import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { MdSettings, MdUndo, MdDirectionsRun } from 'react-icons/md';
import { MdDelete } from 'react-icons/md';
import convert from 'convert-units';
import { FaPlayCircle } from 'react-icons/fa';
import ToolbarButton from '../button';
import FloorToolbarButton from '../popup/floorplan';
import CabinetDoorStyleToolbarButton from '../popup/doorStyle';
import CabinetToolbarButton from '../popup/cabinet';
import ApplianceToolbarButton from '../popup/appliance';
import FinishingTouchToolbarButton from '../popup/finishingtouch'
import ReviewQuoteToolbarButton from '../popup/reviewquote';
import PanelElementEditor from './../../sidebar/panel-element-editor/panel-element-editor';
import FormNumberInput from '../../style/form-number-input'
import { FaCube, FaTimes, FaTrash, FaCopy, FaPaste } from 'react-icons/fa/index';
import If from '../../../utils/react-if';
import PlgItem from '../plugin-item.jsx';
import * as PS from '../popup/styles';
import { Map } from 'immutable';
import { StyledAlert } from './myComponents';
// import * as Three from 'three';

import {
  // API_SERVER,
  MODE,
  MODE_IDLE,
  MODE_3D_VIEW,

  MODE_WAITING_DRAWING_LINE,
  MODE_IDLE_3D,
  MODE_ROTATING_ITEM_3D,
  MODE_DRAGGING_ITEM_3D,
  MODE_2D_PAN,
  MODE_DRAWING_ITEM_3D,
  API_SERVER_URL,
  MAKE_FLOOR_PLAN,
  SELECT_DOOR_STYLE,
  ADD_CABINETS,
  ADD_APPLIANCES,
  FINISHING_TOUCH,
  REVIEW_AND_QUOTE,
  SELECTALL,
  EDIT,
  UNDO,
  REDO,
  TAKE_PICTURE,
  SELECT_ALL,
  NEW_PROJECT_BTN

} from '../../../constants';
import * as SharedStyle from '../../../shared-style';
import * as S from './styles';
import axios from 'axios';
import RightButton from '../button/RightButton';
import { getSignedUrl } from '../../../utils/helper';

const iconTextStyle = {
  fontSize: '19px',
  textDecoration: 'none',
  fontWeight: 'bold',
  margin: '0px',
  userSelect: 'none'
};

const Icon2D = ({ style }) => <p style={{ ...iconTextStyle, ...style }}>2D</p>;
const Icon3D = FaCube;

const ASIDE_STYLE = {
  marginTop: "85px",
  // zIndex: 6,
};

const sortButtonsCb = (a, b) => {
  if (a.index === undefined || a.index === null) {
    a.index = Number.MAX_SAFE_INTEGER;
  }

  if (b.index === undefined || b.index === null) {
    b.index = Number.MAX_SAFE_INTEGER;
  }

  return a.index - b.index;
};

const mapButtonsCb = (el, ind) => {
  return (
    <If key={ind} condition={el.condition} style={{ position: 'relative' }}>
      {el.dom}
    </If>
  );
};

const searchInput = {
  width: 'calc( 100% - 2em )',
  height: '2em',
  marginTop: "15px",
  padding: '0 1em',
  margin: '0 1em',
  border: '1px solid #EEE'
};

const finishingtouchData = [
  {
    name: "Handles",
    thumbnail: "/assets/img/svg/toolbar/handles.svg"
  },
  {
    name: "Countertop",
    thumbnail: "/assets/img/svg/toolbar/countertop.svg"
  },
  {
    name: "Backsplash",
    thumbnail: "/assets/img/svg/toolbar/backsplash.svg"
  }, {
    name: "Wall Color",
    thumbnail: "/assets/img/svg/toolbar/wall_color_icon.svg"
  }, {
    name: "Floor Styles",
    thumbnail: "/assets/img/svg/toolbar/floorstyle.svg"
  }, {
    name: "Lighting",
    thumbnail: "/assets/img/svg/toolbar/lighting.svg"
  }, {
    name: "Furnishing",
    thumbnail: "/assets/img/svg/toolbar/furnishings_icon.svg"
  },
];

let hintText = "";
export default class Toolbar extends Component {
  constructor(props, context) {
    super(props, context);

    let layer = props.layers.get(props.selectedLayer);
    let elements = {
      lines: layer.lines,
      holes: layer.holes,
      items: layer.items,
    };

    this.state = {
      elements,
      appliancesData: [],
      doorHandle: [],
      wallColors: [],
      floorStyles: [],
      lighting: [],
      counterTop: [],
      molding: [],
      backsplash: [],
      furnishing: [],
      opened: false,
      vData: [],
      toData: [],
      vDataArray: [],
      dData: [],
      oData: [],
      DoorStyles: [],
      dDataArray: [],
      currentTexture: layer.doorStyle !== null ? layer.doorStyle : props.state.doorStyle,
      doorTitle: [],
      cabinetTitle: [],
      cabinetCollapse: true,
      sortFlag: true,
      doorCollapse: true,
      doorExpand: false,
      searchCabinet: "",
      searchDoor: "",
      floorCollapse: true,
      animOpened: false,
      closeover: false,
      animover: false,
      cabinetStyle: 0,
      plgItems: [],
      plgUrls: [],
      filter: '',
      activeButton: 'Floor Plan',
      search: '',
      controlButton: '',
      topButton: '',
      recentStyle: [],
      selectAll: false,
      cabinetSubCategoryData: [],
      cabinetExpand: -1,
      applianceSubCategoryData: [],
      applianceCategoryCollapse: true,
      applianceExpand: -1,
      finishingSubCategoryData: [],
      finishingCategoryCollapse: true,
      finishingProductCollapse: true,
      floorSubCategoryData: [],
      floorCategoryCollapse: true,
      floorProductCollapse: true,
      doorStyleSubCategoryData: [],
      doorStyleCategoryCollapse: true,
      doorStyleProductCollapse: true,
      doorStyleStep: 0,
      stepSource: [],
      doorStyleExpand: -1,
      reviewCollapse: true,
      isMolding: false,
      editWidthVisible: false,
      areaSize: 0,
      isModalVisible: false,
      imageUri: '',
      image: "",
      croppedImage: "",
      isMouseDown: false,
      firstMousePoint: { x: 0, y: 0 },
      lastMousePoint: { x: 1000, y: 1000 },
      mouse: { p:{ x: 0, y: 0 }, frame: 0, frames: 30 },
      topLeft: { x: this.percentToPxHor(17.275), y: this.percentToPxVert(59.691) },
      bottomLeft: { x: this.percentToPxHor(17.275), y: this.percentToPxVert(60.72) },
      topRight: { x: this.percentToPxHor(71.459), y: this.percentToPxVert(61.407) },
      bottomRight: { x: this.percentToPxHor(71.352), y: this.percentToPxVert(62.436) }
    };
    this.onMouseUp_On_New_Save_SQ_DA = this.onMouseUp_On_New_Save_SQ_DA.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
  }

  hideModal() {
    this.setState({ isModalVisible: false });
  }
  editWidth() {
    this.setState({ editWidthVisible: true });
  }

  handleEditWidth(newWidth, layerID, itemID) {
    this.setState({ editWidthVisible: false });
    this.context.itemsActions.editWidth(newWidth, layerID, itemID);
  }


  componentDidMount() {
    const self = this;
    const data = self.props.categoryData;
    if (data.success === true) {
      const { appliances, cabinets, doorStyles, doorHandle, wallColors, floorStyles, lighting, counterTop, backsplash, furnishing } = data.data;
      var molding = [];
      data.data.cabinets[data.data.cabinets.length - 1].items.forEach(index => {
        if(index.name === 'Molding') {
          molding = index.items;
        }
      });
      self.setState({ appliancesData: appliances, vData: cabinets, doorHandle, wallColors, floorStyles, lighting, counterTop, backsplash, furnishing, toData: cabinets, molding, DoorStyles: doorStyles.items, dData: doorStyles.items, stepSource: doorStyles.items });
      if (self.state.currentTexture == null) {
        const findDefault = (data) => {
          var result = undefined;
          for (var i = 0; i < data.length; i++) {
            if (data[i].type === 'door-category') {
              result = findDefault(data[i].items);
              if (result !== undefined) {
                break;
              }
            }
            else if (data[i].type === 'door') {
              if (data[i].name === "Brilliant White Shaker") {
                return data[i];
              }
            }
          }
          return result;
        }

        const doorCategory = (result, data, path) => {
          if (data.some(element => { return element.type === "door-category" })) {
            const temp = data.filter(element => { return element.type === "door-category" })
            temp.forEach(t => {
              if (t.items !== undefined) {
                doorCategory(result, t.items, path != "" ? path + "," + t.name : t.name);
              }
            })
          }
          else {
            const temp = data.filter(element => { return element.type !== "door-category" })
            temp.forEach(t => {
              result.push({ name: t.name, path: path != "" ? (path + ',' + t.name) : t.name, data: t });
            })
          }
          return result;
        }

        const findFinishing = (data) => {
          let isSelected = false;
          let temp = {
            counterTop: [],
            floorStyles: []
          }
          if (data.counterTop) {
            data.counterTop.forEach(element => {
              if (element.selected) isSelected = true;
            })
            if (!isSelected) data.counterTop[0].selected = true;
            temp.counterTop = data.counterTop;
          } else {
            console.log("There are no counterTops.");
          }
          isSelected = false;
          if (data.floorStyles) {
            data.floorStyles.forEach(element => {
              if (element.selected) isSelected = true;
            })
            if (!isSelected) data.floorStyles[0].selected = true;
            temp.floorStyles = data.floorStyles;
          } else {
            console.log("There are no floorStyles.");
          }
          temp = {
            counterTop: temp.counterTop.filter(e => { return (e.selected == true) }),
            floorStyles: temp.floorStyles.filter(e => { return (e.selected == true) })
          }
          return temp;
        }

        let dStyle = findDefault(doorStyles.items);
        let finishing = findFinishing({ counterTop, floorStyles });
        let oStyle = doorCategory([], doorStyles.items, '');
        let pathes = [];
        self.context.itemsActions.setInitialDoorStyle(dStyle, oStyle);
        self.context.itemsActions.setCounterTop({ uri: finishing.counterTop[0].texture });
        self.context.areaActions.setFloorStyles({ uri: finishing.floorStyles[0].texture });
        if (dStyle !== undefined) {
          self.setState({ currentTexture: dStyle, oData: oStyle });
        }
        else {
          console.log('There are no Door Colors in database');
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    let layer = nextProps.layers.get(nextProps.selectedLayer);
    if (layer.doorStyle !== null && layer.doorStyle !== undefined && this.state.currentTexture !== undefined) {
      if (layer.doorStyle.id !== this.state.currentTexture.id) {
        this.setState ({ currentTexture : layer.doorStyle });
      }
    }
    this.setState({areaSize : layer.areas.size});

    let activeButton = '';
    if (nextProps.reviewQuotePopupOpened) {
      activeButton = REVIEW_AND_QUOTE;
    } else if (nextProps.floorOpened) {
      activeButton = MAKE_FLOOR_PLAN;
    } else if (nextProps.cabinetOpened) {
      activeButton = ADD_CABINETS;
    } else if (nextProps.applianceOpened) {
      activeButton = ADD_APPLIANCES;
    } else if (nextProps.finishingOpened) {
      activeButton = FINISHING_TOUCH;
    } else if (nextProps.doorStyleOpen) {
      activeButton = SELECT_DOOR_STYLE;
    }
    this.setState({ activeButton });

    if (this.props.layers.hashCode() === nextProps.layers.hashCode()) return;

    let elements = {
      lines: layer.lines,
      holes: layer.holes,
      items: layer.items,
    };

    this.setState({
      elements,
    });
  }

  toggleOpen() {
    let { replacingSupport } = this.props.state;
    let layerID = replacingSupport.getIn(['layerID']);
    let itemID = replacingSupport.getIn(['itemID']);
    let iName = null;
    if (layerID !== undefined) {
      let obj = this.props.state.scene.getIn(['layers', layerID, 'items', itemID]);
      if (obj === undefined) {
        this.PanelClose();
        return;
      }
      iName = obj.name;
      this.setState({ plgUrls: [iName] });
      this.updateplgItems([iName]);
      if ([MODE_3D_VIEW, MODE_IDLE_3D, MODE_ROTATING_ITEM_3D, MODE_DRAGGING_ITEM_3D, MODE_DRAWING_ITEM_3D].includes(this.props.state.mode) && !this.state.opened)
        this.PanelOpen();
      else
        this.PanelClose();
    }
    else {
      this.PanelClose();
    }
  }

  Animate() {
    this.context.itemsActions.animateObject(this.state.animValue);
  }

  toggleAnimationOpen() {
    let { replacingSupport } = this.props.state;
    let layerID = replacingSupport.getIn(['layerID']);
    let itemID = replacingSupport.getIn(['itemID']);
    let planData = window.planData;
    let iName = null;
    if (layerID !== undefined) {
      let obj = this.props.state.scene.getIn(['layers', layerID, 'items', itemID]);
      if (obj === undefined) {
        this.PanelAnimClose();
        return;
      }
      iName = obj.name;
      let item3D = planData.sceneGraph.layers[layerID].items[itemID];
      let userData = item3D.children[0].userData;
      this.setState({ animData: userData.animation, animValue: 0 });
      this.setState({ plgUrls: [iName] });
      this.updateplgItems([iName]);
      if ([MODE_3D_VIEW, MODE_IDLE_3D, MODE_ROTATING_ITEM_3D, MODE_DRAGGING_ITEM_3D].includes(this.props.state.mode) && !this.state.animOpened)
        this.PanelAnimOpen();
      else
        this.PanelAnimClose();
    }
    else {
      this.PanelAnimClose();
    }
  }

  PanelOpen() {
    this.setState({ opened: true, animOpened: false });
  }

  PanelClose() {
    this.setState({ opened: false, closeover: false });
  }

  PanelAnimOpen() {
    this.setState({ animOpened: true, opened: false });
  }

  PanelAnimClose() {
    this.setState({ animOpened: false, closeover: false });
  }
  getItem(i) {
    if (i == -1) {
      this.setState({ cabinetSubCategoryData: [], cabinetTitle: [], cabinetExpand: -1 });
    } else {
      if (i === 'Molding') {
        this.setState({ cabinetSubCategoryData: this.state.molding });
      }
      else if (i === 'Moldings') {
        this.setState({ isMolding: true });
      } else if (i != "-1") {
        const getCategory = (result, data, path) => {
          if (data.some(element => { return element.type === "cabinet_category" })) {
            const temp = data.filter(element => { return element.type === "cabinet_category" })
            temp.forEach(t => {
              if (t.items !== undefined) {
                result.push({ name: t.name, path: path != "" ? (path + ',' + t.name) : t.name, data: t.items });
                getCategory(result, t.items, t.name);
              }
            })
          }
          return result;
        }
        const allData = getCategory([], this.state.vData, "");
        var t = allData.find(element => { return element.name === i });
        const splitStrs = t.path.split(",");
        const result = t.data.filter(element => { return element.type === "cabinet" });
        this.setState({ cabinetSubCategoryData: result, cabinetTitle: splitStrs, isMolding: false });
        if (this.state.cabinetExpand === -1) {
          this.setState({ cabinetExpand: 0 })
        }
      }
    }
  }
  getAppliance(i) {
    // const appliance = this.state.appliancesData.find(element => element.name === i);
    // this.setState({ applianceSubCategoryData: appliance.items });
    if (i != "-1") {
      const getCategory = (result, data, path) => {
        if (data.some(element => { return element.type === "appliance_category" })) {
          const temp = data.filter(element => { return element.type === "appliance_category" })
          temp.forEach(t => {
            if (t.items !== undefined) {
              result.push({ name: t.name, path: path != "" ? (path + ',' + t.name) : t.name, data: t.items });
              getCategory(result, t.items, t.name);
            }
          })
        }
        return result;
      }
      const allData = getCategory([], this.state.appliancesData, "");
      var t = allData.find(element => { return element.name === i });
      const splitStrs = t.path.split(",");
      const result = t.data.filter(element => { return element.type === "cabinet" });
      this.setState({ applianceSubCategoryData: result, cabinetTitle: splitStrs });
      if (this.state.applianceExpand === -1) {
        this.setState({ applianceExpand: 0 })
      }
    }
    else {
      this.setState({ applianceSubCategoryData: [], cabinetTitle: [], applianceExpand: -1 });
    }
  }
  getFloor(i) {
    if (i === -1 || i === -2) {
      this.setState({ floorSubCategoryData: i });
    } else {
      const data = this.props.catalog.getCategory('root');
      const fData = data.categories.find(el => { return el.name === i });
      this.setState({ floorSubCategoryData: fData.elements });
    }
  }
  getFinishing(i) {
    console.log(i);
    if (i === 'Handles') {
      this.setState({ finishingSubCategoryData: this.state.doorHandle });
    }
    else if (i === 'Wall Color') {
      this.setState({ finishingSubCategoryData: this.state.wallColors });
    }
    else if (i === 'Floor Styles') {
      this.setState({ finishingSubCategoryData: this.state.floorStyles });
    }
    else if (i === 'Lighting') {
      this.setState({ finishingSubCategoryData: this.state.lighting });
    }
    else if (i === 'Countertop') {
      this.setState({ finishingSubCategoryData: this.state.counterTop });
    }
    else if (i === 'Backsplash') {
      this.setState({ finishingSubCategoryData: this.state.backsplash });
    }
    else if (i === 'Furnishing') {
      this.setState({ finishingSubCategoryData: this.state.furnishing });
    }
  }
  getStyle(texture) {
    let doorTitle = this.state.doorTitle;
    this.context.itemsActions.setDoorStyle(texture, doorTitle);
    let recentStyle = this.state.recentStyle;
    let rStyle = { doorTitle: doorTitle, textureData: texture };
    if (recentStyle.length == 2) {
      recentStyle[1] = recentStyle[0];
      recentStyle[0] = rStyle;
    } else {
      recentStyle.unshift(rStyle);
    }
    this.setState({ recentStyle: recentStyle });
    this.setState({ currentTexture: texture, doorStyleStep: 0, doorStyleExpand: -1, doorStyleSubCategoryData: [], stepSource: this.state.DoorStyles, doorTitle: [] });
  }
  applyMaterial(category, metalness, roughness) {
    if (category === 'floor_style')
      this.context.areaActions.setFloorStyles({ metalness, roughness });
    else if (category === 'backsplash')
      this.context.itemsActions.setBacksplash({ metalness, roughness });
    else if (category === 'counter_top')
      this.context.itemsActions.setCounterTop({ metalness, roughness });
    else if (category === 'handle')
      this.context.itemsActions.setHandleMaterial({ metalness, roughness });
    else if (category === 'appliance')
      this.context.itemsActions.setApplianceMaterial({ metalness, roughness });
  }
  setDoorHandle(texture) {
    if (texture.category === 'modelling') {
      this.context.itemsActions.setModelling(texture.name);
    }
    else if (texture.category === 'molding') {
      this.context.itemsActions.setMolding(texture);
    }
    else if (texture.category === 'wall_color') {
      this.context.itemsActions.setWallColor(texture.color);
    }
    else if (texture.category === 'counter_top') {
      this.context.itemsActions.setCounterTop({ uri: texture.texture });
    }
    else if (texture.category === 'floor_style') {
      this.context.areaActions.setFloorStyles({ uri: texture.texture });
    }
    else if (texture.category === 'backsplash') {
      this.context.itemsActions.setBacksplash({ uri: texture.texture });
    }
    else if (texture.category === 'lighting') {
    }
    else if (texture.category === 'furnishing') {
    }
    else {
      const gltfJSON = JSON.parse(texture.gltf)
      if (gltfJSON.gltf.some(el => el.endsWith('gltf'))) {
        this.context.itemsActions.setDoorHandle(gltfJSON.gltf.find(el => el.endsWith('gltf')));
        if (document.getElementsByClassName("popup-content").length > 0)
          document.getElementsByClassName("popup-content")[0].style.display = "none";
      } else {
        alert("Invalid Door Handle!!!");
        console.log("There are no gltf file in " + texture.name + "handle!");
      }
    }
  }
  getStyleCategory(i) {
    const { doorStyleStep } = this.state;
    if (doorStyleStep === 1) {
      this.setState({ doorStyleExpand: 0 });
    }
    this.setState({ doorStyleStep: doorStyleStep + 1 });
    if (i !== -1) {
      const { stepSource } = this.state;
      const splitStr = i.split('/');
      var data = stepSource;
      for (let x = 0; x < splitStr.length; x++) {
        const temp = data.find(el => { return el.name === splitStr[x] });
        data = temp.items;
      }
      const result = data.filter(element => { return element.type === "door" });
      this.setState({ doorStyleSubCategoryData: result });
    }
  }
  collapseAction(i) {
    if (i == 0) {
      if (this.state.cabinetCollapse == false)
        this.setState({ cabinetCollapse: true, doorCollapse: true });
      else
        this.setState({ cabinetCollapse: false, doorCollapse: true });
    }
    if (i == 1) {
      if (this.state.doorCollapse == false)
        this.setState({ cabinetCollapse: true, doorCollapse: true });
      else
        this.setState({ cabinetCollapse: true, doorCollapse: false });
    }
    if (i == 2)
      this.setState({ floorCollapse: !this.state.floorCollapse });
    if (i == 3)
      this.setState({ doorExpand: !this.state.doorExpand });
    if (i == 4) {

    }
  }

  expandDoorStyleAction() {
    this.setState({ doorStyleExpand: (this.state.doorStyleExpand + 1) % 2 });
  }

  expandCabinetAction() {
    this.setState({ cabinetExpand: (this.state.cabinetExpand + 1) % 2 });
  }

  expandApplianceAction() {
    this.setState({ applianceExpand: (this.state.applianceExpand + 1) % 2 });
  }

  plgItemAction(url, name, gltfUrl, self) {
    let purls = this.state.plgUrls;
    if (url == 'dir') {
      purls.push(name);
    }
    if (url == 'before') purls.pop();
    if (url != 'dir' && url != 'before') {
      this.context.itemsActions.replaceSubmodule(gltfUrl);
    }
    this.updateplgItems(this.state.plgUrls);
  }

  updateplgItems(urls) {
    let getUrl = '';
    for (let i = 0; i < urls.length; i++) {
      getUrl += '/';
      getUrl += urls[i];
    }
    const self = this;
  }

  filterChange(self) {
    let setValue = self.target.value;
    this.setState({ filter: setValue });
  }

  setAnimValue(event) {
    let value = event.target.value;
    this.setState({ animValue: value });
  }

  shouldComponentUpdate(nextProps, nextState) {
    let openedType = nextProps.state.get('openedType');
    if (nextProps.state.get('isOpen') === true) {
      if (openedType === 1) this.props.openCabinet();
      if (openedType === 2) this.props.openFinishing();
      if (openedType === 3) this.props.openAppliance();
      if (openedType === 4) this.props.openFloor();
      this.context.holesActions.endCreatingHole();
      this.context.itemsActions.endCreatingCabinet();

    }

    return (
      this.props.state.mode !== nextProps.state.mode ||
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width ||
      this.props.state.alterate !== nextProps.state.alterate ||
      nextState
    );
  }

  toNextState(i = -1, textureData = null) {
    if (i != -2) {
      this.setState({ cabinetStyle: this.state.cabinetStyle + 1 });
      if (i != -1) {
        let doorTitle = this.state.doorTitle;
        doorTitle.push(this.state.dData[i].name);
        let dDataArray = this.state.dDataArray;
        dDataArray.push(i);
        this.setState({ dData: this.state.dData[i].items, doorTitle: doorTitle, dDataArray: dDataArray });
      }
      if (textureData != null) {
        let doorTitle = this.state.doorTitle;
        // console.log("tonextstate", textureData, doorTitle);
        this.context.itemsActions.setDoorStyle(textureData, doorTitle);
        let recentStyle = this.state.recentStyle;
        let rStyle = { doorTitle: doorTitle, textureData: textureData };
        if (recentStyle.length == 2) {
          recentStyle[1] = recentStyle[0];
          recentStyle[0] = rStyle;
        } else {
          recentStyle.unshift(rStyle);
        }
        this.setState({ recentStyle: recentStyle });
        this.setState({ cabinetStyle: 0, currentTexture: textureData, dData: this.state.DoorStyles, doorTitle: [], dDataArray: [] });
        // this.setState({ cabinetStyle: 0, currentTexture: textureData, dData: DoorStyles, doorTitle: [], dDataArray: [] });
      }
    }
    else {
      this.setState({ cabinetStyle: this.state.cabinetStyle - 1 });
      let doorTitle = this.state.doorTitle;
      doorTitle.pop();
      let dDataArray = this.state.dDataArray;
      dDataArray.pop();
      this.setState({ doorTitle: doorTitle });
      let tData = this.state.DoorStyles;
      // let tData = DoorStyles;
      for (let i = 0; i < dDataArray.length; i++) {
        tData = tData[i].items;
      }
      this.setState({ dData: tData });
    }
  }

  selectFromRecent(i) {
    let doorTitle = this.state.recentStyle[i].doorTitle;
    doorTitle.pop();
    let textureData = this.state.recentStyle[i].textureData;
    // console.log("selectfromrecent", textureData);
    this.context.itemsActions.setDoorStyle(textureData, doorTitle);
    this.setState({ cabinetStyle: 0, currentTexture: textureData, dData: this.state.DoorStyles, doorTitle: [], dDataArray: [] });
    // this.setState({ cabinetStyle: 0, currentTexture: textureData, dData: DoorStyles, doorTitle: [], dDataArray: [] });
  }

  selected(name) {
    let texture = this.state.currentTexture;
    if (texture !== null)
      texture.name = texture.name.charAt(0).toUpperCase() + texture.name.substr(1);
    if (texture) return texture.name === name ? 1 : 0;
    else return 0;
  }

  onSetSort(flag) {
    this.setState({ sortFlag: flag });
  }

  onSearch(e, i) {
    if (i == 0)
      this.setState({ searchCabinet: e.target.value });
    else
      this.setState({ searchDoor: e.target.value });
  }

  onUpdate(e) {
    this.setState({ search: e.target.value });
  }

  drawCircle(ctx, p, color, radius) {
    if (typeof radius == 'undefined') radius = 2;
    if (typeof color == 'undefined') color = 'red';
    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.arc(p.x,p.y,radius,0,2*Math.PI);
    ctx.fill();
  }
  
  drawPoly(ctx, points, color) {
    ctx.globalAlpha = 1;
    ctx.strokeStyle = color ? color : 'red';
    ctx.beginPath();
    var p = points[points.length-1];
    ctx.moveTo(p.x, p.y);
    for(var i = 0; i < points.length; i++) {
      var p = points[i];
      ctx.lineTo(p.x, p.y); 
    }
    ctx.stroke();
  }

  percentToPxHor(canvas, intPx) {
    return intPx * canvas.width / 100;
  }

  percentToPxVert(canvas, intPx) {
      return intPx * canvas.height / 100;
  }

  canvasUpdate(ctx, canvas) {
    if (ctx === undefined) return;
    // clear canvas
    ctx.fillStyle='white';
    ctx.fillRect(0,0,canvas.width,canvas.height);
    ctx.globalAlpha = 0.3;
    ctx.drawImage(this.state.image, 0, 0, canvas.width, canvas.height);
    if (this.state.isMouseDown) {
      ctx.globalAlpha = 1;
      ctx.drawImage(
      this.state.image,
      this.state.firstMousePoint.x,
      this.state.firstMousePoint.y,
      this.state.mouse.p.x - this.state.firstMousePoint.x,
      this.state.mouse.p.y - this.state.firstMousePoint.y,
      this.state.firstMousePoint.x,
      this.state.firstMousePoint.y,
      this.state.mouse.p.x - this.state.firstMousePoint.x,
      this.state.mouse.p.y - this.state.firstMousePoint.y
      );
      
      let img = new Image ();
      img.crossOrigin = 'anonymous';
      img.src = canvas.toDataURL();

      img.onload = () => {
        this.setState ({ croppedImage: img });
      };
    }
    
    // draw points and connect
    this.drawCircle(ctx, this.state.topLeft, "blue");
    this.drawCircle(ctx, this.state.bottomLeft, "green");
    this.drawCircle(ctx, this.state.topRight, "red");
    this.drawCircle(ctx, this.state.bottomRight, "orange");
    this.drawPoly(ctx, [this.state.topLeft, this.state.bottomLeft, this.state.bottomRight, this.state.topRight]);
    
    // draw mouse circle
    // this.drawCircle(ctx, this.state.mouse.p, 'rgba(255,200,0,.75)', 10);
    
    // draw mouse lines
    let h1 = { x: 0, y: this.state.mouse.p.y };
    let h2 = { x: canvas.width, y: this.state.mouse.p.y };
    let v1 = { x: this.state.mouse.p.x, y: 0 };
    let v2 = { x: this.state.mouse.p.x, y: canvas.height };
    if (
      Math.abs(this.state.mouse.p.x - this.state.firstMousePoint.x) < 60 &&
      this.state.isMouseDown
    ) {
      this.drawPoly(ctx, [v1, v2]);
    } else {
      this.drawPoly(ctx, [v1, v2], 'rgba(64, 155, 197, 1)');
    }
    if (
      Math.abs(this.state.mouse.p.y - this.state.firstMousePoint.y) < 60 &&
      this.state.isMouseDown
    ) {
      this.drawPoly(ctx, [h1, h2]);
    } else {
      this.drawPoly(ctx, [h1, h2], 'rgba(64, 155, 197, 1)');
    }
  }

  imageBrowserDownload ( canvas, imageUri ) {
    let fileOutputLink = document.createElement('a');

    let filename = 'output' + Date.now() + '.png';
    filename = window.prompt('Insert output filename', filename);
    if (!filename) return;

    fileOutputLink.setAttribute('download', filename);
    fileOutputLink.href = imageUri;
    fileOutputLink.style.display = 'none';
    document.body.appendChild(fileOutputLink);
    setTimeout(() => {
      fileOutputLink.click();
    }, 100);
    document.body.removeChild(fileOutputLink);
    this.setState({
      isModalVisible: false,
      imageUri: "",
      image: ""
    });
    canvas.style.zIndex = -1;
    document.removeEventListener ("keydown", this.onKeyDown, false);
    document.removeEventListener ("mouseup", this.onMouseUp_On_New_Save_SQ_DA, false);
    canvas.style.display = "none";
  };

  makeCroppedImage (ctx, canvas) {
    // clear canvas
    ctx.fillStyle='white';
    ctx.fillRect(0,0,canvas.width,canvas.height);
    ctx.drawImage(this.state.croppedImage, 0, 0, canvas.width, canvas.height);

    let croppedCanvas = document.createElement('canvas');
    let croppedCtx = croppedCanvas.getContext('2d');

    // Set width and height for the new croppedCanvas
    let heightAtt = document.createAttribute('height');
    heightAtt.value = Math.abs (this.state.mouse.p.y - this.state.firstMousePoint.y);
    croppedCanvas.setAttributeNode(heightAtt);

    let widthAtt = document.createAttribute('width');
    widthAtt.value = Math.abs (this.state.mouse.p.x - this.state.firstMousePoint.x);
    croppedCanvas.setAttributeNode(widthAtt);

    croppedCtx.fillStyle = 'white';
    croppedCtx.fillRect(0, 0, croppedCanvas.width, croppedCanvas.height);

    croppedCtx.drawImage(
      this.state.image,
      this.state.mouse.p.x - this.state.firstMousePoint.x < 0
        ? this.state.mouse.p.x
        : this.state.firstMousePoint.x,
      this.state.mouse.p.y - this.state.firstMousePoint.y < 0
        ? this.state.mouse.p.y
        : this.state.firstMousePoint.y,
      croppedCanvas.width,
      croppedCanvas.height,
      0,
      0,
      croppedCanvas.width,
      croppedCanvas.height
    );
    this.imageBrowserDownload(canvas, croppedCanvas.toDataURL());
  }

  setMousePoint (e, canvas, type) {
    let ctx = canvas.getContext('2d');
    let rectCanvas = canvas.getBoundingClientRect();
    let positionX = Math.round(((e.clientX - rectCanvas.left) / canvas.width * 100) * 1000) / 1000;
    let positionY = Math.round(((e.clientY - rectCanvas.top) / canvas.height * 100) * 1000) / 1000;
    switch (type) {
      case "down":
        this.setState ({ firstMousePoint: { x: this.percentToPxHor(canvas, positionX), y: this.percentToPxVert(canvas, positionY) } });
        break;
        case "move":
        this.setState ({ mouse: { p: { x: this.percentToPxHor(canvas, positionX), y: this.percentToPxVert(canvas, positionY)} } });
        break;
      case "up":
        this.setState({
          lastMousePoint: {
            x: this.percentToPxHor(canvas, positionX),
            y: this.percentToPxVert(canvas, positionY)
          }
        });
        if (
          !(
            Math.abs(
              this.state.mouse.p.x - this.state.firstMousePoint.x
            ) < 60 ||
            Math.abs(
              this.state.mouse.p.y - this.state.firstMousePoint.y
            ) < 60
          ) &&
          ctx !== undefined
        ) {
          this.makeCroppedImage(ctx, canvas);
        } else {
          this.setState({
            isModalVisible: false,
            imageUri: "",
            image: ""
          });
          canvas.style.zIndex = -1;
          document.removeEventListener ("keydown", this.onKeyDown, false);
          document.removeEventListener ("mouseup", this.onMouseUp_On_New_Save_SQ_DA, false);
          canvas.style.display = 'none';
        }
        break;
    
      default:
        break;
    }
  }
  
  onMouseUp_On_New_Save_SQ_DA (e) {
    let canvas = document.getElementById ("canvas_crop_image");
    switch (e.target.textContent) {
      case "New":
      case "Save":
      case "Submit for Quote":
      case "Designer Assistance":
        canvas.style.zIndex = -1;
        document.removeEventListener ("keydown", this.onKeyDown, false);
        document.removeEventListener ("mouseup", this.onMouseUp_On_New_Save_SQ_DA, false);
        canvas.style.display = 'none';
        this.setState({
          isModalVisible: false,
          isMouseDown: false,
          imageUri: "",
          image: ""
        });
      break;
      default:
        break;
    }
    if (
      e.target.outerHTML.includes('review_quote.svg') ||
      e.target.outerHTML.includes('consult_designer.svg')
    ) {
      canvas.style.zIndex = -1;
      document.removeEventListener('keydown', this.onKeyDown, false);
      document.removeEventListener(
        'mouseup',
        this.onMouseUp_On_New_Save_SQ_DA,
        false
      );
      canvas.style.display = 'none';
      this.setState({
        isModalVisible: false,
        isMouseDown: false,
        imageUri: '',
        image: ''
      });
    }
  }

  onKeyDown (e) {
    if (e.keyCode === 27) { // Escape
      let canvas = document.getElementById ("canvas_crop_image");
      canvas.style.zIndex = -1;
      document.removeEventListener ("keydown", this.onKeyDown, false);
      document.removeEventListener ("mouseup", this.onMouseUp_On_New_Save_SQ_DA, false);
      canvas.style.display = 'none';
      this.setState({
        isModalVisible: false,
        isMouseDown: false,
        imageUri: "",
        image: ""
      });
    }
  }

  onMouseDown (e) {
    let canvas = document.getElementById ("canvas_crop_image");
    this.setMousePoint (e, canvas, "down");
    this.setState ({ isMouseDown: true });
  }
  onMouseMove (e) {
    let canvas = document.getElementById ("canvas_crop_image");
    let ctx = canvas.getContext('2d');
    this.setMousePoint (e, canvas, "move");
    this.canvasUpdate (ctx, canvas);
  }
  onMouseUp (e) {
    let canvas = document.getElementById ("canvas_crop_image");
    this.setMousePoint (e, canvas, "up");
    this.setState ({ isMouseDown: false });
  }

  render() {
    let {
      props: { state, width, height, content, handleSaveDesign, handleSubmitForQuote, handleDesignerAssist },
      context: { projectActions, viewer2DActions, linesActions, viewer3DActions, translator, areaActions,itemsActions }
    } = this;
    let scene = state.get('scene');
    let layerID = scene.get('selectedLayer');
    
    var panel;
    let dirUrl = '';
    let mode = state.get('mode');

    let tt = this;
    this.state.counterTop.forEach((data) => {
      let scene = tt.props.state.get('scene');
      let layerID = scene.get('selectedLayer');
      let layers = scene.layers.get(layerID);
      let counterTop = layers.get('counterTop');
      data.selected = ((counterTop && counterTop.uri) == data.texture);
    });

    this.state.backsplash.forEach((data) => {
      let scene = tt.props.state.get('scene');
      let layerID = scene.get('selectedLayer');
      let layers = scene.layers.get(layerID);
      let backsplash = layers.get('backsplash');
      data.selected = ((backsplash && backsplash.uri) == data.texture);
    });
    
    this.state.molding.forEach((data) => {
      let scene = tt.props.state.get('scene');
      let layerID = scene.get('selectedLayer');
      let layers = scene.layers.get(layerID);
      let molding = layers.get('molding');
      data.selected = molding && molding.some(m => {return m.name === data.name});
    })

    this.state.wallColors.forEach((data) => {
      let scene = tt.props.state.get('scene');
      let layerID = scene.get('selectedLayer');
      let layers = scene.layers.get(layerID);
      let wallColor = layers.get('wallColor');
      data.selected = (wallColor == data.color);
    });

    this.state.floorStyles.forEach((data) => {
      let scene = tt.props.state.get('scene');
      let layerID = scene.get('selectedLayer');
      let layers = scene.layers.get(layerID);
      let floorStyles = layers.get('floorStyle');
      data.selected = ((floorStyles && floorStyles.uri) == data.texture);
    });

    this.state.doorHandle.forEach((data) => {
      let scene = tt.props.state.get('scene');
      let layerID = scene.get('selectedLayer');
      let layers = scene.layers.get(layerID);
      let doorHandle = layers.get('doorHandle');
      data.selected = (doorHandle && data.gltf.includes(doorHandle));
    });

    let alterate = state.get('alterate');
    let alterateColor = alterate ? SharedStyle.MATERIAL_COLORS[500].orange : '';
    let select = this.state.activeButton;
    let topRightImg = "/assets/img/svg/topbar/edit_inactive.svg";
    let topBtnAction = state.mode == MODE_IDLE || state.mode == MODE_2D_PAN ? () => linesActions.selectToolDrawingLine("wall") : () => { projectActions.setMode(MODE_IDLE); linesActions.selectToolDrawingLine("wall"); }

    let saveScreenshotToFile = event => {
      event.preventDefault();
      let canvas = document.getElementsByTagName('canvas')[1];
      let canvas_crop_image = document.getElementById ("canvas_crop_image");
      let ctx = canvas_crop_image.getContext('2d');

      // Set width and height for the new canvas
      let heightAtt = document.createAttribute('height');
      heightAtt.value = canvas.height;
      canvas_crop_image.setAttributeNode(heightAtt);

      let widthAtt = document.createAttribute('width');
      widthAtt.value = canvas.width;
      canvas_crop_image.setAttributeNode(widthAtt);

      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas_crop_image.width, canvas_crop_image.height);

      let img = new Image ();
      img.crossOrigin = 'anonymous';
      img.src = canvas.toDataURL();

      img.onload = () => {
        ctx.globalAlpha = 0.3;
        ctx.drawImage(img, 0, 0, canvas_crop_image.width, canvas_crop_image.height);
        this.setState({
          isModalVisible: true,
          imageUri: canvas.toDataURL(),
          image: img
        });
        canvas_crop_image.style.zIndex = 10;
        document.addEventListener ("keydown", this.onKeyDown, false);
        document.addEventListener ("mouseup", this.onMouseUp_On_New_Save_SQ_DA, false);
        canvas_crop_image.style.display = "block";
      };
    };

    let saveSVGScreenshotToFile = async event => {
      event.preventDefault();

      // First of all I need the svg content of the viewer
      let svgElements = document.getElementsByTagName('svg');

      // I get the element with max width (which is the viewer)
      let maxWidthSVGElement = svgElements[0];
      for (let i = 1; i < svgElements.length; i++) {
        if (svgElements[i].width.baseVal.value > maxWidthSVGElement.width.baseVal.value) {
          maxWidthSVGElement = svgElements[i];
        }
      }

      // Begin: Change Image tags' href to local data src
      let images = maxWidthSVGElement.getElementsByTagName("image");

      let readBlob = b => {
        return new Promise(res => {
          const reader = new FileReader();
          reader.onloadend = () => {
            res(reader.result);
          };
          reader.onerror = () => {
            res(b);
          };
          reader.readAsDataURL(b);
        });
      };

      try {
        for (let image of images) {
          const response = await fetch(getSignedUrl(image.getAttribute("href")));
          const blob = await response.blob();
          const content = await readBlob(blob);
          image.setAttribute("href", content);
        }
      } catch (e) {

      }
      // End: Change Image tags' href to local data src

      let serializer = new XMLSerializer();

      let img = new Image ();

      // I create the new canvas to draw
      // let canvas = document.createElement('canvas');
      let canvas = document.getElementById ("canvas_crop_image");
      let ctx = canvas.getContext('2d');

      // Set width and height for the new canvas
      let heightAtt = document.createAttribute('height');
      heightAtt.value = maxWidthSVGElement.height.baseVal.value;
      canvas.setAttributeNode(heightAtt);

      let widthAtt = document.createAttribute('width');
      widthAtt.value = maxWidthSVGElement.width.baseVal.value;
      canvas.setAttributeNode(widthAtt);

      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      img.crossOrigin = 'anonymous';
      img.src = `data:image/svg+xml;base64,${window.btoa(serializer.serializeToString(maxWidthSVGElement))}`;

      img.onload = () => {
        ctx.globalAlpha = 0.3;
        ctx.drawImage(img, 0, 0, maxWidthSVGElement.width.baseVal.value, maxWidthSVGElement.height.baseVal.value);
        this.setState({
          isModalVisible: true,
          imageUri: canvas.toDataURL(),
          image: img
        });
      };
      canvas.style.zIndex = 10;
      document.addEventListener ("keydown", this.onKeyDown, false);
      document.addEventListener ("mouseup", this.onMouseUp_On_New_Save_SQ_DA, false);
      canvas_crop_image.style.display = "block";
    };

    // control button active remove//
    let control = this.state.controlButton;
    let topControl = this.state.topButton;
    // /////////////////////////////
    // let control = '';
    // added component//////
    let objectProperty = "";
    let layer = state.scene.layers.get(state.scene.selectedLayer);
    let selected = [];
    layer.selected.forEach((data, key) => {
      if (key == "vertices")
        return;
      if (data.size > 0) {
        let values = data.toJS();
        for (let i = 0; i < values.length; i++) {
          selected.push({ type: key, id: values[i] });
        }
      }
    });
    if (mode.indexOf("DRAW") != -1) {
      // hintText = (<S.hintText>Press ESC key to finish drawing!</S.hintText>);
      hintText = <StyledAlert text = "Press ESC key to finish drawing!" />;
    } else if (this.state.isModalVisible) {
      hintText = <StyledAlert text = "Press ESC key to stop clipping!" />;
    } else {
      hintText = "";
    }
    if (selected.length == 1) {
      let selData = selected[0];
      let currentObject;
      switch (selData.type) {
        case "holes":
          currentObject = layer.holes.get(selData.id);
          break;
        case "items":
          currentObject = layer.items.get(selData.id);
          break;
        case "lines":
          currentObject = layer.lines.get(selData.id);
          break;
      }
      if (currentObject != undefined) {
        let elementName = currentObject.type;
        let select = this.props.catalog.elements[elementName];
        if (!select) {
          select = this.props.catalog.elements[elementName.toLowerCase()];
        }
        if (select) {
          const attrPorpSeparatorStyle = {
            margin: '0.1em 0 0.5em 0',
            position: 'relative',
            height: '2em',
            borderRadius: '2px'
          };
          const headActionStyle = {
            position: 'absolute',
            right: '0em',
            top: '0.15em'
          };
          const headActionStyle1 = {
            float: 'left',
            position: 'absolute',
            left: '0em',
            top: '0.15em'
          };
          const iconHeadStyle = {
            float: 'right',
            padding: 0,
            cursor: 'pointer',
            fontSize: '1.6em'
          };
          const fillerStyle = {
            float: 'right',
            position: 'absolute',
            right: '0.8em'
          }
          if (select.prototype === "items") {
            var objSKU = select.obj.sku_number;
            if (this.state.currentTexture !== null && select.obj.skuArray.length !== 0) {
              let doorStyle = currentObject.doorStyle, dcId;
              if (doorStyle instanceof Map) {
                dcId = currentObject.doorStyle.get('id');
              } else {
                dcId = currentObject.doorStyle.id;
              }
              var tempSKU = select.obj.skuArray.find(el => el.door_color_id === dcId);
              if (tempSKU !== undefined) {
                objSKU = tempSKU.sku;
              }
            }
            let isFiller = select.long_name.toUpperCase().includes('FILLER');
            let tempWidth = currentObject.properties.get('width');
            let tempDepth = currentObject.properties.get('depth');
            let tempHeight = currentObject.properties.get('height');
            let itemHeight = select.info.sizeinfo.height;
            let itemWidth = select.info.sizeinfo.width;
            let itemDepth = select.info.sizeinfo.depth;

            let width = tempWidth?convert(tempWidth.get('_length')).from(tempWidth.get('_unit')).to('in').toFixed(2):itemWidth;
            let height = tempHeight?convert(tempHeight.get('_length')).from(tempHeight.get('_unit')).to('in').toFixed(2):itemHeight;
            let depth = tempDepth?convert(tempDepth.get('_length')).from(tempDepth.get('_unit')).to('in').toFixed(2):itemDepth;

            objectProperty = (<PS.SelectedWrapper>
              <PS.TitleBar>
                <PS.Title>Object Properties</PS.Title>
                {/* <PS.Title>Object Properties: {select.info.title}</PS.Title> */}
                <PS.TitleBarIconButton onClick={() => { projectActions.unselectAll() }}>
                  <PS.IconButton><FaTimes /></PS.IconButton>
                </PS.TitleBarIconButton>
              </PS.TitleBar>
              <PS.ContentObjectWrapper>
                <PS.PropteryValue>Name: <span>{select.long_name || select.name}</span></PS.PropteryValue>
                <PS.PropteryValue>SKU: <span>{objSKU}</span></PS.PropteryValue>
                <PS.CabinetTitle>Description:</PS.CabinetTitle>
                <PS.ChildTitle>{select.info.description}</PS.ChildTitle>
                {/* <PS.FurnitureBoxTitle>Full furniture box</PS.FurnitureBoxTitle> */}
                <PS.SelectedBox>
                  <img src={select.info.image} style={{ objectFit: 'contain' }} />
                  {/* <PS.Image><div><img src={select.info.image} /></div></PS.Image> */}
                  {/* <PS.IconButton style={{ 'margin': '10px', 'height': '24px' }}><FaTrash /> Delete</PS.IconButton>
                  <PS.IconButton style={{ 'margin': '10px', 'height': '24px' }}><FaCopy /> Duplicate</PS.IconButton> */}
                </PS.SelectedBox>
                <br />
                <PS.CabinetTitle>Dimensions(W*D*H)</PS.CabinetTitle>
                <PS.ChildTitle>{width}"x{depth}"x{height}"</PS.ChildTitle>
                {/* <PS.PropteryValue>Base Price: <span>122$</span></PS.PropteryValue>
                <PS.PropteryValue>Door Style: <span>{select.obj.door_style}</span></PS.PropteryValue>
                <PS.PropteryValue>Manufacturer: <span>{select.obj.manufacturer}</span></PS.PropteryValue>
                <PS.PropteryValue>Marterial: <span>Ground Pringles</span></PS.PropteryValue> */}
                {
                  isFiller ? (this.state.editWidthVisible ? <PS.ChildTitle1><div
                    style={fillerStyle}
                  >
                    <FormNumberInput
                      style={{
                        width: 88,
                        height: 24.95
                      }}
                      value={width}
                      onChange={event => {
                        this.handleEditWidth(event.target.value, layerID, selData.id);
                      }}
                      precision={2}
                    />
                  </div></PS.ChildTitle1> : <PS.ChildTitle1>
                      <div style={fillerStyle}>
                        <div title={translator.t('Edit')} style={iconHeadStyle} onClick={e => this.editWidth()}>
                          <img width={'88px'} src={'/assets/img/svg/toolbar/use_button.svg'} />
                          <PS.ButtonTitle>
                            {'Resize'}
                          </PS.ButtonTitle>
                        </div>
                      </div></PS.ChildTitle1>) : null
                }
                <PS.Diver></PS.Diver>
                <div style={attrPorpSeparatorStyle}>
                  <div style={headActionStyle}>
                    <div title={translator.t('Delete')} style={iconHeadStyle} onClick={e => projectActions.remove()}>
                      <img width={'88px'} src={'/assets/img/svg/toolbar/delete_button.svg'} />
                      <PS.ButtonTitle style={{ iconHeadStyle }}>
                        {'Delete'}
                      </PS.ButtonTitle>
                    </div>
                  </div>
                  <div style={headActionStyle1}>
                    <div title={translator.t('Duplicate')} style={iconHeadStyle} onClick={e => itemsActions.duplicateSelected(currentObject)}>
                      <img width={'88px'} src={'/assets/img/svg/toolbar/use_button.svg'} />
                      <PS.ButtonTitle style={{ iconHeadStyle }}>
                        {'Duplicate'}
                      </PS.ButtonTitle>
                    </div>
                  </div>
                </div>
                <PS.Diver></PS.Diver>
                <PS.PositionValue>Positioning</PS.PositionValue>
                {/* <PS.PositionValue>Rotate</PS.PositionValue>
                <PS.PositionValue>Resize</PS.PositionValue> */}
                <PanelElementEditor state={state}/>
              </PS.ContentObjectWrapper>
            </PS.SelectedWrapper>);
          }
          else if (select.prototype === "holes") {
            objectProperty = (<PS.SelectedWrapper>
              <PS.TitleBar>
                <PS.Title>Object Properties</PS.Title>
                {/* <PS.Title>Object Properties: {select.info.title}</PS.Title> */}
                <PS.TitleBarIconButton onClick={() => { projectActions.unselectAll() }}>
                  <PS.IconButton><FaTimes /></PS.IconButton>
                </PS.TitleBarIconButton>
              </PS.TitleBar>
              <PS.ContentObjectWrapper>
                <PS.PropteryValue>Name: <span>{select.name}</span></PS.PropteryValue>
                {/* <PS.FurnitureBoxTitle>Full furniture box</PS.FurnitureBoxTitle> */}
                <PS.PropteryValue>SKU: <span>#2021</span></PS.PropteryValue>
                <PS.CabinetTitle>Description:</PS.CabinetTitle>
                <PS.ChildTitle>Full furniture box</PS.ChildTitle>

                <PS.SelectedBox>
                  <img src={select.info.image} style={{ objectFit: 'contain' }} />
                </PS.SelectedBox>
                <br />
                <PS.CabinetTitle>Dimensions(W*D*H)</PS.CabinetTitle>
                <PS.Diver></PS.Diver>
                <div style={attrPorpSeparatorStyle}>
                  <div style={headActionStyle}>
                    <div title={translator.t('Delete')} style={iconHeadStyle} onClick={e => projectActions.remove()}>
                      <img width={'88px'} src={'/assets/img/svg/toolbar/delete_button.svg'} />
                      <PS.ButtonTitle style={{ iconHeadStyle }}>
                        {'Delete'}
                      </PS.ButtonTitle>
                    </div>
                  </div>
                  <div style={headActionStyle1}>
                    <div title={translator.t('Duplicate')} style={iconHeadStyle} onClick={e => itemsActions.duplicateSelected(currentObject)}>
                      <img width={'88px'} src={'/assets/img/svg/toolbar/use_button.svg'} />
                      <PS.ButtonTitle style={{ iconHeadStyle }}>
                        {'Duplicate'}
                      </PS.ButtonTitle>
                    </div>
                  </div>
                </div>
                <PS.Diver></PS.Diver>
                <PS.PositionValue>Positioning</PS.PositionValue>
                <PanelElementEditor state={state} />
              </PS.ContentObjectWrapper>
            </PS.SelectedWrapper>);
          }
          else if (select.prototype === "lines") {
            objectProperty = (<PS.SelectedWrapper>
              <PS.TitleBar>
                <PS.Title>Object Properties</PS.Title>
                {/* <PS.Title>Object Properties: {select.info.title}</PS.Title> */}
                <PS.TitleBarIconButton onClick={() => { projectActions.unselectAll() }}>
                  <PS.IconButton><FaTimes /></PS.IconButton>
                </PS.TitleBarIconButton>
              </PS.TitleBar>
              <PS.ContentObjectWrapper>
                <PS.PropteryValue>Name: <span>{select.name}</span></PS.PropteryValue>
                {/* <PS.FurnitureBoxTitle>Full furniture box</PS.FurnitureBoxTitle> */}
                <PS.PropteryValue>SKU: <span>#2021</span></PS.PropteryValue>
                <PS.CabinetTitle>Description:</PS.CabinetTitle>
                <PS.ChildTitle><span>{select.info.description}</span></PS.ChildTitle>
                <PS.SelectedBox>
                  <img src={select.info.image} style={{ objectFit: 'contain' }} />
                </PS.SelectedBox>
                <br />
                <PS.CabinetTitle>Dimensions(W*D*H)</PS.CabinetTitle>
                <PS.Diver></PS.Diver>
                <div style={attrPorpSeparatorStyle}>
                  <div style={headActionStyle}>
                    <div title={translator.t('Delete')} style={iconHeadStyle} onClick={e => projectActions.remove()}>
                      <img width={'88px'} src={'/assets/img/svg/toolbar/delete_button.svg'} />
                      <PS.ButtonTitle style={{ iconHeadStyle }}>
                        {'Delete'}
                      </PS.ButtonTitle>
                    </div>
                  </div>
                  <div style={headActionStyle1}>
                    <div title={translator.t('Duplicate')} style={iconHeadStyle} onClick={e => itemsActions.duplicateSelected(currentObject)}>
                      <img width={'88px'} src={'/assets/img/svg/toolbar/use_button.svg'} />
                      <PS.ButtonTitle style={{ iconHeadStyle }}>
                        {'Duplicate'}
                      </PS.ButtonTitle>
                    </div>
                  </div>
                </div>
                <PS.Diver></PS.Diver>
                <PS.PositionValue>Positioning</PS.PositionValue>
                {/* <PS.PositionValue>Rotate</PS.PositionValue>
                <PS.PositionValue>Resize</PS.PositionValue> */}
                <PanelElementEditor state={state} />
              </PS.ContentObjectWrapper>
            </PS.SelectedWrapper>);
          }
        }
      }
    }

    let sorter = [
      {
        key: 1,
        index: 1,
        condition: true,
        dom: (
          <FloorToolbarButton
            closeAction={this.props.closeFloorTB}
            trigger={<ToolbarButton
              active={select === MAKE_FLOOR_PLAN}
              tooltip={translator.t(MAKE_FLOOR_PLAN)}
              title={translator.t(MAKE_FLOOR_PLAN)}
              title1={[]}
              title2={[]}
              src={"/assets/img/svg/toolbar/make_floorplan_active.svg"}
              onClick={event => {
                // projectActions.setMode(MODE_IDLE);
                this.props.openFloor();
                projectActions.removeDrawingSupport();
                this.setState({ floorSubCategoryData: [] });
                this.setState({ activeButton: MAKE_FLOOR_PLAN });
              }}>
              <img id = "make_floorplan_inactive" width="75px" src="/assets/img/svg/toolbar/make_floorplan_inactive.svg" />
            </ToolbarButton>}
            props={this.props}
            drawAction={topBtnAction}
            dataSource={this.props.catalog.getCategory('root')}
            subData={this.state.floorSubCategoryData}
            action={this.getFloor.bind(this)}
            categoryCollapse={this.state.floorCategoryCollapse}
            productCollapse={this.state.floorProductCollapse}
            collapseAction={this.collapseAction.bind(this)}
            mode={mode}
            isOpen={this.props.floorOpened}
          >
          </FloorToolbarButton>
        )
      }, {
        key: 2,
        index: 2,
        condition: true,
        dom: (
          <CabinetToolbarButton
            closeAction={this.props.closeCabinetTB}
            action={this.getItem.bind(this)}
            chooseAction={this.setDoorHandle.bind(this)}
            subData={this.state.cabinetSubCategoryData}
            dataSource={this.state.vData}
            dData={this.state.oData}
            bFlag={this.state.vDataArray.length}
            props={this.props}
            isExpand={this.state.cabinetExpand}
            expandAction={this.expandCabinetAction.bind(this)}
            cabinetCollapse={this.state.cabinetCollapse}
            doorCollapse={this.state.doorCollapse}
            doorExpand={this.state.doorExpand}
            collapseAction={this.collapseAction.bind(this)}
            searchCabinet={this.state.searchCabinet}
            search={this.onSearch.bind(this)}
            mode={mode}
            isOpen={this.props.cabinetOpened}
            areaSize={this.state.areaSize}
            trigger={<ToolbarButton
              id={"cabinet_toolbar"}
              active={select === ADD_CABINETS}
              tooltip={translator.t(ADD_CABINETS)}
              title={translator.t(ADD_CABINETS)}
              title1={this.state.cabinetTitle}
              title2={this.state.doorTitle}
              src={"/assets/img/svg/toolbar/add_cabinets_active.svg"}
              onClick={event => {
                this.props.openCabinet();
                this.setState({ cabinetSubCategoryData: [], cabinetTitle: [], doorTitle: [], cabinetExpand: -1, isMolding: false });
              }}
            >
              <img id = "add_cabinets_inactive" width='75px' src="/assets/img/svg/toolbar/add_cabinets_inactive.svg" />
            </ToolbarButton>}>
          </CabinetToolbarButton>
        )
      },
      {
        key: 3,
        index: 3,
        condition: true,
        dom: (
          <CabinetDoorStyleToolbarButton
            closeAction={() => {
              this.props.toggleDoorStyle(false);
            }}
            trigger={<ToolbarButton
              active={select === SELECT_DOOR_STYLE}
              tooltip={translator.t(SELECT_DOOR_STYLE)}
              title={translator.t(SELECT_DOOR_STYLE)}
              title1={[]}
              title2={[]}
              src={"/assets/img/svg/toolbar/select_doorstyle_active.svg"}
              onClick={event => {
                this.props.toggleDoorStyle(!this.props.doorStyleOpen);
                projectActions.removeDrawingSupport();
                this.setState({ doorStyleSubCategoryData: [], doorStyleStep: 0, stepSource: this.state.DoorStyles, doorStyleExpand: -1 });
                // this.setState({ activeButton: SELECT_DOOR_STYLE });
              }}>
              <img id = "select_doorstyle_inactive" width="75px" src="/assets/img/svg/toolbar/select_doorstyle_inactive.svg" />
            </ToolbarButton>}
            step={this.state.doorStyleStep}
            mode={mode}
            props={this.props}
            dataSource={[]}
            stepSource={this.state.stepSource}
            subData={this.state.doorStyleSubCategoryData}
            action={this.getStyleCategory.bind(this)}
            chooseAction={this.getStyle.bind(this)}
            categoryCollapse={this.state.doorStyleCategoryCollapse}
            productCollapse={this.state.doorStyleProductCollapse}
            isExpand={this.state.doorStyleExpand}
            expandAction={this.expandDoorStyleAction.bind(this)}
            collapseAction={this.collapseAction.bind(this)}
            currentTexture={this.state.currentTexture}
            recentStyle={this.state.recentStyle}
            selectFromRecent={this.selectFromRecent.bind(this)}
            selected={this.selected.bind(this)}
            isOpen={this.props.doorStyleOpen}
          // onUpdate={e => this.onUpdate(e)}
          >
          </CabinetDoorStyleToolbarButton>
        )
      },

      {
        key: 4,
        index: 4,
        condition: true,
        dom: (
          <ApplianceToolbarButton
            closeAction={this.props.closeApplianceTB}
            action={this.getAppliance.bind(this)}
            subData={this.state.applianceSubCategoryData}
            dataSource={this.state.appliancesData}
            dData={this.state.oData}
            bFlag={this.state.vDataArray.length}
            props={this.props}
            isExpand={this.state.applianceExpand}
            expandAction={this.expandApplianceAction.bind(this)}
            cabinetCollapse={this.state.cabinetCollapse}
            doorCollapse={this.state.doorCollapse}
            doorExpand={this.state.doorExpand}
            collapseAction={this.collapseAction.bind(this)}
            searchCabinet={this.state.searchCabinet}
            search={this.onSearch.bind(this)}
            // categoryCollapse={this.state.applianceCategoryCollapse}
            mode={mode}
            isOpen={this.props.applianceOpened}
            applyMaterial={this.applyMaterial.bind(this)}
            areaSize={this.state.areaSize}
            trigger={
              <ToolbarButton
                active={select === ADD_APPLIANCES}
                tooltip={translator.t(ADD_APPLIANCES)}
                title={translator.t(ADD_APPLIANCES)}
                title1={this.state.cabinetTitle}
                title2={this.state.doorTitle}
                src={"/assets/img/svg/toolbar/add_appliances_active.svg"}
                onClick={event => {
                  this.props.openAppliance();
                  projectActions.removeDrawingSupport();
                  this.setState({ applianceSubCategoryData: [], cabinetTitle: [], doorTitle: [], applianceExpand: -1  });
                  this.setState({ activeButton: ADD_APPLIANCES })
                }}>
                <img id = "add_appliances_inactive" width='75px' src="/assets/img/svg/toolbar/add_appliances_inactive.svg" />
              </ToolbarButton>
            }>
          </ApplianceToolbarButton>
        )
      },
      {
        key: 5,
        index: 5,
        condition: true,
        dom: (
          <FinishingTouchToolbarButton
            closeAction={this.props.closeFinishingTB}
            dataSource={finishingtouchData}
            collapseAction={this.collapseAction.bind(this)}
            action={this.getFinishing.bind(this)}
            chooseAction={this.setDoorHandle.bind(this)}
            subData={this.state.finishingSubCategoryData}
            categoryCollapse={this.state.finishingCategoryCollapse}
            productCollapse={this.state.finishingProductCollapse}
            mode={mode}
            isOpen={this.props.finishingOpened}
            props={this.props}
            applyMaterial={this.applyMaterial.bind(this)}
            trigger={
              <ToolbarButton
                active={select === FINISHING_TOUCH}
                tooltip={translator.t(FINISHING_TOUCH)}
                title={translator.t(FINISHING_TOUCH)}
                title1={[]}
                title2={[]}
                src={"/assets/img/svg/toolbar/finishing_touches_active.svg"}
                onClick={event => {
                  this.props.openFinishing();
                  projectActions.removeDrawingSupport();
                  this.setState({ finishingSubCategoryData: []});
                  // this.setState({ activeButton: FINISHING_TOUCH });
                }}>
                <img id = "finishing_touches_inactive" width='75px' src="/assets/img/svg/toolbar/finishing_touches_inactive.svg" />
              </ToolbarButton>
            }>
          </FinishingTouchToolbarButton>
        )
      },
      {
        key: 6,
        index: 6,
        condition: true,
        dom: (
          <ReviewQuoteToolbarButton
            isOpened={this.props.reviewQuotePopupOpened}
            mode={mode}
            onClose={() => {
              this.props.onReviewQuoteClicked(false);
            }}
            trigger={
              <ToolbarButton
                active={select === REVIEW_AND_QUOTE}
                tooltip={translator.t(REVIEW_AND_QUOTE)}
                title={translator.t(REVIEW_AND_QUOTE)}
                src={"/assets/img/svg/toolbar/review_quote_active.svg"}
                onClick={event => {
                  this.props.onReviewQuoteClicked(!this.props.reviewQuotePopupOpened);
                  projectActions.removeDrawingSupport();
                  this.setState({ activeButton: REVIEW_AND_QUOTE });
                }}>
                <img id = "review_quote_inactive" width='75px' src="/assets/img/svg/toolbar/review_quote_inactive.svg" />
              </ToolbarButton>}
            dataSource={this.state.elements.items}
            catalog={this.props.catalog}
            collapse={this.state.reviewCollapse}
            collapseAction={this.collapseAction.bind(this)}
            handleSaveDesign={handleSaveDesign}
            handleSubmitForQuote={handleSubmitForQuote}
            handleDesignerAssist={handleDesignerAssist}
          />
        )
      }
    ];
    let plgUrls = this.state.plgUrls;
    for (let i = 0; i < plgUrls.length; i++) {
      dirUrl += '>';
      dirUrl += plgUrls[i];
    }
    let beforeItem;
    let plgItems;
    if (this.state.filter == '') plgItems = this.state.plgItems;
    else {
      plgItems = [];
      for (let i = 0; i < this.state.plgItems.length; i++) {
        if (this.state.plgItems[i].name.indexOf(this.state.filter) != -1)
          plgItems.push(this.state.plgItems[i]);
      }
    }
    if (plgUrls.length > 1)
      beforeItem = (
        <PlgItem

          url="before"
          gltfUrl={null}
          onClick={this.plgItemAction.bind(this)}
          name="..."
        />
      );
    if (this.state.opened) {
      let multiMark = String.fromCharCode(215);//x Mark
      panel = (
        <S.PanelWrapper>
          <div>
            <div style={{ backgroundColor: SharedStyle.TOOLBAR_COLOR.active }}>
              <S.Title>
                <b>choose room features</b>
              </S.Title>
              <S.Head>
                <S.Close>
                  <a
                    onClick={this.PanelClose.bind(this)}
                    className="roundButton"
                    onMouseOut={event => this.setState({ closeover: false })}
                    onMouseOver={event => this.setState({ closeover: true })}
                  >
                    <span>{multiMark}</span>
                  </a>
                </S.Close>
              </S.Head>
              <div style={{ clear: "both" }}>
              </div>
            </div>

            <input
              value={this.state.filter}
              onChange={this.filterChange.bind(this)}
              placeholder="filter"
              style={searchInput}
              type="text"
            ></input>
            <p style={{ float: 'left', fontSize: '80%', paddingLeft: '8px' }}>
              <b>{'features' + dirUrl}</b>
            </p>
            <div style={{ clear: 'both' }}></div>
            <div style={{ padding: '0 12px' }}>
              {beforeItem}
              {plgItems.map((data, key) => {
                return (
                  <PlgItem
                    url={data.url}
                    gltfUrl={data.gltf_url}
                    onClick={this.plgItemAction.bind(this)}
                    name={data.name}
                    key={key}
                  />
                );
              })}
            </div>
          </div>
        </S.PanelWrapper>
      );
    }
    if (this.state.animOpened) {
      let multiMark = String.fromCharCode(215);
      panel = (
        <div style={
          {
            position: "fixed",
            zIndex: 9,
            top: "615px",
            left: "10px",
            marginLeft: "100px",
            width: "310px",
            backgroundColor: SharedStyle.TOOLBAR_COLOR.focus
          }}>
          <div>
            <div style={{ backgroundColor: SharedStyle.TOOLBAR_COLOR.active }}>
              <p style={{ float: "left", fontSize: "80%", paddingLeft: "8px", color: 'white' }}><b>
                Animation
            </b>
              </p>
              <p style={{ display: "inline-block", float: "right", paddingRight: "12px", fontSize: "80%" }}>
                <a onClick={this.PanelAnimClose.bind(this)} className="roundButton"
                  onMouseOut={event => this.setState({ closeover: false })}
                  onMouseOver={event => this.setState({ closeover: true })}
                  style={{
                    borderRadius: "50%", width: "15px", height: "15px",
                    color: this.state.closeover ? "black" : "white",
                    backgroundColor: SharedStyle.MATERIAL_COLORS['500'].red,
                    cursor: "pointer", display: "inline-block", textAlign: "center"
                  }}>
                  <span style={{ fontSize: "15px", lineHeight: "15px" }}>{multiMark}</span>
                </a>
              </p>
              <div style={{ clear: "both" }}>
              </div>
            </div>
            <div style={{ marginTop: "15px" }}>
              <select value={this.state.animValue} onChange={this.setAnimValue.bind(this)} style={searchInput}>
                {this.state.animData.map((data, key) => {
                  return <option key={key} value={key}>{data.name + " animation"}</option>
                })}
              </select>
            </div>
            <div style={{ padding: "0 12px", textAlign: "center", marginTop: "15px" }}>
              <a
                onMouseOut={event => this.setState({ animover: false })}
                onMouseOver={event => this.setState({ animover: true })}
                style={{ color: this.state.animover ? "white" : "black", textAlign: "center" }}>
                <FaPlayCircle onClick={this.Animate.bind(this)} style={{ fontSize: "40px" }} />
              </a>
            </div>
          </div>
        </div>);
    }

    // control buttons of right side

    let controlView = ![MODE_3D_VIEW, MODE_IDLE_3D, MODE_ROTATING_ITEM_3D, MODE_DRAGGING_ITEM_3D, MODE_DRAWING_ITEM_3D].includes(mode) ? [
      /**
       *undo/redo button
       */
      <div
        key={"section1"}
        onKeyDown={event => event.stopPropagation()}
        onKeyUp={event => event.stopPropagation()}
        style={{
          display: "flex",
          position: "absolute",
          right: "2px",
          top: "85px",
          zIndex: "10",
          maxHeight: "0px",
          flexFlow: 'column'
        }}>
        <div style={{
          textAlign: 'right'
        }}>
          {/* <RightButton
            key={SELECTALL}
            title={translator.t(SELECTALL)}
            onClick={() => {
              if(selected.length <= 1) {
                projectActions.selectAll();
              }else{
                projectActions.unselectAll();
              }
            }}
            active = {selected.length > 1}
            src={"/assets/img/svg/topbar/select_all_active.svg"}
            icon={<img width="40px" src={selected.length > 1 ? ("/assets/img/svg/topbar/select_all_active.svg"):("/assets/img/svg/topbar/select_all_inactive.svg")} />}
          />
          */
          <RightButton
            key={EDIT}
            title={translator.t(EDIT)}
            onClick={topBtnAction}
            active={state.mode === MODE_WAITING_DRAWING_LINE}
            src={"/assets/img/svg/topbar/edit_active.svg"}
            icon={<img src={topRightImg} width="40px" />}
          />
          /*
          <RightButton key={NEW_PROJECT_BTN}
            title={translator.t(NEW_PROJECT_BTN)}
            onClick={() => {
              confirm('Would you want to start a new Project?')
                ? (projectActions.newProject(),
                  localStorage.setItem('projectTitle', "Project Name"),
                  localStorage.removeItem('projectId'),
                  localStorage.removeItem('email'))
                : null

            }}
            src={"/assets/img/svg/topbar/edit_active.svg"}
            icon={<img width="40px" src="/assets/img/svg/topbar/edit_inactive.svg" />}
          /> */}
          <RightButton key={UNDO}
            title={translator.t(UNDO)}
            onClick={() => {
              projectActions.undo();
            }}
            src={"/assets/img/svg/topbar/undo_active.svg"}
            icon={<img width="40px" src="/assets/img/svg/topbar/undo_inactive.svg" />}
          />
          <RightButton key={REDO}
            title={translator.t(REDO)}
            onClick={() => {
              projectActions.redo();
            }}
            src={"/assets/img/svg/topbar/redo_active.svg"}
            icon={<img width="40px" src="/assets/img/svg/topbar/redo_inactive.svg" />}
          />
          <RightButton
            title={translator.t(TAKE_PICTURE)}
            onClick={saveSVGScreenshotToFile}
            src={"/assets/img/svg/topbar/take_picture_active.svg"}
            icon={<img width="40px" src="/assets/img/svg/topbar/take_picture_inactive.svg" />}
          />
        </div>
        {objectProperty}
      </div>
    ] : [
        <div
          key={"section1"}
          onKeyDown={event => event.stopPropagation()}
          onKeyUp={event => event.stopPropagation()}
          style={{
            display: "flex",
            position: "absolute",
            right: "2px",
            top: "85px",
            zIndex: "10",
            maxHeight: "0px",
            flexFlow: 'column'
          }}>
          <div style={{
          textAlign: 'right'
          }}>
            {/* <RightButton key={NEW_PROJECT_BTN}
              title={translator.t(NEW_PROJECT_BTN)}
              onClick={() => {
                confirm('Would you want to start a new Project?')
                  ? (projectActions.newProject(),
                    localStorage.setItem('projectTitle', "Project Name"),
                    localStorage.removeItem('projectId'),
                    localStorage.removeItem('email'))
                  : null

              }}
              src={"/assets/img/svg/topbar/edit_active.svg"}
              icon={<img width="40px" src="/assets/img/svg/topbar/edit_inactive.svg" />}
            /> */}
            <RightButton
              key={EDIT}
              title={translator.t(EDIT)}
              onClick={topBtnAction}
              active={state.mode === MODE_WAITING_DRAWING_LINE}
              src={"/assets/img/svg/topbar/edit_active.svg"}
              icon={<img src={topRightImg} width="40px" />}
            />
            <RightButton key={UNDO}
              title={translator.t(UNDO)}
              onClick={() => {
                projectActions.undo();
              }}
              src={"/assets/img/svg/topbar/undo_active.svg"}
              icon={<img width="40px" src="/assets/img/svg/topbar/undo_inactive.svg" />}
            />
            <RightButton key={REDO}
              title={translator.t(REDO)}
              onClick={() => {
                projectActions.redo();
              }}
              src={"/assets/img/svg/topbar/redo_active.svg"}
              icon={<img width="40px" src="/assets/img/svg/topbar/redo_inactive.svg" />}
            />
            <RightButton
              title={translator.t(TAKE_PICTURE)}
              onClick={saveScreenshotToFile}
              src={"/assets/img/svg/topbar/take_picture_active.svg"}
              icon={<img width="40px" src="/assets/img/svg/topbar/take_picture_inactive.svg" />}
            />
          </div>
          {objectProperty}
        </div>
      ];

    return (
      <aside
        style={{ ...ASIDE_STYLE, maxWidth: ![MODE_3D_VIEW, MODE_IDLE_3D, MODE_ROTATING_ITEM_3D, MODE_DRAGGING_ITEM_3D, MODE_DRAWING_ITEM_3D].includes(mode) ? width : 90, maxHeight: height }}
      >
        {sorter.sort(sortButtonsCb).map(mapButtonsCb)}
        {panel}
        {controlView}
        {hintText}
        <canvas
          id = "canvas_crop_image"
          style={{
            zIndex: -1,
            position: "fixed",
            top: "0px",
            left: "0px"
          }}
          onMouseDown = { (e) => {
            this.onMouseDown (e);
          }}
          onMouseMove = { (e) => {
            this.onMouseMove (e);
          }}
          onMouseUp = { (e) => {
            this.onMouseUp (e);
          }}
        >
          The SCREENSHOT is not available in this browser!!!
        </canvas>
      </aside>
    );
  }
}

Toolbar.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  content: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  allowProjectFileSupport: PropTypes.bool.isRequired,
  toolbarButtons: PropTypes.array,
};

Toolbar.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  viewer2DActions: PropTypes.object.isRequired,
  viewer3DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  areaActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired
};
