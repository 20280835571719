import React, { useState } from 'react';


export default function ReviewItem({catalog, itemID, item}) {
  let review;
  let doorColor = item.doorStyle;
  if(catalog !== undefined) {
    review = catalog.elements[item.type];
    if(item.doorStyle.size){
      doorColor = item.doorStyle.toJS();
    }
    return (
      <div style={{display: 'flex', alignItems: 'center', padding: '5px 15px 5px', borderBottom: '1px solid #2f2f2f', minWidth: 400, color: '#FFF'}}>
        <div style={{ width: '100%'}}>
          <div style={{ display: 'flex', alignItems:'center', marginTop:'5px',marginBottom:'5px'}}>
            <img width={40} name={"cabinetImage"} src={review.img} />
            {/* <span style={{marginLeft: 23, fontSize: 12,}}>{name}</span> */}
            <div style={{width: '90%'}}>
              <table style = {{width:'100%', paddingLeft: '15px', fontSize: '10px'}}>
                <thead>
                  <tr>
                    <td style = {{ width: "90%" }}><strong>{review.long_name}</strong></td>
                    <td><strong>Qty</strong></td>
                    {/* <td><strong>Price/Unit</strong></td>
                    <td><strong>Subtotal</strong></td> */}
                  </tr>
                </thead>
                <tbody>
                    <tr>
                      <td>
                        <p>
                        <strong>SKU#:</strong> {`${review.obj.sku_number}${doorColor.sku ? `-${doorColor.sku}` : ""}`} <br/>
                          <strong>Door Style:</strong> {review.obj.door_style} <br/>
                          <strong>Door Color:</strong> {doorColor.name} <br/>
                        </p>
                      </td>
                      <td>1</td>
                      {/* <td>0</td>
                      <td>0</td> */}
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
    
  }else{
    null
  }
  
}
