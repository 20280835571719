import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import convert from 'convert-units';
import axios from 'axios'
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import SaveDesignPopup from './components/toolbar/popup/savedesign';
import SubmitForQuotePopup from './components/toolbar/popup/submitforquote';
import DesignerAssistPopup from './components/toolbar/popup/designerassist';
import NewProjectPopup from './components/toolbar/popup/newproject';
import * as constants from './constants';
import {
  Plugins as PlannerPlugins,
} from 'KitchenConfigurator';

import TutorialModal from "./components/tutorial-view/Modal.jsx";

const AWS = require('aws-sdk');

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET
});

import { Project } from './class/export';
import Translator from './translator/translator';
import actions from './actions/export';
import Catalog from './catalog/catalog';
import { objectsMap } from './utils/objects-utils';
import {
  ToolbarComponents,
  Content,
  SidebarComponents,
  FooterBarComponents,
  HeaderComponents
} from './components/export';
import { VERSION } from './version';
import './styles/export';
import { API_SERVER_URL, MODE } from './constants';
import jwtService from './components/login/jwtService';


const { Header } = HeaderComponents;
const { Toolbar } = ToolbarComponents;
const { Sidebar } = SidebarComponents;
const { FooterBar } = FooterBarComponents;
const { Tutorial } = TutorialModal;

const toolbarW = 0;
const sidebarW = 250;
const footerBarH = 20;

const wrapperStyle = {
  display: 'flex',
  flexFlow: 'row nowrap'
};

const uploadFiles = (data, filePath) => {
  var p1 = new Promise((resolve, reject) => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: filePath,
      Body: data,
      ContentType: "application/json",
      ACL: 'public-read'
    };
    s3.putObject(params, function (err, data) {
      if (err) {
        reject(err);
        return;
      }
      console.log("success");
      resolve([data.Location]);
    });
  });
  return p1;
};

class KitchenConfigurator extends Component {

  constructor(props) {
    super(props);

    // utm tracking
    let utmParams = new URLSearchParams(this.props.location && this.props.location.search);

    // Check for tutorial modal visibility setting
    let isModalVisible = localStorage.getItem('show tutorial dialog again') === 'false' ? false : true;

    this.state = {
      savePopupVisible: false,
      quotePopupVisible: false,
      assistPopupVisible: false,
      newProjectPopupVisible: false,
      isModalVisible,
      tutorial_state: 1,

      // For Toolbar Item
      reviewQuotePopupOpened: false,
      floorOpened: false,
      cabinetOpened: false,
      applianceOpened: false,
      finishingOpened: false,
      doorStyleOpen: false,
      tutorialMouseEventsAdd: false
    };

    // For UTM tracking
    this.utm = {
      utm_source: utmParams.get("utm_source") || "source",
      utm_medium: utmParams.get("utm_medium") || "medium",
      utm_campaign: utmParams.get("utm_campaign") || "campaign",
      utm_term: utmParams.get("utm_term") || "term",
      utm_content: utmParams.get("utm_content") || "content",
      gclid: utmParams.get("gclid") || "gclid",
      msclkid: utmParams.get("msclkid") || "msclkid",
      fbclid: utmParams.get("fbclid") || "fbclid",
      ttclid: utmParams.get("ttclid") || "ttclid",
    }

    this.jwtCheck();

    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.openFloor = this.openFloor.bind(this);
    this.openCabinet = this.openCabinet.bind(this);
    this.openFinishing = this.openFinishing.bind(this);
    this.openAppliance = this.openAppliance.bind(this);
    this.toggleDoorStyle = this.toggleDoorStyle.bind(this);
    this.onReviewQuoteClicked = this.onReviewQuoteClicked.bind(this);
    this.closeFloorTB = this.closeFloorTB.bind(this);
    this.closeCabinetTB = this.closeCabinetTB.bind(this);
    this.closeFinishingTB = this.closeFinishingTB.bind(this);
    this.closeApplianceTB = this.closeApplianceTB.bind(this);
    this.onMouseDownforPopup = this.onMouseDownforPopup.bind(this);
    this.onMouseMoveforPopup = this.onMouseMoveforPopup.bind(this);
    this.onMouseClickforPopup = this.onMouseClickforPopup.bind(this);
    this.neverShowInput = React.createRef(null);
  }

  // Toolbar control functions

  openFloor() {
    this.setState({ floorOpened: true });
    document.getElementById ("make_floorplan_inactive").parentElement.parentElement.style.zIndex = 999;
  }

  openCabinet() {
    this.setState({ cabinetOpened: true });
    document.getElementById ("add_cabinets_inactive").parentElement.parentElement.style.zIndex = 999;
  }

  toggleDoorStyle(visible) {
    this.setState({ doorStyleOpen: visible });
    document.getElementById ("select_doorstyle_inactive").parentElement.parentElement.style.zIndex = visible ? 999 : 6;
  }

  openAppliance() {
    this.setState({ applianceOpened: true });
    document.getElementById ("add_appliances_inactive").parentElement.parentElement.style.zIndex = 999;
  }

  openFinishing() {
    this.setState({ finishingOpened: true });
    document.getElementById ("finishing_touches_inactive").parentElement.parentElement.style.zIndex = 999;
  }

  onReviewQuoteClicked(visible) {
    this.setState({ reviewQuotePopupOpened: visible });
    document.getElementById ("review_quote_inactive").parentElement.parentElement.style.zIndex = visible ? 999 : 6;
  }

  closeFloorTB() {
    this.setState({ floorOpened: false });
    document.getElementById ("make_floorplan_inactive").parentElement.parentElement.style.zIndex = 6;
  }

  closeCabinetTB() {
    this.setState({ cabinetOpened: false });
    document.getElementById ("add_cabinets_inactive").parentElement.parentElement.style.zIndex = 6;
  }

  closeFinishingTB() {
    this.setState({ finishingOpened: false });
    document.getElementById ("finishing_touches_inactive").parentElement.parentElement.style.zIndex = 6;
  }

  closeApplianceTB() {
    this.setState({ applianceOpened: false });
    document.getElementById ("add_appliances_inactive").parentElement.parentElement.style.zIndex = 6;
  }

  closeDoorstyle() {
    this.setState({});
    document.getElementById ("select_doorstyle_inactive").parentElement.parentElement.style.zIndex = 6;
  }
  closeReviewQuote() {
    this.setState({});
    document.getElementById ("review_quote_inactive").parentElement.parentElement.style.zIndex = 6;
  }


  jwtCheck() {
    jwtService.on('onAutoLogin', () => {

      // this.props.showMessage({message: 'Logging in with JWT'});

      /**
       * Sign in and retrieve user data from Api
       */
      jwtService.signInWithToken()
        .then(user => {
          //this.props.userActions.setUserData(user);
          this.props.userActions.login(user.id, user.role, jwtService.getAccessToken(), this.utm);
          //this.props.showMessage(option)
          //this.props.showMessage({message: 'Logged in with token data',  variant: 'success'});
          //alert('Logged in with token data');
        })
        .catch(error => {
          //this.props.showMessage({message: error});
          alert(error)
        })
    });

    jwtService.on('onAutoLogout', (message) => {
      if (message) {
        //this.props.showMessage({message, variant: 'error'});
      }
      this.props.userActions.logout();
    });

    jwtService.init();
  }

  getChildContext() {
    return {
      ...objectsMap(actions, actionNamespace => this.props[actionNamespace]),
      translator: this.props.translator,
      catalog: this.props.catalog,
    }
  }

  saveProjectToStorage() {
    let { state, stateExtractor } = this.props;
    const extractedState = stateExtractor(state);
    const projectData = JSON.stringify(extractedState.get('scene').toJS());
    sessionStorage.setItem(window.location.href, projectData);
  }

  onMouseDownforPopup (e) {
    if (!this.state.isModalVisible) return;
    e.path.forEach(element => {
      if (
        element.attributes !== undefined &&
        element.attributes.id !== undefined
      ) {
        if (
          element.attributes.id.value === 'tutorial_dialog' &&
          e.target.className !==
            'MuiDialog-container MuiDialog-scrollPaper'
        ) {
          if (
            e.target.tagName === 'path' ||
            e.target.tagName === 'INPUT' ||
            e.target.tagName === 'BUTTON' ||
            e.target.textContent === 'Do not show this again' ||
            e.target.textContent === 'Close Guide' ||
            e.target.textContent === '< Prev' ||
            e.target.textContent === ' Next >'
          ) {
            // console.log('onmousedown_OK!!!');
          } else {
            return e.stopPropagation();
          }
        } else {
          return e.stopPropagation();
        }
      }
    });
  };
  onMouseMoveforPopup (e) {
    if (!this.state.isModalVisible) {
      if (document.getElementById ("coverToggle2D3DDiv") !== null) {
        document.body.removeChild (document.getElementById ("coverToggle2D3DDiv"));
      }
      if (document.getElementById ("coverPopupDiv") !== null) {
        document.body.removeChild (document.getElementById ("coverPopupDiv"));
      }
      document.body.removeEventListener ("mousedown", this.onMouseDownforPopup, false);
      document.body.removeEventListener ("mousemove", this.onMouseMoveforPopup, false);
      document.body.removeEventListener ("click", this.onMouseClickforPopup, false);
      this.setState ({ tutorialMouseEventsAdd: false });
      return;
    } else if (!this.state.tutorialMouseEventsAdd) {
      document.body.addEventListener ("mousedown", this.onMouseDownforPopup, false);
      document.body.addEventListener ("mousemove", this.onMouseMoveforPopup, false);
      document.body.addEventListener ("click", this.onMouseClickforPopup, false);
      this.setState ({ tutorialMouseEventsAdd: true });
    }
    if (this.state.isModalVisible && document.getElementById ("coverToggle2D3DDiv") === null) {
      let coverToggle2D3DDiv = document.createElement ("div");
      let toggle2D3DContent = document.getElementById ("toggle_2d_3d");
      let toggle2D3DClientRect = toggle2D3DContent.getBoundingClientRect();
      if (toggle2D3DContent !== undefined) {
        if (toggle2D3DContent.style !== undefined) {
          coverToggle2D3DDiv.setAttribute(
            'style',
            `
              position: absolute;
              z-index: 1001;
              pointer-events: auto;
              top: ${toggle2D3DClientRect.top}px;
              left: ${toggle2D3DClientRect.left}px;
              width: ${toggle2D3DClientRect.width}px;
              height: ${toggle2D3DClientRect.height}px;
            `
          );
        }
        coverToggle2D3DDiv.setAttribute ("id", "coverToggle2D3DDiv");
        document.body.appendChild (coverToggle2D3DDiv);
      }
    }
    if (this.state.isModalVisible && document.getElementById ("coverPopupDiv") === null) {
      let coverPopupDiv = document.createElement ("div");
      let popupContent = document.getElementsByClassName ("popup-content")[0];
      if (popupContent !== undefined) {
        if (document.getElementsByClassName ("popup-content")[0].style !== undefined) {
          coverPopupDiv.setAttribute ("style", "position: absolute; z-index: 1000; pointer-events: auto; top: 85px; left: 99px;");
        }
        coverPopupDiv.setAttribute ("id", "coverPopupDiv");
        coverPopupDiv.style.zIndex = 1000;
        document.body.appendChild (coverPopupDiv);
      }
    } else if (this.state.isModalVisible) {
      let popupContent = document.getElementsByClassName ("popup-content")[0];
      if (popupContent !== undefined) {
        let popupContentClientRect = popupContent.getBoundingClientRect();
        document
          .getElementById('coverPopupDiv')
          .setAttribute(
            'style',
            `
              position: absolute;
              z-index: 1000;
              pointer-events: auto;
              top: 85px;
              width: ${popupContentClientRect.width + 99}px;
              height: ${popupContentClientRect.height}px
            `
          );
      }
    }
    if (
      e.target.className === 'MuiDialog-container MuiDialog-scrollPaper'
    ) {
      // document.body.style.cursor = "not-allowed";
      return e.stopPropagation();
    }
    e.path.forEach(element => {
      if (
        element.attributes !== undefined &&
        element.attributes.id !== undefined
      ) {
        if (
          element.attributes.id.value === 'tutorial_dialog' &&
          e.target.className !==
            'MuiDialog-container MuiDialog-scrollPaper'
        ) {
          // document.body.style.cursor = "auto";
          if (
            e.target.tagName === 'path' ||
            e.target.tagName === 'INPUT' ||
            e.target.tagName === 'BUTTON' ||
            e.target.textContent === 'Do not show this again' ||
            e.target.textContent === 'Close Guide' ||
            e.target.textContent === '< Prev' ||
            e.target.textContent === ' Next >'
          ) {
            // console.log('onmousedown_OK!!!');
          } else {
            return e.stopPropagation();
          }
        } else {
          // document.body.style.cursor = "not-allowed";
          return e.stopPropagation();
        }
      }
    });
  };
  onMouseClickforPopup (e) {
    if (!this.state.isModalVisible) return;
    if (
      e.target.className === 'MuiDialog-container MuiDialog-scrollPaper'
    ) {
      return e.stopPropagation();
    }
    e.path.forEach(element => {
      if (
        element.attributes !== undefined &&
        element.attributes.id !== undefined
      ) {
        if (
          element.attributes.id.value === 'tutorial_dialog' &&
          e.target.className !==
            'MuiDialog-container MuiDialog-scrollPaper'
        ) {
          // console.log('onclick_OK!!!');
        } else {
          return e.stopPropagation();
        }
      }
    });
  };

  componentDidMount() {
    document.body.addEventListener ("mousedown", this.onMouseDownforPopup, false);
    document.body.addEventListener ("mousemove", this.onMouseMoveforPopup, false);
    document.body.addEventListener ("click", this.onMouseClickforPopup, false);
    this.setState ({ tutorialMouseEventsAdd: true });
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    hotjar.event && hotjar.event(`pageview:${window.location.pathname}`);

    /*
      When the page refreshed the project data do not lost.
    */
    window.onbeforeunload = () => {
      this.saveProjectToStorage();
      return null;
    }

    window.forRedo = [];
    let { store } = this.context;
    let { projectActions, catalog, stateExtractor, plugins } = this.props;

    projectActions.initCatalog(catalog);

    let { match } = this.props;

    if (sessionStorage.getItem(window.location.href)) {
      const jsonData = sessionStorage.getItem(window.location.href);
      projectActions.loadProject(JSON.parse(jsonData));
      sessionStorage.removeItem(window.location.href);
      return;
    }

    if (match && match.params.pid === undefined) {
      let newplugins = [...plugins, PlannerPlugins.Autosave('KitchenConfigurator_v0', false)];
      newplugins.forEach(newplugin => newplugin(store, stateExtractor));
      projectActions.newProject();
      localStorage.setItem('projectTitle', "Project Name");
      localStorage.removeItem('projectId');
      localStorage.removeItem('email');
      return;
    }

    if (match && match.params.pid !== undefined) {
      if (match.params.pid === 'new') {
        projectActions.newProject();
        localStorage.setItem('projectTitle', "Project Name");
        localStorage.removeItem('projectId');
        localStorage.removeItem('email');
      } else {
        axios.post(`${constants.API_SERVER_URL}/api/project/loadPidProject`, {
          pid: match.params.pid
        }).then(async response => {
          const { projectElement } = response.data;
          if (projectElement.length === 0) return;
          localStorage.setItem('projectTitle', projectElement[0].title);
          localStorage.setItem('projectId', projectElement[0].id);
          localStorage.setItem('email', projectElement[0].email);
          let jsonData;
          if (projectElement[0].project_data) {
            jsonData = JSON.parse(projectElement[0].project_data);
          } else {
            try {
              jsonData = await axios.post(`${constants.API_SERVER_URL}/api/project/loadPidData`, { pid: match.params.pid }).data.data;
            } catch(err) {
              alert(err);
            }
          }
          jsonData.isLoadingCabinet = false;
          projectActions.loadProject(jsonData);
        });
      }
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
    document.body.removeEventListener ("mousedown", this.onMouseDownforPopup, false);
    document.body.removeEventListener ("mousemove", this.onMouseMoveforPopup, false);
    document.body.removeEventListener ("click", this.onMouseClickforPopup, false);
  }

  componentWillMount() {
  }

  componentWillReceiveProps(nextProps) {
    let { stateExtractor, state, projectActions, catalog } = nextProps;
    let plannerState = stateExtractor(state);
    let catalogReady = plannerState.getIn(['catalog', 'ready']);
    if (!catalogReady) {
      projectActions.initCatalog(catalog);
    }
  }

  handleSaveDesign(value) {
    let { stateExtractor, state } = this.props;
    let plannerState = stateExtractor(state);
    let items = plannerState.getIn(['scene', 'layers', 'layer-1', 'items']);
    let areas = plannerState.getIn(['scene', 'layers', 'layer-1', 'areas']);
    if (areas.size === 0) {
      alert('Your project is empty.');
      return;
    }
    if (items.size === 0) {
      alert('You have to add least one item.');
      return;
    }
    this.setState({ reviewQuotePopupOpened: false });
    this.setState({ savePopupVisible: value });
    this.setState({ quotePopupVisible: false });
    this.setState({ assistPopupVisible: false });
    this.setState({ newProjectPopupVisible: false });
  }

  handleNewProject(value) {
    this.setState({ reviewQuotePopupOpened: false });
    this.setState({ savePopupVisible: false });
    this.setState({ quotePopupVisible: false });
    this.setState({ assistPopupVisible: false });
    this.setState({ newProjectPopupVisible: value });
  }

  handleSubmitForQuote(value) {
    this.setState({ reviewQuotePopupOpened: false });
    this.setState({ savePopupVisible: false });
    this.setState({ quotePopupVisible: value });
    this.setState({ assistPopupVisible: false });
    this.setState({ newProjectPopupVisible: false });
  }

  handleDesignerAssist(value) {
    this.setState({ reviewQuotePopupOpened: false });
    this.setState({ savePopupVisible: false });
    this.setState({ quotePopupVisible: false });
    this.setState({ assistPopupVisible: value });
    this.setState({ newProjectPopupVisible: false });
  }

  showModal() {
    this.setState({ isModalVisible: true });
  }

  hideModal(event, reason) {
    if (reason != 'backdropClick') {
      if (this.neverShowInput.current.checked) {
        localStorage.setItem('show tutorial dialog again', 'false');
      }
      this.setState({
        isModalVisible: false,
        reviewQuotePopupOpened: false,
        floorOpened: false,
        cabinetOpened: false,
        applianceOpened: false,
        finishingOpened: false,
        doorStyleOpen: false
      });
    }
  }

  render() {
    let { width, height, state, stateExtractor, ...props } = this.props;
    let { savePopupVisible, quotePopupVisible, assistPopupVisible, newProjectPopupVisible } = this.state;
    let contentW = width - toolbarW;
    let categoryData = this.props.categoryData;

    // console.log(categoryData);
    // let contentW = width - toolbarW - sidebarW;
    // let toolbarH = height - footerBarH;
    // let contentH = height - footerBarH;
    // let sidebarH = height - footerBarH;
    let toolbarH = height;
    let contentH = height;
    let sidebarH = height;
    let headerW = width;
    let headerH = 70;

    let extractedState = stateExtractor(state);
    //console.log('KC state - mode: ', extractedState.mode);
    let doorStyle = extractedState.get('doorStyle');
    if (
      extractedState.getIn([
        'scene',
        'layers',
        extractedState.scene.selectedLayer,
        'doorStyle'
      ]) !== undefined &&
      extractedState.getIn([
        'scene',
        'layers',
        extractedState.scene.selectedLayer,
        'doorStyle'
      ]) !== null
    ) {
      doorStyle = extractedState.getIn([
        'scene',
        'layers',
        extractedState.scene.selectedLayer,
        'doorStyle'
      ]);
    }

    let _scene = extractedState.getIn(['scene']);
    let len = convert(_scene.width).from(_scene.unit).to('cm');
    let _viewer2D = extractedState.getIn(['viewer2D']);
    if (_viewer2D.size > 0) {
      let scaleX = width / len * 3;
      let scaleY = height / len * 3;
      let scale = scaleX > scaleY ? scaleY : scaleX;
      let _e = width - len * scale, _f = height - len * scale;
      let viewer = _viewer2D.toJS();
      if (viewer.e === 0 && viewer.f === 0) {
        _viewer2D = _viewer2D.merge({
          e: viewer.viewerWidth / 2 - viewer.SVGWidth / 2,
          f: viewer.viewerHeight / 2 - viewer.SVGHeight / 2,
          a: 0.99,
          d: 0.99,
        });
      }
    }
    extractedState = extractedState.merge({
      viewer2D: _viewer2D,
    });

    let userInfo = [];
    if (this.props.match) userInfo = this.props.match.params;
    var location = this.props.location;
    var history = this.props.history;

    let newProject = () => {
      this.props.projectActions.newProject();
      localStorage.setItem('projectTitle', "Project Name");
      localStorage.removeItem('projectId');
      localStorage.removeItem('email');
      localStorage.removeItem('phone');
    }

    /**
     * @description Save Projects
     * @param {{email,firstName,lastName,notes,projectName}} param Project information to save
     */
    let saveProject = param => {
      // Get storage info
      let title = localStorage.getItem('projectTitle');
      let projectId = localStorage.getItem('projectId');

      let projectTitle = (param && param.projectName) || title || `Project-${new Date().toLocaleString()}`;
      let utm = this.utm;

      // State
      let state = Project.unselectAll(extractedState).updatedState;
      let { scene } = state;
      state = state.merge({ scene: scene.merge({ "title": projectTitle }) });

      // Set storage info
      localStorage.setItem('projectTitle', projectTitle);

      // Data to save
      const projectData = JSON.stringify(state.get('scene').toJS());

      axios
        .post('/api/project/saveProject', {
          pid: projectId,
          title: projectTitle,
          mode: MODE,
          access_token: userInfo.token ? userInfo.token : null,
          userrole: userInfo.role ? userInfo.role : null,
          saveData: { ...param, ...utm, projectData }
        })
        .then(response => {
          const { success, userData, access_token } = response.data;
          if (success === true) {
            const { insertId } = response.data.execResult;
            const currentProjectID = insertId !== 0 ? insertId : state.getIn(['scene', 'currentProjectID']);

            // Set storage data
            localStorage.setItem('projectId', currentProjectID);
            localStorage.setItem('email', param.email);
            props.projectActions.setProjectProperties({ currentProjectID });
            actions.userActions.login(userData[0].id, userData[0].role, access_token, utm);
            alert('Successfully saved');
          } else {
            alert('Failed to add project data');
          }
        })
        .catch(err => {
          console.error(err);
          alert('Failed to add project data');
        });
    };

    let pidSplit = userInfo.pid && userInfo.pid.indexOf('?');
    // If pidSplit is undefined pid is undefined
    let pid = ((pidSplit === undefined) || (pidSplit === -1)) ? userInfo.pid : userInfo.pid.slice(0, pidSplit);

    let submitForQuote = (param) => {
      if (!userInfo.token) {
        alert('You should save the project first.');
        return;
      }
      axios.post(`${API_SERVER_URL}/api/project/submitProject`, {
        access_token: userInfo.token,
        data: param,
        num: true,
        projectid: pid,
        search: this.props.location.search,
        userrole: userInfo.role
      }).then(response => {
        const { success } = response.data;
        if (success) {
          localStorage.setItem('email', param.email);
          localStorage.setItem('phone', param.phone);
          alert('Project submitted successfully.');
        }
        else
          alert('Submitting project failed.');
      }).catch(err => {
        console.log('err', err);
      })
    };

    let designerAssist = ({ email, phone, issue_type, notes }) => {
      console.log("dd", history)
      if (history.location.pathname != "/" || history.location.hash != "") {
        var path = history.location.pathname;
        var params = path.split('/');
        console.log(path, params)
        if (params.length > 3) {
          if (!params[params.length - 2]) {
            alert('You should save the project first.');
            return;
          }
          axios.post(`${API_SERVER_URL}/api/project/designerAssist`, {
            email,
            phone,
            issue_type,
            notes,
            num: true,
            projectid: pid,
            access_token: params[params.length - 2],
            userrole: params[params.length - 3]
          }).then(response => {
            const { success } = response.data;
            if (success) {
              localStorage.setItem('phone', phone);
              alert('Assistance requested successfully.\nA designer will soon be in touch via your preferred communication method');
            }
            else
              alert('Assistance failed.');
          }).catch(err => {
            console.log('err', err);
          })
        }
        else {
          alert('You should save the project first.');
        }
      } else {
        alert('You should save the project first.');
      }
    };


    return (
      <section>
        <TutorialModal
          ok={this.hideModal}
          cancel={this.hideModal} {...props}
          isModalVisible={this.state.isModalVisible}
          toggleDoorStyle={this.toggleDoorStyle}
          openFloor={this.openFloor}
          openCabinet={this.openCabinet}
          openAppliance={this.openAppliance}
          openFinishing={this.openFinishing}
          closeFloorTB={this.closeFloorTB}
          closeCabinetTB={this.closeCabinetTB}
          closeFinishingTB={this.closeFinishingTB}
          closeApplianceTB={this.closeApplianceTB}
          onReviewQuoteClicked={this.onReviewQuoteClicked}
          neverShowInput={this.neverShowInput}
        />
        <div style={{ ...wrapperStyle, height }}>
          <Header
            width={headerW}
            height={headerH}
            state={extractedState}
            reviewQuoteHandler={this.onReviewQuoteClicked.bind(this)}
            handleSaveDesign={this.handleSaveDesign.bind(this)}
            handleSubmitForQuote={this.handleSubmitForQuote.bind(this)}
            handleNewProject={this.handleNewProject.bind(this)}
            handleDesignerAssist={this.handleDesignerAssist.bind(this)}
            saveProject={saveProject}
            newProject={newProject}
            isModalVisible={this.state.isModalVisible}
            {...props}
          />
          <Toolbar
            width={toolbarW}
            content={contentW}
            height={toolbarH}
            state={extractedState}
            layers={extractedState.scene.layers}
            selectedLayer={extractedState.scene.selectedLayer}
            {...props}
            handleSaveDesign={this.handleSaveDesign.bind(this)}
            handleSubmitForQuote={this.handleSubmitForQuote.bind(this)}
            handleNewProject={this.handleNewProject.bind(this)}
            handleDesignerAssist={this.handleDesignerAssist.bind(this)}
            openFloor={this.openFloor}
            openCabinet={this.openCabinet}
            openAppliance={this.openAppliance}
            openFinishing={this.openFinishing}
            closeFloorTB={this.closeFloorTB}
            closeCabinetTB={this.closeCabinetTB}
            closeFinishingTB={this.closeFinishingTB}
            closeApplianceTB={this.closeApplianceTB}
            reviewQuotePopupOpened={this.state.reviewQuotePopupOpened}
            floorOpened={this.state.floorOpened}
            cabinetOpened={this.state.cabinetOpened}
            applianceOpened={this.state.applianceOpened}
            finishingOpened={this.state.finishingOpened}
            doorStyleOpen={this.state.doorStyleOpen}
            toggleDoorStyle={this.toggleDoorStyle}
            onReviewQuoteClicked={this.onReviewQuoteClicked}
          />
          <Content
            width={contentW}
            height={contentH}
            state={extractedState}
            {...props}
            onWheel={event => event.preventDefault()}
          />
          <FooterBar
            content={contentW}
            layers={extractedState.scene.layers}
            state={extractedState}
            selectedLayer={extractedState.scene.selectedLayer}
            {...props}
          />

          <SaveDesignPopup visible={savePopupVisible} handleSaveDesign={this.handleSaveDesign.bind(this)} saveProject={saveProject} />
          <SubmitForQuotePopup
            categoryData={categoryData}
            curDoorStyle={doorStyle}
            handleSubmitForQuote={this.handleSubmitForQuote.bind(this)}
            submitForQuote={submitForQuote}
            visible={quotePopupVisible}
            setDoorStyle={this.props.itemsActions.setDoorStyle}
          />
          <DesignerAssistPopup visible={assistPopupVisible} handleDesignerAssist={this.handleDesignerAssist.bind(this)} designerAssist={designerAssist} />
          <NewProjectPopup visible={newProjectPopupVisible} handleNewProject={this.handleNewProject.bind(this)} newProject={newProject} />
        </div>
      </section>
    );
  }
}

KitchenConfigurator.propTypes = {
  translator: PropTypes.instanceOf(Translator),

  catalog: PropTypes.instanceOf(Catalog),
  allowProjectFileSupport: PropTypes.bool,
  plugins: PropTypes.arrayOf(PropTypes.func),
  autosaveKey: PropTypes.string,
  autosaveDelay: PropTypes.number,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  stateExtractor: PropTypes.func.isRequired,
  toolbarButtons: PropTypes.array,
  sidebarComponents: PropTypes.array,
  footerbarComponents: PropTypes.array,
  customContents: PropTypes.object,
  softwareSignature: PropTypes.string
};

KitchenConfigurator.contextTypes = {
  store: PropTypes.object.isRequired,
};

KitchenConfigurator.childContextTypes = {
  ...objectsMap(actions, () => PropTypes.object),
  translator: PropTypes.object,
  catalog: PropTypes.object,
};

KitchenConfigurator.defaultProps = {
  translator: new Translator(),
  catalog: new Catalog(),
  plugins: [],
  allowProjectFileSupport: true,
  softwareSignature: `KitchenConfigurator ${VERSION}`,
  toolbarButtons: [],
  sidebarComponents: [],
  footerbarComponents: [],
  customContents: {},
};

//redux connect
function mapStateToProps(reduxState) {
  return {
    state: reduxState
  };
}

function mapDispatchToProps(dispatch) {
  return objectsMap(actions, actionNamespace =>
    bindActionCreators(actions[actionNamespace], dispatch)
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KitchenConfigurator);
