import React from 'react';
import ToolbarPanel from '../../../../sidebar/toolbar-panel';
import * as S from '../../styles';

import Product from '../../product';
import Floor from '../../product/floor';

export const FloorCategory = ({action,  dataSource , isExpand, closeAction }) => {

  let miHeight = 0;
  let nonHeight = (495 - miHeight) + "px";
  let height = (window.innerHeight * 0.6 - miHeight) + "px";
  var result = [];
  dataSource.map(el => {
      result.push(
        <Product
          key={el.name}
          url={el.name === "Windows" ? "/assets/img/svg/toolbar/add_items_windows.svg" : "/assets/img/svg/toolbar/add_items_doors.svg"}
          title={el.name}
          onClick={() => {action(el.name)}}
        />
      );
  })
  return (
    <S.CategoryContentWrapper style={{ height:  isExpand ? height : nonHeight}}>
      <ToolbarPanel
        opened
        onClose={() => {}}
        onOpen={() => {}}
        isUrl={false}
        name={"Floor Space"}
        url={null}>
        <Floor
          url={'/assets/img/svg/toolbar/draw_custom_floor.svg'}
          title={'Draw Custom Floor'}
          onClick={() => {action(-1)}}
        >
        </Floor>
        <Floor
          url={'/assets/img/svg/toolbar/predefined_room_layout.svg'}
          title={'Predefined Room Layout'}
          onClick={() => {action(-2)}}
        >
        </Floor>
      </ToolbarPanel>
      <ToolbarPanel
          opened
          onClose={() => {}}
          onOpen={() => {}}
          isUrl={false}
          name={"Room Elements"}
          url={null}>
          {result}
        </ToolbarPanel>
    </S.CategoryContentWrapper>

  )
}
