import * as Three from 'three';
import createGrid from './grid-creator';
import { disposeObject } from './three-memory-cleaner';
import { Color } from 'three';
import { isUndefined } from 'util';
import {
  MODE_DRAWING_ITEM_3D,
  EPSILON,
  OBJTYPE_MESH,
  ARROW_COLOR,
  ARROW_TEXT_FORECOLOR,
  ARROW_TEXT_BACKCOLOR,
  ARROW_TEXT_FONTFACE,
  UNIT_CENTIMETER
} from '../../constants';
import { IDBroker, GeometryUtils } from '../../utils/export';
import convert from 'convert-units';
import { verticesDistance } from '../../utils/geometry';
import * as GeomUtils from '../../../demo/src/catalog/utils/geom-utils';
import { loadTexture } from '../../../demo/src/catalog/utils/item-loader';

let transformBox;
export var fVLine = [];
let scene_mode = null;
let snapFlag = false;
let linesFlag = false;

const MOLDING_TYPE_NONE = 'None';
const MOLDING_TYPE_BASE = 'Base';
const MOLDING_TYPE_WALL_TALL = 'Wall/Tall';

export function parseData(sceneData, actions, catalog) {
  let planData = { catalog, sceneData };

  window.planData = planData;

  planData.sceneGraph = {
    unit: sceneData.unit,
    layers: {},
    busyResources: { layers: {} },
    width: sceneData.width,
    height: sceneData.height,
    LODs: {}
  };

  planData.plan = new Three.Object3D();
  planData.plan.name = 'plan';

  // Add a grid to the plan
  planData.grid = createGrid(sceneData);
  planData.grid.name = 'grid';

  planData.boundingBox = new Three.Box3().setFromObject(planData.grid);
  planData.boundingBox.name = 'boundingBox';

  let promises = [];

  sceneData.layers.forEach(layer => {
    if (layer.id === sceneData.selectedLayer || layer.visible) {
      promises = promises.concat(
        createLayerObjects(layer, planData, sceneData, actions, catalog)
      );
    }
  });

  var p1 = new Promise(resolve => {
    Promise.all(promises).then(value => {
      updateBoundingBox(planData);
      // resolve(planData);
      resolve('success');
    });
  });

  return { promise: p1, planData: planData };

  // return planData;
}

export function visibleTransformBox(flag) {
  // if (transformBox instanceof Three.Mesh)
  //   transformBox.visible = flag;
}

function createLayerObjects(layer, planData, sceneData, actions, catalog) {
  let promises = [];

  planData.sceneGraph.layers[layer.id] = {
    id: layer.id,
    lines: {},
    holes: {},
    areas: {},
    items: {},
    countertops: [],
    moldings: [],
    backsplashes: [],

    visible: layer.visible,
    altitude: layer.altitude
  };

  planData.sceneGraph.busyResources.layers[layer.id] = {
    id: layer.id,
    lines: {},
    holes: {},
    areas: {},
    items: {},
    moldings: {}
  };
  // Import lines
  layer.lines.forEach(line => {
    promises.push(
      addLine(
        sceneData,
        planData,
        layer,
        line.id,
        catalog,
        actions.linesActions
      )
    );
    line.holes.forEach(holeID => {
      promises.push(
        addHole(
          sceneData,
          planData,
          layer,
          holeID,
          catalog,
          actions.holesActions
        )
      );
    });
  });

  // Import areas
  layer.areas.forEach(area => {
    promises.push(
      addArea(sceneData, planData, layer, area.id, catalog, actions.areaActions)
    );
  });
  // Import items
  layer.items.forEach(item => {
    promises.push(
      addItem(
        sceneData,
        planData,
        layer,
        item.id,
        catalog,
        actions.itemsActions
      )
    );
  });

  return promises;
}

export function updateScene(
  planData,
  sceneData,
  oldSceneData,
  diffArray,
  actions,
  catalog,
  mode = null
) {
  var result = [];
  if (mode != null) {
    scene_mode = mode;
  }
  let splitted = diffArray.map(el => {
    return { op: el.op, path: el.path.split('/'), value: el.value };
  });
  let filteredDiffs = filterDiffs(splitted, sceneData, oldSceneData);
  //***testing additional filter***
  filteredDiffs = filteredDiffs.filter(({ path }) => path[3] !== 'selected');
  filteredDiffs = filteredDiffs.filter(({ path }) => path[1] !== 'groups');
  //*******************************
  filteredDiffs.forEach(({ op, path, value }) => {
    /* First of all I need to find the object I need to update */
    if (path[1] === 'layers') {
      let layer = sceneData.getIn(['layers', path[2]]);

      if (path.length === 3 && op === 'remove') {
        removeLayer(path[2], planData);
      } else if (path.length > 3) {
        if (path.length > 5)
          if (
            path[5] === 'submodule' ||
            path[5] === 'doorStyle' ||
            path[5] === 'properties'
          )
            op = 'replace';
        switch (op) {
          case 'replace':
            var promiseValue = replaceObject(
              path,
              layer,
              planData,
              actions,
              sceneData,
              oldSceneData,
              catalog,
              value
            );
            if (promiseValue !== undefined) {
              result.push(promiseValue.promise);
            }
            break;
          case 'add':
            addObject(
              path,
              layer,
              planData,
              actions,
              sceneData,
              oldSceneData,
              catalog
            );
            break;
          case 'remove':
            deleteSpecifiedMeshObjects('WarningBox' + path[4]);
            deleteSpecifiedMeshObjects('backsplash' + path[4]);
            let item = oldSceneData
              .getIn(['layers', path[2]])
              .getIn(['items', path[4]]);
            deleteCountertop(
              planData.sceneGraph.layers[layer.id].countertops,
              item,
              planData,
              layer
            );
            deleteMolding(
              planData.sceneGraph.layers[layer.id].moldings,
              item,
              planData,
              layer
            );
            removeObject(
              path,
              layer,
              planData,
              actions,
              sceneData,
              oldSceneData,
              catalog
            );
            break;
        }
      }
    } else if (path[1] === 'selectedLayer') {
      let layerSelectedID = value;
      let layerSelected = sceneData.getIn(['layers', layerSelectedID]);
      // First of all I check if the new selected layer is not visible
      if (!layerSelected.visible) {
        // I need to create the objects for this layer
        let promises = createLayerObjects(
          layerSelected,
          planData,
          sceneData,
          actions,
          catalog
        );
        Promise.all(promises).then(() => updateBoundingBox(planData));
      }

      let layerGraph = planData.sceneGraph.layers[oldSceneData.selectedLayer];

      if (layerGraph) {
        if (!layerGraph.visible) {
          // I need to remove the objects for this layer
          for (let lineID in layerGraph.lines)
            removeLine(planData, layerGraph.id, lineID);
          for (let areaID in layerGraph.areas)
            removeArea(planData, layerGraph.id, areaID);
          for (let itemID in layerGraph.items)
            removeItem(planData, layerGraph.id, itemID);
          for (let holeID in layerGraph.holes)
            removeHole(planData, layerGraph.id, holeID);
        }
      }
    }
  });
  var p1 = new Promise(resolve => {
    Promise.all(result).then(replaceValue => {
      resolve('successPromise');
    });
  });

  return { promise: p1, planData };
}

function replaceObject(
  modifiedPath,
  layer,
  planData,
  actions,
  sceneData,
  oldSceneData,
  catalog,
  value = null
) {
  let promises = [];
  switch (modifiedPath[3]) {
    case 'vertices':
      if (modifiedPath[5] !== 'selected') {
        let vertex = layer.getIn(['vertices', modifiedPath[4]]);

        if (modifiedPath[5] === 'x' || modifiedPath[5] === 'y') {
          vertex.lines.forEach(lineID => {
            let lineHoles = oldSceneData.getIn([
              'layers',
              layer.id,
              'lines',
              lineID,
              'holes'
            ]);
            if (lineHoles)
              lineHoles.forEach(holeID => {
                replaceObject(
                  [0, 0, 0, 'holes', holeID, 'selected'],
                  layer,
                  planData,
                  actions,
                  sceneData,
                  oldSceneData,
                  catalog
                );
              });
            return replaceObject(
              [0, 0, 0, 'lines', lineID],
              layer,
              planData,
              actions,
              sceneData,
              oldSceneData,
              catalog
            );
          });
          vertex.areas.forEach(areaID =>
            replaceObject(
              [0, 0, 0, 'areas', areaID],
              layer,
              planData,
              actions,
              sceneData,
              oldSceneData,
              catalog
            )
          );
        }

        if (modifiedPath[5] === 'areas') {
          let areaID = vertex.getIn(['areas', ~~modifiedPath[6]]);
          replaceObject(
            [0, 0, 0, 'areas', areaID],
            layer,
            planData,
            actions,
            sceneData,
            oldSceneData,
            catalog
          );
        }
      }
      break;
    case 'holes':
      let newHoleData = layer.getIn(['holes', modifiedPath[4]]);

      if (catalog.getElement(newHoleData.type).updateRender3D) {
        promises.push(
          updateHole(
            sceneData,
            oldSceneData,
            planData,
            layer,
            modifiedPath[4],
            modifiedPath.slice(5),
            catalog,
            actions.holesActions,
            () => removeHole(planData, layer.id, newHoleData.id),
            () =>
              addHole(
                sceneData,
                planData,
                layer,
                newHoleData.id,
                catalog,
                actions.holesActions
              )
          )
        );
      } else {
        let lineID = newHoleData.line;
        if (modifiedPath[5] === 'selected') {
          // I remove only the hole without removing the wall
          removeHole(planData, layer.id, newHoleData.id);
          promises.push(
            addHole(
              sceneData,
              planData,
              layer,
              newHoleData.id,
              catalog,
              actions.holesActions
            )
          );
        } else {
          layer.getIn(['lines', lineID, 'holes']).forEach(holeID => {
            removeHole(planData, layer.id, holeID);
          });
          removeLine(planData, layer.id, lineID);
          promises.push(
            addLine(
              sceneData,
              planData,
              layer,
              lineID,
              catalog,
              actions.linesActions
            )
          );
          layer.getIn(['lines', lineID, 'holes']).forEach(holeID => {
            promises.push(
              addHole(
                sceneData,
                planData,
                layer,
                holeID,
                catalog,
                actions.holesActions
              )
            );
          });
        }
      }
      break;
    case 'lines':
      let line = layer.getIn(['lines', modifiedPath[4]]);

      if (catalog.getElement(line.type).updateRender3D) {
        promises.push(
          updateLine(
            sceneData,
            oldSceneData,
            planData,
            layer,
            modifiedPath[4],
            modifiedPath.slice(5),
            catalog,
            actions.linesActions,
            () => removeLine(planData, layer.id, modifiedPath[4]),
            () =>
              addLine(
                sceneData,
                planData,
                layer,
                modifiedPath[4],
                catalog,
                actions.linesActions
              )
          )
        );
      } else {
        removeLine(planData, layer.id, modifiedPath[4]);
        promises.push(
          addLine(
            sceneData,
            planData,
            layer,
            modifiedPath[4],
            catalog,
            actions.linesActions
          )
        );
      }
      break;
    case 'areas':
      let area = layer.getIn(['areas', modifiedPath[4]]);

      if (catalog.getElement(area.type).updateRender3D) {
        promises.push(
          updateArea(
            sceneData,
            oldSceneData,
            planData,
            layer,
            modifiedPath[4],
            modifiedPath.slice(5),
            catalog,
            actions.areaActions,
            () => removeArea(planData, layer.id, modifiedPath[4]),
            () =>
              addArea(
                sceneData,
                planData,
                layer,
                modifiedPath[4],
                catalog,
                actions.areaActions
              )
          )
        );
      } else {
        if (planData.sceneGraph.layers[layer.id].areas[modifiedPath[4]]) {
          removeArea(planData, layer.id, modifiedPath[4]);
        }
        promises.push(
          addArea(
            sceneData,
            planData,
            layer,
            modifiedPath[4],
            catalog,
            actions.areaActions
          )
        );
      }
      break;
    case 'items':
      let item = layer.getIn(['items', modifiedPath[4]]);
      deleteCountertop(
        planData.sceneGraph.layers[layer.id].countertops,
        item,
        planData,
        layer
      );
      deleteMolding(
        planData.sceneGraph.layers[layer.id].moldings,
        item,
        planData,
        layer
      );
      if (catalog.getElement(item.type).updateRender3D) {
        promises.push(
          updateItem(
            sceneData,
            oldSceneData,
            planData,
            layer,
            modifiedPath[4],
            modifiedPath.slice(5),
            catalog,
            actions.itemsActions,
            () => removeItem(planData, layer.id, modifiedPath[4]),
            () =>
              addItem(
                sceneData,
                planData,
                layer,
                modifiedPath[4],
                catalog,
                actions.itemsActions
              )
          )
        );
      } else {
        let item3D = null;
        try {
          item3D = planData.sceneGraph.layers[layer.id].items[modifiedPath[4]];
        } catch (err) {
          console.log('modifiedPath: ', modifiedPath, '\nError: ', err);
          return;
        }

        if (isUndefined(item3D)) return;
        let keyIndex = modifiedPath.length - 1;
        if (modifiedPath[keyIndex] == 'rotation') {
          item3D.rotation.set(0, (value * Math.PI) / 180 + Math.PI, 0);
          setTimeout(() => {
            getDistances(layer);
          }, 50);
        } else if (modifiedPath[keyIndex] == 'x') {
          item3D.position.x = value;
          setTimeout(() => {
            getDistances(layer);
          }, 50);
        } else if (modifiedPath[keyIndex] == 'y') {
          item3D.position.z = -value;
          setTimeout(() => {
            getDistances(layer);
          }, 50);
        } else if (modifiedPath[keyIndex] == 'selected') {
          const itemType = catalog.elements[item.type].type;

          if (value == false) {
            item3D.children[0].children.pop();
          } else {
            if (
              item3D.name !== 'pivot' &&
              item3D.children[0].children[
                item3D.children[0].children.length - 1
              ].name == 'TransformGizmo'
            )
              return;
            if (
              item3D.children[0].children[
                item3D.children[0].children.length - 1
              ].name == 'TransformGizmo'
            ) {
              item3D.children[0].children.pop();
            }
            let object = item3D.children[0].clone();
            item3D.children.pop();
            let altitude = item.properties.get('altitude').get('length');
            let scalevec = new Three.Vector3(
              object.scale.x,
              object.scale.y,
              object.scale.z
            );
            let posVec = new Three.Vector3(
              object.position.x,
              object.position.y,
              object.position.z
            );
            let newAltitude = item.properties.get('altitude').get('_length');
            let newUnit = item.properties.get('altitude').get('_unit') || 'in';
            newAltitude = convert(newAltitude)
              .from(newUnit)
              .to(sceneData.unit);

            let newWidth = item.properties.get('width').get('_length');
            let newWidthUnit =
              item.properties.get('width').get('_unit') || 'in';
            newWidth = convert(newWidth)
              .from(newWidthUnit)
              .to('in');

            let newHeight = item.properties.get('height').get('_length');
            let newHeightUnit =
              item.properties.get('height').get('_unit') || 'in';
            newHeight = convert(newHeight)
              .from(newHeightUnit)
              .to('in');

            let newDepth = item.properties.get('depth').get('_length');
            let newDepthUnit =
              item.properties.get('depth').get('_unit') || 'in';
            newDepth = convert(newDepth)
              .from(newDepthUnit)
              .to('in');
            let sizeinfo = catalog.elements[item.type].info.sizeinfo;
            object.scale.set(
              (1 * newWidth) / sizeinfo.width,
              (1 * newHeight) / sizeinfo.height,
              (1 * newDepth) / sizeinfo.depth
            );
            object.position.set(0, 0, 0);
            object.rotation.set(0, 0, 0);

            let box = GeomUtils.baseBoxHelper(object, 0xffffff);
            box.material.lineWidth = 0.01;
            let boundingBox = GeomUtils.baseBox3FromObject(object);
            let max = boundingBox.max;
            let min = boundingBox.min;
            let radius =
              Math.sqrt(
                (boundingBox.max.x - boundingBox.min.x) *
                  (boundingBox.max.x - boundingBox.min.x) +
                  (boundingBox.max.z - boundingBox.min.z) *
                    (boundingBox.max.z - boundingBox.min.z)
              ) / 2;
            let moveBox = new Three.BoxGeometry(
              max.x - min.x,
              max.y - min.y,
              max.z - min.z
            );

            // translate Object
            let controlGeom = GeomUtils.controlGeom();

            // rotate Object //////////
            let rotGeom = GeomUtils.rotGeoms(radius + 0.05);
            // //////////////////////////////////
            // upwards Geometry///////////
            let upwardsGeom = GeomUtils.upwardsGeom();

            let vLineGeom = new Three.Geometry();
            vLineGeom.vertices.push(
              new Three.Vector3((max.x - min.x) / 2 + min.x, 0, max.z)
            );
            vLineGeom.vertices.push(
              new Three.Vector3((max.x - min.x) / 2 + min.x, 0, max.z + 1.3)
            );

            let vLineGeom1 = new Three.Geometry();
            vLineGeom1.vertices.push(
              new Three.Vector3((max.x - min.x) / 2 + min.x, 0, min.z)
            );
            vLineGeom1.vertices.push(
              new Three.Vector3((max.x - min.x) / 2 + min.x, 0, min.z - 1.3)
            );

            let vLineGeom2 = new Three.Geometry();
            vLineGeom2.vertices.push(
              new Three.Vector3(max.x, 0, max.z - (max.z - min.z) / 2)
            );
            vLineGeom2.vertices.push(
              new Three.Vector3(max.x + 1.3, 0, max.z - (max.z - min.z) / 2)
            );

            let vLineGeom3 = new Three.Geometry();
            vLineGeom3.vertices.push(
              new Three.Vector3(min.x, 0, max.z - (max.z - min.z) / 2)
            );
            vLineGeom3.vertices.push(
              new Three.Vector3(min.x - 1.3, 0, max.z - (max.z - min.z) / 2)
            );
            // ///////////////////////////////////////

            // set names of transform object
            let rotFillObj = new Three.Mesh(
              rotGeom.rotFill,
              new Three.MeshPhongMaterial({
                color: 0x000000,
                side: Three.DoubleSide,
                colorWrite: true
              })
            );
            let rotStrokeObj = new Three.Line(
              rotGeom.rotStroke,
              new Three.LineBasicMaterial({ color: 0xffffff, colorWrite: true })
            );
            rotFillObj.name = 'rotate';
            let upObj = new Three.Mesh(
              upwardsGeom,
              new Three.MeshBasicMaterial({
                color: 0x000000,
                side: Three.DoubleSide
              })
            );
            // let upLine = new Three.Line(upwardsGeom, new Three.LineBasicMaterial({ color: 0x000000 }));
            // upLine.name = "transUp";
            // upObj.add(upLine);
            upObj.name = 'transUp';
            let upwardbox = new Three.BoxHelper(upObj, 0x326780);
            upwardbox.material.lineWidth = 0.01;
            fVLine = [];
            // let obj = new Three.Mesh(controlGeom, new Three.MeshPhongMaterial({ color: 0xffffff, side: Three.DoubleSide, colorWrite: true }));
            // obj.name = "translateX";
            let color = ARROW_COLOR;
            let mBox = new Three.Mesh(
              moveBox,
              new Three.MeshBasicMaterial({
                color: 0xdd6699,
                side: Three.DoubleSide,
                transparent: true,
                opacity: 0.4
              })
            );
            let vLine = new Three.Line(
              vLineGeom,
              new Three.LineBasicMaterial({ color })
            );
            let vLine1 = new Three.Line(
              vLineGeom1,
              new Three.LineBasicMaterial({ color })
            );
            let vLine2 = new Three.Line(
              vLineGeom2,
              new Three.LineBasicMaterial({ color })
            );
            let vLine3 = new Three.Line(
              vLineGeom3,
              new Three.LineBasicMaterial({ color })
            );
            fVLine.push(vLine);
            fVLine.push(vLine1);
            fVLine.push(vLine2);
            fVLine.push(vLine3);
            vLine.renderOrder = 1;
            vLine1.renderOrder = 1;
            vLine2.renderOrder = 1;
            vLine3.renderOrder = 1;
            vLine.material.transparent = true;
            vLine1.material.transparent = true;
            vLine2.material.transparent = true;
            vLine3.material.transparent = true;
            vLine.material.depthTest = false;
            vLine1.material.depthTest = false;
            vLine2.material.depthTest = false;
            vLine3.material.depthTest = false;
            let uVec = new Three.Vector3(
              -posVec.x / scalevec.x,
              -posVec.y / scalevec.y,
              -posVec.z / scalevec.z
            );
            let blLighting = item.type.includes('Light');
            vLine.translateY(blLighting ? 1.6 : 0.1);
            vLine1.translateY(blLighting ? 1.6 : 0.1);
            vLine2.translateY(blLighting ? 1.6 : 0.1);
            vLine3.translateY(blLighting ? 1.6 : 0.1);
            upObj.translateOnAxis(uVec, 1);
            upObj.translateY(max.y - min.y);
            mBox.name = 'TransformBox';
            mBox.translateOnAxis(
              new Three.Vector3(uVec.x, uVec.y + (max.y - min.y) / 2, uVec.z),
              1
            );
            mBox.scale.set(1.01, 1.01, 1.01);
            let rotFillObj1 = rotFillObj.clone();
            let rotStrokeObj1 = rotStrokeObj.clone();
            rotFillObj1.rotateY(Math.PI);
            rotStrokeObj1.rotateY(Math.PI);
            let asrtObj = new Three.Group();
            // let asrtObj1 = new Three.Group();
            // asrtObj.add(obj);
            // asrtObj.add(obj1);
            rotFillObj.translateY(blLighting ? 1.6 : 0.1);
            rotFillObj1.translateY(blLighting ? 1.6 : 0.1);
            rotStrokeObj.translateY(blLighting ? 1.6 : 0.1);
            rotStrokeObj1.translateY(blLighting ? 1.6 : 0.1);
            upObj.translateY(posVec.y / scalevec.y);
            upObj.add(upwardbox);
            //asrtObj.add(upObj);
            // vLine.translateY(posVec.y / scalevec.y);
            // vLine1.translateY(posVec.y / scalevec.y);
            // vLine2.translateY(posVec.y / scalevec.y);
            // vLine3.translateY(posVec.y / scalevec.y);

            mBox.translateY(posVec.y / scalevec.y);
            asrtObj.add(rotFillObj);
            asrtObj.add(rotFillObj1);
            asrtObj.add(rotStrokeObj);
            asrtObj.add(rotStrokeObj1);
            asrtObj.add(vLine);
            asrtObj.add(vLine1);
            asrtObj.add(vLine2);
            asrtObj.add(vLine3);
            asrtObj.add(box);
            asrtObj.scale.set(
              1 / object.scale.x,
              object.scale.y,
              1 / object.scale.z
            );
            mBox.visible = false;
            // transformBox = mBox;
            // asrtObj.add(mBox);
            asrtObj.name = 'TransformGizmo';
            object.add(asrtObj);
            object.position.x = posVec.x;
            object.position.y = posVec.y;
            object.position.z = posVec.z;
            // object.rotation.y = Math.PI;
            object.scale.set(scalevec.x, scalevec.y, scalevec.z);
            item3D.add(object);
            applyInteract(item3D, () => {
              return actions.itemsActions.selectItem(layer.id, modifiedPath[4]);
            });
            setTimeout(() => {
              getDistances(layer);
            }, 100);
          }
        } else if (modifiedPath[keyIndex] == 'length') {
          let object = item3D.children[0];
          let prevPos = object.position;
          object.position.set(prevPos.x, value, prevPos.z);
        } else if (modifiedPath[keyIndex] == '_length') {
          let newAltitude = item.properties.get('altitude').get('_length');
          let newUnit = item.properties.get('altitude').get('_unit') || 'in';
          newAltitude = convert(newAltitude)
            .from(newUnit)
            .to(sceneData.unit);

          let newWidth = item.properties.get('width').get('_length');
          let newWidthUnit = item.properties.get('width').get('_unit') || 'in';
          newWidth = convert(newWidth)
            .from(newWidthUnit)
            .to('in');

          let newHeight = item.properties.get('height').get('_length');
          let newHeightUnit =
            item.properties.get('height').get('_unit') || 'in';
          newHeight = convert(newHeight)
            .from(newHeightUnit)
            .to('in');

          let newDepth = item.properties.get('depth').get('_length');
          let newDepthUnit = item.properties.get('depth').get('_unit') || 'in';
          newDepth = convert(newDepth)
            .from(newDepthUnit)
            .to('in');
          let sizeinfo = catalog.elements[item.type].info.sizeinfo;
          item3D.scale.set(
            (1 * newWidth) / sizeinfo.width,
            (1 * newHeight) / sizeinfo.height,
            (1 * newDepth) / sizeinfo.depth
          );
        } else if (modifiedPath[keyIndex] == 'flip_doorhandle') {
          updateDoorHandleMesh(item, item3D, value);
        } else if (modifiedPath[keyIndex] == 'animValue') {
          return;
        } else if (modifiedPath[keyIndex] == 'animate') {
          let i = 0;
          let index = sceneData.getIn([
            'layers',
            layer.id,
            'items',
            modifiedPath[4],
            'animValue'
          ]);
          let object = item3D.children[0];
          let userData = item3D.children[0].userData.animation;
          let selData = userData[index];
          if (selData !== undefined) {
            for (let j = 0; j < object.children.length; j++) {
              if (selData.placeholder == object.children[j].name) {
                object = object.children[j];
                break;
              }
            }
            object = object.children[0];
            // for (let i = 0; i < userData.path.length; i++) {
            //   for (let j = 0; i < object.children.length; j++) {
            //     if (userData.path[i] == object.children[j].name) {
            //       object = object.children[j];
            //       break;
            //     }
            //   }
            // }
            // object = object.children[0];
            let rotName, rotPoint, pos;
            if (selData.type == 'rotate') {
              rotName = selData.target;
              rotPoint;
              for (let i = 0; i < object.children.length; i++) {
                if (object.children[i].name.indexOf(rotName) != -1) {
                  rotPoint = object.children[i].clone();
                  break;
                }
              }
              pos = rotPoint.position;
            }
            let func;
            if (selData.type == 'rotate') func = myAnimRotate;
            if (selData.type == 'translate') func = myAnimTranslate;
            let renderID = setInterval(func, 30);
            function myAnimRotate() {
              if (i > 50) {
                i = 0;
                clearInterval(renderID);
              }
              if (i < 25) {
                let tx = pos.x;
                let ty = pos.y;
                let offset = new Three.Vector3(tx, ty);
                object.translateOnAxis(offset, -1);
                object.rotateY(-0.1);
                object.translateOnAxis(offset, 1);
              } else {
                let tx = pos.x;
                let ty = pos.y;
                let offset = new Three.Vector3(tx, ty);
                object.translateOnAxis(offset, -1);
                object.rotateY(0.1);
                object.translateOnAxis(offset, 1);
              }
              i++;
            }
            function myAnimTranslate() {
              if (i > 50) {
                i = 0;
                clearInterval(renderID);
              }
              if (i < 25) {
                if (selData.target == 'Y') {
                  object.translateY(-0.01);
                }
                if (selData.target == 'X') object.translateX(-0.01);
                if (selData.target == 'Z') object.translateZ(-0.01);
              } else {
                if (selData.target == 'Y') {
                  object.translateY(0.01);
                }
                if (selData.target == 'X') object.translateX(0.01);
                if (selData.target == 'Z') object.translateZ(0.01);
              }
              i++;
            }
          } else {
            console.log('selData is undefined in viewer3d/scene-creator.js');
          }
        } else if (["cabinet_door_style_id", "counterTop", "drawer_door_handle_1_gltf"].includes(modifiedPath[keyIndex])) {
          let rItem =
            planData.sceneGraph.layers[layer.id].items[modifiedPath[4]];
          if (rItem != null) {
            removeItemWithoutItem(planData, layer.id, modifiedPath[4]);
            promises.push(
              addItem(
                sceneData,
                planData,
                layer,
                modifiedPath[4],
                catalog,
                actions.itemsActions,
                rItem
              )
            );
          }
          break;
        }
      }

      item = layer.getIn(['items', modifiedPath[4]]);
      addCountertop(
        planData.sceneGraph.layers[layer.id].countertops,
        item,
        planData,
        layer
      );
      addMolding(
        planData.sceneGraph.layers[layer.id].moldings,
        item,
        planData,
        layer
      );
      break;

    case 'visible':
      if (!layer.visible) {
        let layerGraph = planData.sceneGraph.layers[layer.id];

        for (let lineID in layerGraph.lines)
          removeLine(planData, layer.id, lineID);
        for (let areaID in layerGraph.areas)
          removeArea(planData, layer.id, areaID);
        for (let itemID in layerGraph.items)
          removeItem(planData, layer.id, itemID);
        for (let holeID in layerGraph.holes)
          removeHole(planData, layer.id, holeID);
      } else {
        promises = promises.concat(
          createLayerObjects(layer, planData, sceneData, actions, catalog)
        );
      }

      break;

    case 'unit':
    case 'ceilHeight':
    case 'opacity':
    case 'altitude':
    case 'molding':
      let layerGraph = planData.sceneGraph.layers[layer.id];
      for (let lineID in layerGraph.lines)
        removeLine(planData, layer.id, lineID);
      for (let areaID in layerGraph.areas)
        removeArea(planData, layer.id, areaID);
      for (let itemID in layerGraph.items)
        removeItem(planData, layer.id, itemID);
      for (let holeID in layerGraph.holes)
        removeHole(planData, layer.id, holeID);

      promises = promises.concat(
        createLayerObjects(layer, planData, sceneData, actions, catalog)
      );
  }
  var p1 = new Promise(resolve => {
    Promise.all(promises).then(values => {
      updateBoundingBox(planData, true);
      resolve('replaceObject');
    });
  });
  return { promise: p1 };
}

export function getDistances(layer, isCalcWall) {
  fVLine.forEach((line, index) => {
    getLineDistance(
      line,
      layer,
      isCalcWall === undefined ? false : isCalcWall,
      index
    );
  });
}

function getLineDistance(obj, layer, isCalcWall, index) {
  if (obj === undefined) return;
  let wPoint0 = obj.geometry.vertices[0].clone().applyMatrix4(obj.matrixWorld);
  let wPoint1 = obj.geometry.vertices[1].clone().applyMatrix4(obj.matrixWorld);
  let raycaster = new Three.Raycaster(
    wPoint0,
    new Three.Vector3(
      wPoint1.x - wPoint0.x,
      wPoint1.y - wPoint0.y,
      wPoint1.z - wPoint0.z
    )
  );
  let rayDirection = raycaster.ray.direction;
  rayDirection.normalize();
  let intersects = raycaster.intersectObjects([planData.plan], true);

  let lx = wPoint0.x - wPoint1.x;
  let ly = wPoint0.y - wPoint1.y;
  let lz = wPoint0.z - wPoint1.z;
  let length = Math.sqrt(lx * lx + ly * ly + lz * lz);
  let scale = 1;

  var extrudeSettings = {
    steps: 2,
    depth: 0.01,
    bevelEnabled: false
  };
  var w = 0.2;
  var h = w * (Math.sqrt(3) / 2);
  var shape = new Three.Shape();
  shape.moveTo(0, 0);
  shape.lineTo(-w / 4, h / 2);
  shape.lineTo(w / 4, h / 2);
  var geom = new Three.ExtrudeBufferGeometry(shape, extrudeSettings);
  geom.center();

  if (!isCalcWall) {
    for (let i = 0; i < intersects.length; i++) {
      if (
        intersects[i].object.name != 'TransformBox' &&
        !intersects[i].object.name.includes('WarningBox') &&
        intersects[i].object.type != 'Line' &&
        intersects[i].object.name != 'rotate' &&
        intersects[i].object.name != 'lineText' &&
        intersects[i].object.type != 'BoxHelper' &&
        !intersects[i].object.name.includes('molding') &&
        intersects[i].object.name != "soul"
      ) {
        scale = intersects[i].distance / length;
        if (intersects[i].distance <= 0.1) {
          scale = 0.1 / length;
        }
        obj.userData.distance = intersects[i].distance;
        obj.userData.target = intersects[i].object;
        let originPoint = obj.geometry.vertices[0];
        lx = obj.geometry.vertices[1].x - obj.geometry.vertices[0].x;
        ly = obj.geometry.vertices[1].y - obj.geometry.vertices[0].y;
        lz = obj.geometry.vertices[1].z - obj.geometry.vertices[0].z;
        let newVec = new Three.Vector3(
          originPoint.x + lx * scale,
          originPoint.y + ly * scale,
          originPoint.z + lz * scale
        );
        obj.geometry.vertices[1].x = newVec.x;
        obj.geometry.vertices[1].y = newVec.y;
        obj.geometry.vertices[1].z = newVec.z;
        obj.geometry.verticesNeedUpdate = true;
        let dist = convert(intersects[i].distance)
          .from('cm')
          .to('in')
          .toFixed(2);
        if (dist > 3) {
          let canvas = getTextCanvas(dist);
          let wid = (canvas.width / window.innerWidth) * 30;
          let hei = (canvas.height / window.innerHeight) * 30;
          let texture = new Three.Texture(canvas);
          texture.minFilter = Three.LinearFilter;
          texture.needsUpdate = true;
          let geometry = new Three.PlaneGeometry(wid / 5, hei / 5);
          geometry.computeBoundingBox();
          const material = new Three.MeshBasicMaterial({
            map: texture,
            side: Three.DoubleSide
          });
          let textMesh = new Three.Mesh(geometry, material);
          for (; obj.children.length != 0; ) {
            let temp = obj.children.pop();
            disposeObject(temp);
          }
          textMesh.rotation.set(Math.PI / 2, Math.PI, 0);
          // obj.add(textMesh);
          textMesh.position.set(
            (obj.geometry.vertices[0].x + obj.geometry.vertices[1].x) / 2,
            0.01,
            (obj.geometry.vertices[0].z + obj.geometry.vertices[1].z) / 2
          );
          textMesh.name = 'lineText';
          textMesh.renderOrder = 2;
          textMesh.material.depthTest = false;
          textMesh.material.transparent = true;

          let sprite1 = new Three.Sprite(
            new Three.SpriteMaterial({ map: texture })
          );
          sprite1.position.set(
            (obj.geometry.vertices[0].x + obj.geometry.vertices[1].x) / 2,
            0.01,
            (obj.geometry.vertices[0].z + obj.geometry.vertices[1].z) / 2
          );
          sprite1.name = 'lineText';
          sprite1.scale.set(0.3, 0.3, 0.3);
          sprite1.layers.set(1);
          obj.add(sprite1);

          if (obj.parent != null) {
            // is not lighting
            let item3D = obj.parent.parent.parent;
            let max = item3D.children[0].userData.max;
            let min = item3D.children[0].userData.min;
            let objW = (max.x - min.x) / 100,
              objL = (max.z - min.z) / 100;
            var triangle = new Three.Mesh(
              geom,
              new Three.MeshBasicMaterial({ color: ARROW_COLOR })
            );
            var triangle1 = new Three.Mesh(
              geom,
              new Three.MeshBasicMaterial({ color: ARROW_COLOR })
            );
            triangle.position.set(
              (index < 2
                ? 0
                : Math.sin(((index === 2 ? 1 : -1) * Math.PI) / 2)) *
                (obj.geometry.vertices[0].x +
                  h / 4 +
                  (index % 2 === 0 ? 0 : objW)),
              newVec.y,
              (index < 2 ? Math.cos(index * Math.PI) : 0) *
                (obj.geometry.vertices[0].z +
                  h / 4 +
                  (index % 2 === 0 ? 0 : objL)) -
                0.02
            );
            triangle1.position.set(
              newVec.x -
                ((index < 2
                  ? 0
                  : Math.sin(((index === 2 ? 1 : -1) * Math.PI) / 2)) *
                  h) /
                  4,
              newVec.y,
              newVec.z - ((index < 2 ? Math.cos(index * Math.PI) : 0) * h) / 4
            );
            if (index < 2) {
              triangle.rotation.x = (Math.cos(index * Math.PI) * Math.PI) / 2;
              triangle1.rotation.x = (-Math.cos(index * Math.PI) * Math.PI) / 2;
            } else {
              triangle.rotation.x = -Math.PI / 2;
              triangle.rotation.z = (-(index === 2 ? 1 : -1) * Math.PI) / 2;
              triangle1.rotation.x = Math.PI / 2;
              triangle1.rotation.z = ((index === 2 ? 1 : -1) * Math.PI) / 2;
            }
            triangle.name = 'lineText';
            triangle1.name = 'lineText';
            triangle.renderOrder = 1;
            triangle1.renderOrder = 1;
            triangle.material.transparent = true;
            triangle1.material.transparent = true;
            triangle.material.depthTest = false;
            triangle1.material.depthTest = false;
            obj.add(triangle);
            obj.add(triangle1);
          }
        }

        // if (obj.userData.distance <= 50 && obj.userData.distance >= 0.5) {
        let real_target = obj.userData.target;
        for (; real_target.name != 'pivot'; ) {
          if (real_target.parent == null) break;
          real_target = real_target.parent;
        }
        // let item3D = obj.parent.parent.parent;
        // let origin = obj.geometry.vertices[0].clone().applyMatrix4(obj.matrixWorld);
        // let target = obj.geometry.vertices[1].clone().applyMatrix4(obj.matrixWorld);
        // let uVec = new Three.Vector3(target.x - origin.x - 0.2, target.y - origin.y - 0.2, target.z - origin.z - 0.2);
        obj.visible = true;
        if (dist < 3) obj.visible = false;
        return obj;
      }
    }
  }
  let tX = wPoint0.x - planData.plan.position.x;
  let tZ = wPoint0.z - planData.plan.position.z;
  tZ = -tZ;
  let tX1 = wPoint1.x - planData.plan.position.x;
  let tZ1 = wPoint1.z - planData.plan.position.z;
  tZ1 = -tZ1;
  if (!layer) return;
  layer.lines.forEach(function(data) {
    let px = [];
    let py = [];
    let opX = [tX, tX1];
    let opY = [tZ, tZ1];
    data.vertices.forEach(vec => {
      let vertices = layer.vertices.get(vec);
      px.push(vertices.x);
      py.push(vertices.y);
    });
    let distance = getIntersectPoint(opX, opY, px, py);
    if (distance != null) {
      obj.userData.distance = distance;
      obj.userData.target =
        planData.sceneGraph.layers[layer.id].lines[data.get('id')];
      scale = distance / length;
      if (distance <= 0.1) {
        scale = 0.1 / length;
      }
      let originPoint = obj.geometry.vertices[0];
      lx = obj.geometry.vertices[1].x - obj.geometry.vertices[0].x;
      ly = obj.geometry.vertices[1].y - obj.geometry.vertices[0].y;
      lz = obj.geometry.vertices[1].z - obj.geometry.vertices[0].z;
      let newVec = new Three.Vector3(
        originPoint.x + lx * scale,
        originPoint.y + ly * scale,
        originPoint.z + lz * scale
      );
      obj.geometry.vertices[1].x = newVec.x;
      obj.geometry.vertices[1].y = newVec.y;
      obj.geometry.vertices[1].z = newVec.z;
      obj.geometry.verticesNeedUpdate = true;
      let dist = distance.toFixed(2);
      if (dist > 3) {
        let canvas = getTextCanvas(dist);
        let wid = (canvas.width / window.innerWidth) * 30;
        let hei = (canvas.height / window.innerHeight) * 30;
        let texture = new Three.Texture(canvas);
        texture.needsUpdate = true;
        let geometry = new Three.PlaneGeometry(wid / 2, hei / 2);
        geometry.computeBoundingBox();
        const material = new Three.MeshBasicMaterial({
          map: texture,
          side: Three.DoubleSide
        });
        let textMesh = new Three.Mesh(geometry, material);
        for (; obj.children.length != 0; ) {
          let temp = obj.children.pop();
          disposeObject(temp);
        }
        textMesh.rotation.set(Math.PI / 2, Math.PI, 0);
        // obj.add(textMesh);
        textMesh.position.set(
          (obj.geometry.vertices[0].x + obj.geometry.vertices[1].x) / 2,
          0.01,
          (obj.geometry.vertices[0].z + obj.geometry.vertices[1].z) / 2
        );
        textMesh.name = 'lineText';
        textMesh.renderOrder = 2;
        textMesh.material.depthTest = false;
        textMesh.material.transparent = true;

        let sprite1 = new Three.Sprite(
          new Three.SpriteMaterial({ map: texture })
        );
        sprite1.position.set(
          (obj.geometry.vertices[0].x + obj.geometry.vertices[1].x) / 2,
          0.01,
          (obj.geometry.vertices[0].z + obj.geometry.vertices[1].z) / 2
        );
        sprite1.name = 'lineText';
        sprite1.scale.set(0.3, 0.3, 0.3);
        sprite1.layers.set(1);
        obj.add(sprite1);

        let item3D = obj.parent.parent.parent;
        let max = item3D.children[0].userData.max;
        let min = item3D.children[0].userData.min;
        let objW = (max.x - min.x) / 100,
          objL = (max.z - min.z) / 100;
        var triangle = new Three.Mesh(
          geom,
          new Three.MeshBasicMaterial({ color: ARROW_COLOR })
        );
        var triangle1 = new Three.Mesh(
          geom,
          new Three.MeshBasicMaterial({ color: ARROW_COLOR })
        );
        triangle.position.set(
          (index < 2 ? 0 : Math.sin(((index === 2 ? 1 : -1) * Math.PI) / 2)) *
            (obj.geometry.vertices[0].x + h / 4 + (index % 2 === 0 ? 0 : objW)),
          newVec.y,
          (index < 2 ? Math.cos(index * Math.PI) : 0) *
            (obj.geometry.vertices[0].z +
              h / 4 +
              (index % 2 === 0 ? 0 : objL)) -
            0.02
        );
        triangle1.position.set(
          newVec.x -
            ((index < 2
              ? 0
              : Math.sin(((index === 2 ? 1 : -1) * Math.PI) / 2)) *
              h) /
              4,
          newVec.y,
          newVec.z - ((index < 2 ? Math.cos(index * Math.PI) : 0) * h) / 4
        );
        if (index < 2) {
          triangle.rotation.x = (Math.cos(index * Math.PI) * Math.PI) / 2;
          triangle1.rotation.x = (-Math.cos(index * Math.PI) * Math.PI) / 2;
        } else {
          triangle.rotation.x = -Math.PI / 2;
          triangle.rotation.z = (-(index === 2 ? 1 : -1) * Math.PI) / 2;
          triangle1.rotation.x = Math.PI / 2;
          triangle1.rotation.z = ((index === 2 ? 1 : -1) * Math.PI) / 2;
        }
        triangle.name = 'lineText';
        triangle1.name = 'lineText';
        triangle.renderOrder = 1;
        triangle1.renderOrder = 1;
        triangle.material.transparent = true;
        triangle1.material.transparent = true;
        triangle.material.depthTest = false;
        triangle1.material.depthTest = false;
        obj.add(triangle);
        obj.add(triangle1);
      }
      // if (obj.userData.distance <= 50 && obj.userData.distance >= 0.5) {
      // let item3D = obj.parent.parent.parent;
      // let pos = item3D.position.clone();
      // let origin = obj.geometry.vertices[0].clone().applyMatrix4(obj.matrixWorld);
      // let target = obj.geometry.vertices[1].clone().applyMatrix4(obj.matrixWorld);
      // let uVec = new Three.Vector3(target.x - origin.x - 0.2, target.y - origin.y - 0.2, target.z - origin.z - 0.2);
      obj.visible = true;
      if (dist > 3) obj.visible = false;
      return obj;
      // itemsActions.updateDraggingItemChanged(pos.x - uVec.x, -pos.z + uVec.z);
      // }
    }
  });
  obj.visible = false;
  return obj;
}

export function getIntersectPoint(opX, opY, pX, pY) {
  let oA;
  if (opX[1] == opX[0]) oA = null;
  else oA = (opY[1] - opY[0]) / (opX[1] - opX[0]);
  let A;
  if (pX[1] == pX[0]) A = null;
  else A = (pY[1] - pY[0]) / (pX[1] - pX[0]);
  if (oA == A) return null;
  if (oA != null && A != null) {
    let oB = opY[0] - oA * opX[0];
    let B = pY[0] - A * pX[0];
    let iX = (B - oB) / (oA - A);
    if ((pX[0] <= iX && iX <= pX[1]) || (pX[0] >= iX && iX >= pX[1])) {
      if (
        (opX[0] <= opX[1] && opX[0] <= iX) ||
        (opX[0] >= opX[1] && opX[0] >= iX)
      ) {
        let iY = pY[0] + A * (iX - pX[0]);
        let distance = Math.sqrt(
          (opX[0] - iX) * (opX[0] - iX) + (opY[0] - iY) * (opY[0] - iY)
        );
        return distance;
      }
      return null;
    }
  }
  if (oA == null) {
    let iX = opX[0];
    if (A != null) {
      if ((pX[0] <= iX && iX <= pX[1]) || (pX[0] >= iX && iX >= pX[1])) {
        let iY = pY[0] + A * (iX - pX[0]);
        if (
          (opY[0] <= iY && opY[0] <= opY[1]) ||
          (opY[0] >= iY && opY[0] >= opY[1])
        ) {
          let distance = Math.sqrt(
            (opX[0] - iX) * (opX[0] - iX) + (opY[0] - iY) * (opY[0] - iY)
          );
          return distance;
        }
      }
    }
  }
  if (A == null) {
    let iX = pX[0];
    if (oA != null) {
      let iY = iX * oA + opY[0] - oA * opX[0];
      if ((pY[0] <= iY && iY <= pY[1]) || (pY[0] >= iY && iY >= pY[1])) {
        if (
          (opY[0] <= iY && opY[0] <= opY[1]) ||
          (opY[0] >= iY && opY[0] >= opY[1])
        ) {
          let distance = Math.sqrt(
            (opX[0] - iX) * (opX[0] - iX) + (opY[0] - iY) * (opY[0] - iY)
          );
          return distance;
        }
      }
    }
  }
  return null;
}

function gcd(a, b) {
  return a % b ? gcd(b, a % b) : b;
}
function getTextCanvas(text, parameters = {}) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  let fontSize = 16;
  let integral = String(text) + "''";

  parameters.fontName = parameters.fontName || ARROW_TEXT_FONTFACE;

  // Prepare the font to be able to measure
  ctx.font = `${fontSize}px ` + parameters.fontName;
  if (parameters.italic) {
    ctx.font = `italic ${fontSize}px ` + parameters.fontName;
  }
  if (parameters.bold) {
    ctx.font = `bold ${fontSize}px ` + parameters.fontName;
  }
  if (parameters.bold && parameters.italic) {
    ctx.font = `italic bold ${fontSize}px ` + parameters.fontName;
  }
  const textMetrics = ctx.measureText(integral);

  let width = 70;
  let height = fontSize + 20;

  // Resize canvas to match text size
  canvas.width = width;
  canvas.height = height;
  canvas.style.width = width + 'px';
  canvas.style.height = height + 'px';

  // Re-apply font since canvas is resized.
  ctx.font = `${fontSize}px ` + parameters.fontName;
  if (parameters.italic) {
    ctx.font = `italic ${fontSize}px ` + parameters.fontName;
  }
  if (parameters.bold) {
    ctx.font = `bold ${fontSize}px ` + parameters.fontName;
  }
  if (parameters.bold && parameters.italic) {
    ctx.font = `italic bold ${fontSize}px ` + parameters.fontName;
  }
  //ctx.textAlign = parameters.align || "center";
  ctx.textBaseline = parameters.baseline || 'middle';

  // Make the canvas transparent for simplicity
  ctx.fillStyle = ARROW_TEXT_BACKCOLOR;
  ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

  ctx.fillStyle = parameters.fillColor || ARROW_TEXT_FORECOLOR;
  ctx.fillText(integral, (width - textMetrics.width) / 2, height / 2);
  ctx.strokeStyle = parameters.strokeColor || ARROW_TEXT_FORECOLOR;
  ctx.strokeText(integral, (width - textMetrics.width) / 2, height / 2);

  // ctx.font = `${fontSize - 5}px ` + parameters.fontName;
  // const denoMetrics = ctx.measureText(deno);
  // const numoMetrics = ctx.measureText(numo);
  // ctx.fillStyle = ARROW_TEXT_FORECOLOR;
  // ctx.fillRect(
  //   ctx.canvas.width / 2 + 3,
  //   ctx.canvas.height / 2 - 2,
  //   denoMetrics.width,
  //   1
  // );
  
  // const denoMetrics = ctx.measureText(deno);
  // ctx.fillStyle = ARROW_TEXT_FORECOLOR;
  // ctx.fillRect(
  //   ctx.canvas.width / 2 + 3,
  //   ctx.canvas.height / 2 - 2,
  //   denoMetrics.width,
  //   1
  // );

  // ctx.fillStyle = parameters.fillColor || ARROW_TEXT_FORECOLOR;
  // ctx.fillText(
  //   numo,
  //   ctx.canvas.width / 2 + 3 + (denoMetrics.width - numoMetrics.width) / 2,
  //   height / 2 - 8
  // );
  // ctx.font = `${fontSize}px ` + parameters.fontName;
  // ctx.fillText(
  //   "''",
  //   ctx.canvas.width / 2 + 4 + denoMetrics.width,
  //   height / 2 - 7
  // );
  // ctx.font = `${fontSize - 5}px ` + parameters.fontName;

  // ctx.strokeStyle = parameters.strokeColor || ARROW_TEXT_FORECOLOR;
  // ctx.strokeText(
  //   numo,
  //   ctx.canvas.width / 2 + 3 + (denoMetrics.width - numoMetrics.width) / 2,
  //   height / 2 - 8
  // );

  // ctx.fillStyle = parameters.fillColor || ARROW_TEXT_FORECOLOR;
  // ctx.fillText(deno, ctx.canvas.width / 2 + 3, height / 2 + 8);
  // ctx.strokeStyle = parameters.strokeColor || ARROW_TEXT_FORECOLOR;
  // ctx.strokeText(deno, ctx.canvas.width / 2 + 3, height / 2 + 8);
  return canvas;
}

function removeObject(
  modifiedPath,
  layer,
  planData,
  actions,
  sceneData,
  oldSceneData,
  catalog
) {
  let promises = [];
  switch (modifiedPath[3]) {
    case 'lines':
      // Here I remove the line with all its holes
      let lineID = modifiedPath[4];
      oldSceneData
        .getIn(['layers', layer.id, 'lines', lineID, 'holes'])
        .forEach(holeID => {
          removeHole(planData, layer.id, holeID);
        });
      removeLine(planData, layer.id, lineID);
      if (modifiedPath.length > 5) {
        // I removed an hole, so I should add the new line
        promises.push(
          addLine(
            sceneData,
            planData,
            layer,
            lineID,
            catalog,
            actions.linesActions
          )
        );
        layer.getIn(['lines', lineID, 'holes']).forEach(holeID => {
          promises.push(
            addHole(
              sceneData,
              planData,
              layer,
              holeID,
              catalog,
              actions.holesActions
            )
          );
        });
      }
      break;
    case 'areas':
      if (modifiedPath.length === 5) {
        // I am removing an entire area
        removeArea(planData, layer.id, modifiedPath[4]);
      }
      break;
    case 'items':
      if (modifiedPath.length === 5) {
        // I am removing an item
        removeItem(planData, layer.id, modifiedPath[4]);
      }
      break;
  }

  Promise.all(promises).then(values => updateBoundingBox(planData));
}

function removeLayer(layerId, planData) {
  let layerGraph = planData.sceneGraph.layers[layerId];

  for (let lineID in layerGraph.lines) removeLine(planData, layerId, lineID);
  for (let areaID in layerGraph.areas) removeArea(planData, layerId, areaID);
  for (let itemID in layerGraph.items) removeItem(planData, layerId, itemID);
  for (let holeID in layerGraph.holes) removeHole(planData, layerId, holeID);

  delete planData.sceneGraph.layers[layerId];
}

function removeHole(planData, layerId, holeID) {
  if (planData.sceneGraph.busyResources.layers[layerId].holes[holeID]) {
    setTimeout(() => removeHole(planData, layerId, holeID), 100);
    return;
  }

  planData.sceneGraph.busyResources.layers[layerId].holes[holeID] = true;

  let hole3D = planData.sceneGraph.layers[layerId].holes[holeID];

  if (hole3D) {
    planData.plan.remove(hole3D);
    disposeObject(hole3D);
    delete planData.sceneGraph.layers[layerId].holes[holeID];
    delete planData.sceneGraph.LODs[holeID];
    hole3D = null;
    updateBoundingBox(planData);
  }

  planData.sceneGraph.busyResources.layers[layerId].holes[holeID] = false;
}

function removeLine(planData, layerId, lineID) {
  if (planData.sceneGraph.busyResources.layers[layerId].lines[lineID]) {
    setTimeout(() => removeLine(planData, layerId, lineID), 100);
    return;
  }

  planData.sceneGraph.busyResources.layers[layerId].lines[lineID] = true;

  let line3D = planData.sceneGraph.layers[layerId].lines[lineID];

  if (line3D) {
    planData.plan.remove(line3D);
    disposeObject(line3D);
    delete planData.sceneGraph.layers[layerId].lines[lineID];
    delete planData.sceneGraph.LODs[lineID];
    line3D = null;
    updateBoundingBox(planData);
  }

  planData.sceneGraph.busyResources.layers[layerId].lines[lineID] = false;
}

function removeArea(planData, layerId, areaID) {
  if (planData.sceneGraph.busyResources.layers[layerId].areas[areaID]) {
    setTimeout(() => removeArea(planData, layerId, areaID), 100);
    return;
  }

  planData.sceneGraph.busyResources.layers[layerId].areas[areaID] = true;

  let area3D = planData.sceneGraph.layers[layerId].areas[areaID];

  if (area3D) {
    planData.plan.remove(area3D);
    disposeObject(area3D);
    delete planData.sceneGraph.layers[layerId].areas[areaID];
    delete planData.sceneGraph.LODs[areaID];
    area3D = null;
    updateBoundingBox(planData);
  }

  planData.sceneGraph.busyResources.layers[layerId].areas[areaID] = false;
}

export function removeItem(planData, layerId, itemID) {
  if (planData.sceneGraph.busyResources.layers[layerId].items[itemID]) {
    setTimeout(() => removeItem(planData, layerId, itemID), 100);
    return;
  }

  planData.sceneGraph.busyResources.layers[layerId].items[itemID] = true;

  let layer = planData.sceneData.getIn(['layers', layerId]);
  let item = layer.getIn(['items', itemID]);
  deleteCountertop(
    planData.sceneGraph.layers[layerId].countertops,
    item,
    planData,
    layer
  );
  deleteMolding(
    planData.sceneGraph.layers[layerId].moldings,
    item,
    planData,
    layer
  );
  let item3D = planData.sceneGraph.layers[layerId].items[itemID];

  if (item3D) {
    planData.plan.remove(item3D);
    disposeObject(item3D);
    delete planData.sceneGraph.layers[layerId].items[itemID];
    delete planData.sceneGraph.LODs[itemID];
    item3D = null;
    updateBoundingBox(planData);
  }

  planData.sceneGraph.busyResources.layers[layerId].items[itemID] = false;
}

function removeItemWithoutItem(planData, layerId, itemID) {
  if (planData.sceneGraph.busyResources.layers[layerId].items[itemID]) {
    setTimeout(() => removeItem(planData, layerId, itemID), 100);
    return;
  }

  planData.sceneGraph.busyResources.layers[layerId].items[itemID] = true;

  let layer = planData.sceneData.getIn(['layers', layerId]);
  let item = layer.getIn(['items', itemID]);
  deleteCountertop(
    planData.sceneGraph.layers[layerId].countertops,
    item,
    planData,
    layer
  );
  deleteMolding(
    planData.sceneGraph.layers[layerId].moldings,
    item,
    planData,
    layer
  );
  let item3D = planData.sceneGraph.layers[layerId].items[itemID];

  if (item3D) {
    // planData.plan.remove(item3D);
    // disposeObject(item3D);
    // item3D = null;
    // updateBoundingBox(planData);
    delete planData.sceneGraph.layers[layerId].items[itemID];
    delete planData.sceneGraph.LODs[itemID];
  }

  planData.sceneGraph.busyResources.layers[layerId].items[itemID] = false;
}

//TODO generate an area's replace if vertex has been changed
function addObject(
  modifiedPath,
  layer,
  planData,
  actions,
  sceneData,
  oldSceneData,
  catalog
) {
  if (modifiedPath.length >= 5) {
    let addPromise = null,
      addAction = null;
    switch (modifiedPath[3]) {
      case 'lines':
        if (modifiedPath[5] === "holes") {
          removeLine(planData, layer.id, modifiedPath[4]);
        }
        addPromise = addLine;
        addAction = actions.linesActions;
        break;
      case 'areas':
        addPromise = addArea;
        addAction = actions.areaActions;
        break;
      case 'items':
        addPromise = addItem;
        addAction = actions.itemsActions;
        break;
      case 'holes':
        addPromise = addHole;
        addAction = actions.holesActions;
        break;
    }
    if (addPromise) {
      // if( addPromise(sceneData, planData, layer, modifiedPath[4], catalog, addAction) === undefined ) {
      //   return;
      // }
      addPromise(
        sceneData,
        planData,
        layer,
        modifiedPath[4],
        catalog,
        addAction
      ).then(() => updateBoundingBox(planData));
    }
  }
}

function addHole(sceneData, planData, layer, holeID, catalog, holesActions) {
  let holeData = layer.getIn(['holes', holeID]);

  // Create the hole object
  return catalog
    .getElement(holeData.type)
    .render3D(holeData, layer, sceneData)
    .then(object => {
      if (object instanceof Three.LOD) {
        planData.sceneGraph.LODs[holeID] = object;
      }

      object.children.forEach(item => {
        const { name } = item;

        let texture;
        if (name.includes('_wood')) {
          texture = loadTexture('/assets/img/texture/white1px.jpg');
        } else if (name.includes('_glass')) {
          const material = new Three.MeshPhysicalMaterial({
            roughness: 0.5,
            transmission: 1,
            thickness: 0.5, // Add refraction!
            transparency: 0.6
          });
          item.material = material;
          return item;
        } else if (name.includes('_steel')) {
          texture = loadTexture('/assets/img/texture/steel.jpg');
        } else if (name.includes('_glass')) {
          const material = new Three.MeshPhysicalMaterial({
            roughness: 0.5,
            transmission: 1,
            thickness: 0.5, // Add refraction!
            transparency: 0.6
          });
          item.material = material;
          return item;
        }
      });

      let pivot = new Three.Object3D();
      pivot.name = 'pivot';
      pivot.add(object);

      let line = layer.getIn(['lines', holeData.line]);

      // First of all I need to find the vertices of this line
      let vertex0 = layer.vertices.get(line.vertices.get(0));
      let vertex1 = layer.vertices.get(line.vertices.get(1));
      let offset = holeData.offset;

      if (vertex0.x > vertex1.x) {
        let tmp = vertex0;
        vertex0 = vertex1;
        vertex1 = tmp;
      }

      let distance = Math.sqrt(
        Math.pow(vertex0.x - vertex1.x, 2) + Math.pow(vertex0.y - vertex1.y, 2)
      );
      let alpha = Math.asin((vertex1.y - vertex0.y) / distance);

      let boundingBox = new Three.Box3().setFromObject(pivot);
      let center = [
        (boundingBox.max.x - boundingBox.min.x) / 2 + boundingBox.min.x,
        (boundingBox.max.y - boundingBox.min.y) / 2 + boundingBox.min.y,
        (boundingBox.max.z - boundingBox.min.z) / 2 + boundingBox.min.z
      ];

      let holeAltitude = holeData.properties.getIn(['altitude', 'length']);
      let holeHeight = holeData.properties.getIn(['height', 'length']);

      pivot.rotation.y = alpha;
      pivot.position.x =
        vertex0.x +
        distance * offset * Math.cos(alpha) -
        center[0] * Math.cos(alpha) -
        center[2] * Math.sin(alpha);
      pivot.position.y =
        holeAltitude + holeHeight / 2 - center[1] + layer.altitude;
      pivot.position.z =
        -vertex0.y -
        distance * offset * Math.sin(alpha) -
        center[2] * Math.cos(alpha) +
        center[0] * Math.sin(alpha);
      pivot.userData.type = 'hole';
      pivot.userData.layerId = layer.id;
      pivot.userData.holeId = holeData.id;
      planData.plan.add(pivot);
      planData.sceneGraph.layers[layer.id].holes[holeData.id] = pivot;

      applyInteract(pivot, () => {
        return holesActions.selectHole(layer.id, holeData.id);
      });

      let opacity = layer.opacity;
      if (holeData.selected) {
        opacity = 1;
      }
      applyOpacity(pivot, opacity);
    });
}

function updateHole(
  sceneData,
  oldSceneData,
  planData,
  layer,
  holeID,
  differences,
  catalog,
  holesActions,
  selfDestroy,
  selfBuild
) {
  let hole = layer.getIn(['holes', holeID]);
  let oldHole = oldSceneData.getIn(['layers', layer.id, 'holes', holeID]);
  let mesh = planData.sceneGraph.layers[layer.id].holes[holeID];

  if (!mesh) return null;

  return catalog
    .getElement(hole.type)
    .updateRender3D(
      hole,
      layer,
      sceneData,
      mesh,
      oldHole,
      differences,
      selfDestroy,
      selfBuild
    );
}

function addLine(sceneData, planData, layer, lineID, catalog, linesActions) {
  if (planData.sceneGraph.busyResources.layers[layer.id].lines[lineID]) {
    setTimeout(
      () => addLine(sceneData, planData, layer, lineID, catalog, linesActions),
      100
    );
    return;
  }

  planData.sceneGraph.busyResources.layers[layer.id].lines[lineID] = true;

  let line = layer.getIn(['lines', lineID]);

  // First of all I need to find the vertices of this line
  let vertex0 = layer.vertices.get(line.vertices.get(0));
  let vertex1 = layer.vertices.get(line.vertices.get(1));

  if (vertex0.x > vertex1.x) {
    let tmp = vertex0;
    vertex0 = vertex1;
    vertex1 = tmp;
  }

  return catalog
    .getElement(line.type)
    .render3D(line, layer, sceneData)
    .then(line3D => {
      if (line3D === null) {
        return;
      }

      if (line3D instanceof Three.LOD) {
        planData.sceneGraph.LODs[line.id] = line3D;
      }

      let pivot = new Three.Object3D();
      pivot.name = 'pivot';
      pivot.add(line3D);

      pivot.position.x = vertex0.x;
      pivot.position.y = layer.altitude;
      pivot.position.z = -vertex0.y;
      pivot.userData.type = 'line';
      pivot.userData.layerId = layer.id;
      pivot.userData.lineId = lineID;
      planData.plan.add(pivot);
      planData.sceneGraph.layers[layer.id].lines[lineID] = pivot;

      applyInteract(pivot, () => {
        return linesActions.selectLine(layer.id, line.id);
      });

      let opacity = layer.opacity;
      if (line.selected) {
        opacity = 1;
      }
      applyOpacity(pivot, opacity);
      planData.sceneGraph.busyResources.layers[layer.id].lines[lineID] = false;
    });
}

function updateLine(
  sceneData,
  oldSceneData,
  planData,
  layer,
  lineID,
  differences,
  catalog,
  linesActions,
  selfDestroy,
  selfBuild
) {
  let line = layer.getIn(['lines', lineID]);
  let oldLine = oldSceneData.getIn(['layers', layer.id, 'lines', lineID]);
  let mesh = planData.sceneGraph.layers[layer.id].lines[lineID];

  if (!mesh) return null;

  return catalog
    .getElement(line.type)
    .updateRender3D(
      line,
      layer,
      sceneData,
      mesh,
      oldLine,
      differences,
      selfDestroy,
      selfBuild
    );
}

function addArea(sceneData, planData, layer, areaID, catalog, areaActions) {
  if (planData.sceneGraph.busyResources.layers[layer.id].areas[areaID]) {
    setTimeout(
      () => addArea(sceneData, planData, layer, areaID, catalog, areaActions),
      100
    );
    return;
  }

  planData.sceneGraph.busyResources.layers[layer.id].areas[areaID] = true;

  let area = layer.getIn(['areas', areaID]);
  let interactFunction = () => areaActions.selectArea(layer.id, areaID);

  return catalog
    .getElement(area.type)
    .render3D(area, layer, sceneData)
    .then(area3D => {
      if (area3D instanceof Three.LOD) {
        planData.sceneGraph.LODs[areaID] = area3D;
      }

      let pivot = new Three.Object3D();
      let ceil = area3D.clone();
      let ceilMaterial = new Three.MeshStandardMaterial({
        color: 0xdfdfdf,
        side: Three.BackSide,
        roughness: 0.3,
        metalness: 0.4
      });
      ceil.material = ceilMaterial;
      let vertices = [];
      let lines = [];
      let height = 100;
      area.vertices.forEach(data => {
        vertices.push(data);
      });
      layer.lines.forEach(data => {
        lines.push(data);
      });
      for (let i = 0; i < lines.length; i++) {
        let data = lines[i];
        let realVec = [];
        data.vertices.forEach(vec => {
          realVec.push(vec);
        });
        if (vertices.includes(realVec[0]) && vertices.includes(realVec[1])) {
          height = convert(layer.ceilHeight)
            .from(layer.unit)
            .to(UNIT_CENTIMETER);
          // height = data.properties.getIn(["height", "length"]);
          break;
        }
      }
      ceil.translateZ(
        convert(layer.ceilHeight)
          .from(layer.unit)
          .to(UNIT_CENTIMETER)
      );
      pivot.name = 'pivot';
      pivot.add(area3D);
      pivot.add(ceil);
      pivot.position.y = layer.altitude;
      planData.plan.add(pivot);
      planData.sceneGraph.layers[layer.id].areas[areaID] = pivot;

      applyInteract(pivot, interactFunction);

      let opacity = layer.opacity;
      if (area.selected) {
        opacity = 1;
      }

      applyOpacity(pivot, opacity);
      planData.sceneGraph.busyResources.layers[layer.id].areas[areaID] = false;
    });
}

function updateArea(
  sceneData,
  oldSceneData,
  planData,
  layer,
  areaID,
  differences,
  catalog,
  areaActions,
  selfDestroy,
  selfBuild
) {
  let area = layer.getIn(['areas', areaID]);
  let oldArea = oldSceneData.getIn(['layers', layer.id, 'areas', areaID]);
  let mesh = planData.sceneGraph.layers[layer.id].areas[areaID];

  if (!mesh) return null;

  return catalog
    .getElement(area.type)
    .updateRender3D(
      area,
      layer,
      sceneData,
      mesh,
      oldArea,
      differences,
      selfDestroy,
      selfBuild
    );
}

function addItem(
  sceneData,
  planData,
  layer,
  itemID,
  catalog,
  itemsActions,
  rItem = null
) {
  if (planData.sceneGraph.busyResources.layers[layer.id].items[itemID]) {
    setTimeout(
      () =>
        addItem(
          sceneData,
          planData,
          layer,
          itemID,
          catalog,
          itemsActions,
          rItem
        ),
      100
    );
    return;
  }
  let item = layer.getIn(['items', itemID]);
  if (item.doorStyle === null) {
    console.log(item.name + 's doorStyle is null!');
    return;
  }
  if (!sceneData.loadFlag && scene_mode == MODE_DRAWING_ITEM_3D) {
    itemsActions.toggleLoadingCabinet();
  }
  return catalog
    .getElement(item.type)
    .render3D(item, layer, sceneData)
    .then(item3D => {
      if (item3D instanceof Three.LOD) {
        planData.sceneGraph.LODs[itemID] = item3D;
      }

      if (rItem !== null) {
        planData.plan.remove(rItem);
        disposeObject(rItem);
        rItem = null;
      }
      let pivot = new Three.Object3D();
      pivot.name = 'pivot';
      pivot.add(item3D);

      if (item.selected) {
        let tranformControl = item3D.children[item3D.children.length - 1];
        transformBox =
          tranformControl.children[tranformControl.children.length - 1];
      }

      pivot.rotation.y = (item.rotation * Math.PI) / 180 + Math.PI;
      // pivot.rotation.y = item.rotation;
      pivot.position.x = item.x;
      pivot.position.y = layer.altitude;
      pivot.position.z = -item.y;

      applyInteract(item3D, () => {
        return itemsActions.selectItem(layer.id, item.id);
      });

      let opacity = layer.opacity;
      if (item.selected) {
        opacity = 1;
        fVLine = [];
        let TransformGizmo = item3D.children[item3D.children.length - 1];
        TransformGizmo.children.forEach(child => {
          if (child.type === 'Line' && child.geometry.vertices !== undefined)
            fVLine.push(child);
        });
        setTimeout(() => getDistances(), 50);
      }

      applyOpacity(pivot, opacity);
      pivot.userData.type = 'item';
      pivot.userData.layerId = layer.id;
      pivot.userData.itemId = item.id;
      planData.plan.add(pivot);
      planData.sceneGraph.layers[layer.id].items[item.id] = pivot;
      addCountertop(
        planData.sceneGraph.layers[layer.id].countertops,
        item,
        planData,
        layer
      );
      addMolding(
        planData.sceneGraph.layers[layer.id].moldings,
        item,
        planData,
        layer
      );
      if (!sceneData.loadFlag && scene_mode == MODE_DRAWING_ITEM_3D) {
        itemsActions.endLoading();
        itemsActions.toggleLoadingCabinet();
      }
    });
}

function updateItem(
  sceneData,
  oldSceneData,
  planData,
  layer,
  itemID,
  differences,
  catalog,
  itemsActions,
  selfDestroy,
  selfBuild
) {
  let item = layer.getIn(['items', itemID]);
  let oldItem = oldSceneData.getIn(['layers', layer.id, 'items', itemID]);
  let mesh = planData.sceneGraph.layers[layer.id].items[itemID];

  console.log('--');
  if (!mesh) return null;

  return catalog
    .getElement(item.type)
    .updateRender3D(
      item,
      layer,
      sceneData,
      mesh,
      oldItem,
      differences,
      selfDestroy,
      selfBuild
    );
}

// Apply interact function to children of an Object3D
function applyInteract(object, interactFunction) {
  object.traverse(child => {
    if (child instanceof Three.Mesh) {
      child.interact = interactFunction;
    }
  });
}

// Apply opacity to children of an Object3D
function applyOpacity(object, opacity) {
  object.traverse(child => {
    if (child instanceof Three.Mesh) {
      if (child.material instanceof Three.MultiMaterial) {
        child.material.materials.forEach(materialChild => {
          materialChild.transparent = true;
          if (materialChild.maxOpacity) {
            materialChild.opacity = Math.min(materialChild.maxOpacity, opacity);
          } else if (materialChild.opacity && materialChild.opacity > opacity) {
            materialChild.maxOpacity = materialChild.opacity;
            materialChild.opacity = opacity;
          }
        });
      } else if (child.material instanceof Array) {
        child.material.forEach(material => {
          material.transparent = true;
          if (material.maxOpacity) {
            material.opacity = Math.min(material.maxOpacity, opacity);
          } else if (material.opacity && material.opacity > opacity) {
            material.maxOpacity = material.opacity;
            material.opacity = opacity;
          }
        });
      } else {
        child.material.transparent = true;
        if (child.material.maxOpacity) {
          child.material.opacity = Math.min(child.material.maxOpacity, opacity);
        } else if (child.material.opacity && child.material.opacity > opacity) {
          child.material.maxOpacity = child.material.opacity;
          child.material.opacity = opacity;
        }
      }
    }
  });
}

function updateBoundingBox(planData, flag = false) {
  if (scene_mode == MODE_DRAWING_ITEM_3D) return;
  let newBoundingBox = new Three.Box3().setFromObject(planData.plan);
  if (
    isFinite(newBoundingBox.max.x) &&
    isFinite(newBoundingBox.min.x) &&
    isFinite(newBoundingBox.max.y) &&
    isFinite(newBoundingBox.min.y) &&
    isFinite(newBoundingBox.max.z) &&
    isFinite(newBoundingBox.min.z)
  ) {
    let newCenter = new Three.Vector3(
      (newBoundingBox.max.x - newBoundingBox.min.x) / 2 + newBoundingBox.min.x,
      (newBoundingBox.max.y - newBoundingBox.min.y) / 2 + newBoundingBox.min.y,
      (newBoundingBox.max.z - newBoundingBox.min.z) / 2 + newBoundingBox.min.z
    );
    if (!flag) {
      planData.plan.position.sub(newCenter);
      planData.grid.position.sub(newCenter);
    }

    newBoundingBox.min.sub(newCenter);
    newBoundingBox.max.sub(newCenter);

    planData.boundingBox = newBoundingBox;
  }
}

/**
 * Filter the array of diffs
 * @param diffArray
 * @param sceneData
 * @param oldSceneData
 * @returns {Array}
 */
function filterDiffs(diffArray, sceneData, oldSceneData) {
  return minimizeRemoveDiffsWhenSwitchingLayers(
    minimizeChangePropertiesAfterSelectionsDiffs(
      minimizeChangePropertiesDiffs(diffArray, sceneData, oldSceneData),
      sceneData,
      oldSceneData
    ),
    sceneData,
    oldSceneData
  );
}

/**
 * Reduces the number of remove diffs when switching an hidden layer
 * @param diffArray the array of the diffs
 * @param sceneData
 * @param oldSceneData
 * @returns {Array}
 */
function minimizeRemoveDiffsWhenSwitchingLayers(
  diffArray,
  sceneData,
  oldSceneData
) {
  let foundDiff;
  let i;
  for (i = 0; i < diffArray.length && !foundDiff; i++) {
    if (diffArray[i].path[1] === 'selectedLayer') {
      foundDiff = diffArray[i];
    }
  }

  if (foundDiff) {
    if (!sceneData.getIn(['layers', oldSceneData.selectedLayer, 'visible'])) {
      return diffArray.filter(({ op, path }) => {
        return (
          !(
            path[path.length - 1] === 'selected' &&
            path[1] === 'layers' && path[2] === oldSceneData.selectedLayer
          ) &&
          !(op === 'remove' && path.indexOf(oldSceneData.selectedLayer) !== -1)
        );
      });
    }
  }

  return diffArray;
}

/**
 * Reduces the number of change properties diffs for selected elements
 * @param diffArray the array of the diffs
 * @param sceneData
 * @param oldSceneData
 * @returns {Array}
 */
function minimizeChangePropertiesAfterSelectionsDiffs(
  diffArray,
  sceneData,
  oldSceneData
) {
  let idsFound = {};
  diffArray.forEach(({ path }) => {
    if (path[5] === 'selected') {
      idsFound[path[4]] = path[4];
    }
  });

  return diffArray.filter(({ path }) => {
    if (path[5] === 'properties') {
      return idsFound[path[4]] ? false : true;
    }
    return true;
  });
}

/**
 * Reduces the number of change properties diffs
 * @param diffArray the array of the diffs
 * @param sceneData
 * @param oldSceneData
 * @returns {Array}
 */
function minimizeChangePropertiesDiffs(diffArray, sceneData, oldSceneData) {
  let idsFound = {};
  return diffArray.filter(({ path }) => {
    if (path[5] === 'properties') {
      return idsFound[path[4]] ? false : (idsFound[path[4]] = true);
    } else if (path[5] === 'misc') {
      // Remove misc changes
      return false;
    }
    return true;
  });
}

// countertops:
//   o id:
//   o items: [itemid]
//   o ct3d: 3d object
//   o catid
//   o pos
//   o rotRad
//   o size
function createCTFromItem(item, unit, catalog) {
  return createCTFromItems([item], unit, catalog);
}

function createMDFromItem(item, unit, catalog) {
  return createMDFromItems([item], unit, catalog);
}

function createCTFromItems(items, unit, catalog) {
  let item0 = items[0];
  let item1 = items[items.length - 1];
  let CTId = IDBroker.acquireID();
  let ct3d = null;
  let catid = item0.type;
  let pos = { x: (item0.x + item1.x) / 2, y: (item0.y + item1.y) / 2 };
  let rotRad = (item0.rotation / 180) * Math.PI;

  let cat = catalog.elements[catid];
  // let width = convert(item0.properties.getIn(['width', '_length'])).from('in').to('cm');
  // let depth = convert(item0.properties.getIn(['depth', '_length'])).from('in').to('cm');
  // let height = convert(item0.properties.getIn(['height', '_length'])).from('in').to('cm');
  let width = item0.properties.get('width').get('_length');
  let widthUnit = item0.properties.get('width').get('_unit') || 'cm';
  width = convert(width)
    .from(widthUnit)
    .to('cm');

  let depth = item0.properties.get('depth').get('_length');
  let depthUnit = item0.properties.get('depth').get('_unit') || 'cm';
  depth = convert(depth)
    .from(depthUnit)
    .to('cm');

  let height = item0.properties.get('height').get('_length');
  let heightUnit = item0.properties.get('height').get('_unit') || 'cm';
  height = convert(height)
    .from(heightUnit)
    .to('cm');
  let size = { width: width * items.length, depth, height };

  return { id: CTId, items, ct3d, catid, pos, rotRad, size };
}

function createMDFromItems(items, unit, catalog) {
  let item0 = items[0];
  let item1 = items[items.length - 1];
  let MDId = IDBroker.acquireID();

  let catid = item0.type;
  let pos = { x: (item0.x + item1.x) / 2, y: (item0.y + item1.y) / 2 };
  let rotRad = (item0.rotation / 180) * Math.PI;

  let cat = catalog.elements[catid];
  // let width = convert(item0.properties.getIn(['width', '_length'])).from('in').to('cm');
  // let depth = convert(item0.properties.getIn(['depth', '_length'])).from('in').to('cm');
  // let height = convert(item0.properties.getIn(['height', '_length'])).from('in').to('cm');
  let width = item0.properties.get('width').get('_length');
  let widthUnit = item0.properties.get('width').get('_unit') || 'cm';
  width = convert(width)
    .from(widthUnit)
    .to('cm');

  let depth = item0.properties.get('depth').get('_length');
  let depthUnit = item0.properties.get('depth').get('_unit') || 'cm';
  depth = convert(depth)
    .from(depthUnit)
    .to('cm');

  let height = item0.properties.get('height').get('_length');
  let heightUnit = item0.properties.get('height').get('_unit') || 'cm';
  height = convert(height)
    .from(heightUnit)
    .to('cm');
  let size = { width: width * items.length, depth, height };

  return { id: MDId, items, meshes: {}, catid, pos, rotRad, size };
}

function tryAdjacent(ct1, ct2) {
  if (ct1.catid.includes('Dishwasher') && ct1.catid.includes('BF')) {
    let temp = ct1;
    ct1 = ct2;
    ct2 = temp;
  }

  log('----tryAdjacent', ct1.id, ct2.id);
  if (
    !ct2.catid.includes('Dishwasher') &&
    !ct2.catid.includes('BF') &&
    ct1.catid != ct2.catid
  )
    return false;
  if (ct1.rotRad != ct2.rotRad) return false;

  log('ct1', ct1.pos.x, ct1.pos.y, ct1.size.width);
  log('ct2', ct2.pos.x, ct2.pos.y, ct2.size.width);

  let dist = GeometryUtils.verticesDistance(ct1.pos, ct2.pos);
  let totalwidth = ct1.size.width + ct2.size.width;
  log('epsilon', dist, totalwidth, Math.abs(2 * dist - totalwidth));
  if (Math.abs(2 * dist - totalwidth) / totalwidth > 1e-3) return false;

  log('----success');
  let newpos = {
    x: (ct1.pos.x * ct1.size.width + ct2.pos.x * ct2.size.width) / totalwidth,
    y: (ct1.pos.y * ct1.size.width + ct2.pos.y * ct2.size.width) / totalwidth
  };
  let newsize = {
    width: totalwidth,
    depth: ct1.size.depth,
    height: ct1.size.height
  };

  let CTId = IDBroker.acquireID();
  let items =
    GeometryUtils.compareVertices(ct1.pos, ct2.pos) > 0
      ? [...ct1.items, ...ct2.items]
      : [...ct2.items, ...ct1.items];
  let ct3d = null;
  let catid = ct1.catid;

  return {
    id: CTId,
    items,
    ct3d,
    catid,
    pos: newpos,
    rotRad: ct1.rotRad,
    size: newsize
  };
}

function tryAdjacentMD(md1, md2) {
  log('----tryAdjacent', md1.id, md2.id);

  if (md1.catid != md2.catid) return false;
  if (md1.rotRad != md2.rotRad) return false;

  log('md1', md1.pos.x, md1.pos.y, md1.size.width);
  log('md2', md2.pos.x, md2.pos.y, md2.size.width);
  let dist = GeometryUtils.verticesDistance(md1.pos, md2.pos);
  let totalwidth = md1.size.width + md2.size.width;
  log('epsilon', dist, totalwidth, Math.abs(2 * dist - totalwidth));
  if (Math.abs(2 * dist - totalwidth) / totalwidth > 1e-3) return false;

  log('----success');
  let newpos = {
    x: (md1.pos.x * md1.size.width + md2.pos.x * md2.size.width) / totalwidth,
    y: (md1.pos.y * md1.size.width + md2.pos.y * md2.size.width) / totalwidth
  };
  let newsize = {
    width: totalwidth,
    depth: md1.size.depth,
    height: md1.size.height
  };

  let MDId = IDBroker.acquireID();
  let items =
    GeometryUtils.compareVertices(md1.pos, md2.pos) > 0
      ? [...md1.items, ...md2.items]
      : [...md2.items, ...md1.items];

  let catid = md1.catid;

  return {
    id: MDId,
    items,
    meshes: {},
    catid,
    pos: newpos,
    rotRad: md1.rotRad,
    size: newsize
  };
}

function showItemCT(item, CT, visible, planData, layer) {
  let item3D = planData.sceneGraph.layers[layer.id].items[item.id];
  if (item3D === undefined) return undefined;
  item3D.traverse(child => {
    if (child.name.includes('countertop')) {
      child.visible = visible;

      if (child.material.map === null) {
        let normalMap = item.counterTop.uri;
        let interiortexture = loadTexture(normalMap);
        applyTexture(child.material, interiortexture, 100, 100);
        child.material.color = new Color(1, 1, 1);
        return;
      }
      child.material.map.repeat.x = 1;
    }
  });
}

function getCountertopMesh(item, visible, planData, layer) {
  if (item.type.includes('Dishwasher')) return undefined;
  if (item.type.includes('BF')) return undefined;
  let item3D = planData.sceneGraph.layers[layer.id].items[item.id];
  let countertop = null;
  if (item3D === undefined) return undefined;
  item3D.traverse(child => {
    if (child.name.includes('countertop')) {
      countertop = child;
    }
  });
  return countertop;
}

function getDoorStyleMaterial(item, planData, layer, name) {
  let item3D = planData.sceneGraph.layers[layer.id].items[item.id];
  let door_mesh = null;
  if (item3D === undefined) return undefined;
  item3D.traverse(child => {
    if (
      !door_mesh &&
      !child.name.includes('countertop') &&
      !child.name.includes('_interior_') &&
      !child.name.includes('handle') &&
      child.type === OBJTYPE_MESH
    ) {
      door_mesh = child;
    }
  });
  return door_mesh && door_mesh.material;
}

function addCTMesh(countertop, planData, layer) {
  if (countertop.items.length == 1) {
    showItemCT(countertop.items[0], countertop, true, planData, layer);
  } else {
    let ctMesh = null;
    countertop.items.some(item => {
      ctMesh = getCountertopMesh(item, true, planData, layer);
      return ctMesh != null;
    });
    if (!ctMesh) return;

    if (0) {
      let material = ctMesh.material;
      let thickness = 3.81;
      let geometry = new Three.BoxBufferGeometry(
        countertop.size.width,
        thickness,
        countertop.size.depth
      );
      let ct3d = new Three.Mesh(geometry, material);

      countertop.ct3d = ct3d;
      planData.plan.add(ct3d);

      ct3d.position.x = countertop.pos.x;
      ct3d.position.z = -countertop.pos.y;
      ct3d.position.y = countertop.size.height + thickness / 2;

      ct3d.quaternion.setFromAxisAngle(
        new Three.Vector3(0, 1, 0),
        countertop.rotRad
      );

      ct3d.name = 'countertops';
    } else {
      let ct3d = ctMesh.clone();
      countertop.ct3d = ct3d;
      ct3d.visible = true;
      ct3d.material = ctMesh.material.clone();
      if (ct3d.material.map !== null) {
        ct3d.material.map.copy(ctMesh.material.map);
        ct3d.material.map.repeat.x = countertop.items.length;
      }
      ctMesh.updateMatrix();
      ctMesh.parent.updateMatrix();
      ctMesh.parent.parent.updateMatrix();
      let ctmeshMat = ctMesh.matrix;
      let objMat = ctMesh.parent.matrix;
      let pivotMat = ctMesh.parent.parent.matrix;
      pivotMat.setPosition(
        new Three.Vector3(countertop.pos.x, 0, -countertop.pos.y)
      );

      let width = countertop.items
        .find(ct => !ct.type.includes('Dishwasher') && !ct.type.includes('BF'))
        .properties.get('width')
        .get('_length');
      let unit_width =
        countertop.items
          .find(
            ct => !ct.type.includes('Dishwasher') && !ct.type.includes('BF')
          )
          .properties.get('width')
          .get('_unit') || 'cm';
      width = convert(width)
        .from(unit_width)
        .to('cm');
      pivotMat.scale(new Three.Vector3(countertop.size.width / width, 1, 1));

      let finalMat = objMat.premultiply(pivotMat);
      ct3d.applyMatrix(finalMat);

      planData.plan.add(ct3d);
      ct3d.name = 'countertops';
    }
  }
}

function getArea(a, b, c) {
  return a.x * (b.y - c.y) + b.x * (c.y - a.y) + c.x * (a.y - b.y);
}

/**
 * @param MDV - molding points
 * @param width - cabinet width
 * @param depth - cabinet depth ( z axis )
 * @param model - molding info that appear in real 3D
 * @param outline - Contains Outline Info ( Points, Width, Height ) Of Corner Cabinet
 */
function moldingVertices(
  molding_type,
  mdGeo,
  MDV,
  width,
  depth,
  model,
  svg_width,
  svg_height,
  outline
) {
  if (outline) {
    // `outline` is truy value means it's a corner cabinet

    console.log(outline);

    let { points } = outline;
    let outlineWidth = outline.width;
    let outlineHeight = outline.height;
    let length = points.length; // the Count of Points

    // Point O
    let o = new Three.Vector2(0, 0);

    // First Point == Last Point
    if (points[0] == points[length - 1]) {
      points = points.slice(0, length - 1);
      length -= 1;
    }

    points.forEach((cur, i) => {
      let prev = points[(i + length - 1) % length];
      let next = points[(i + 1) % length];

      let vecPC = cur.clone().sub(prev); // vector from point `prev` to point `cur`
      let vecNC = cur.clone().sub(next); // vector from point `next` to point `cur`

      // Calculate normal vector to `vecPC`
      let vecNorm;
      if (vecPC.x) {
        vecNorm = new Three.Vector2(-vecPC.y / vecPC.x, 1);
      } else {
        vecNorm = new Three.Vector2(1, 0);
      }

      // make sure one heads to out of `outline`
      let p1 = cur.clone().add(vecNorm); // the point that's away from `cur` by `vecNorm`
      if (getArea(prev, p1, cur) > 0) {
        vecNorm.multiplyScalar(-1);
      }

      let area = 0.5 * getArea(prev, cur, next);
      let lenPN = next
        .clone()
        .sub(prev)
        .length();
      let lenNC = vecNC.length();
      let lenPC = vecPC.length();

      let alpha = Math.acos(
        (lenNC * lenNC + lenPC * lenPC - lenPN * lenPN) / (2 * lenNC * lenPC)
      );

      if (area < 0) alpha = 2 * Math.PI - alpha; // Exterior Angle
      alpha /= 2;

      vecNorm.rotateAround(o, Math.PI / 2 - alpha);
      vecNorm.normalize();
      vecNorm.multiplyScalar(1 / Math.sin(alpha));

      // Make 3D Points
      MDV.forEach(p => {
        // Outline's points
        let ox = (cur.x / outlineWidth - 0.5) * width;
        let oy = (cur.y / outlineHeight - 0.5) * depth;

        let x = ox + vecNorm.x * ((p.x / svg_width - 0.5) * model.length);
        let y = (0.5 - p.y / svg_height) * model.width;
        let z = oy + vecNorm.y * ((p.x / svg_height - 0.5) * model.length);

        mdGeo.vertices.push(new Three.Vector3(x, y, z));
      });
    });
  } else {
    let pos_sign = [
      [-1, 1, -1],
      [-1, 1, 1],
      [1, 1, 1],
      [1, 1, -1]
    ];
    let offset_sign = [];

    switch (molding_type) {
      case MOLDING_TYPE_BASE:
        offset_sign = [
          [1, 0, 1],
          [1, 0, 1],
          [1, 0, 1],
          [1, 0, 1]
        ];
        break;
      case MOLDING_TYPE_WALL_TALL:
        offset_sign = [
          [1, 0, 0],
          [1, 0, 1],
          [1, 0, 1],
          [1, 0, 0]
        ];
        break;
      default:
        break;
    }

    for (let num = 0; num < 4; num++) {
      MDV.map(vector => {
        let x =
          pos_sign[num][0] *
          (offset_sign[num][0] * (vector.x / svg_width - 0.5) * model.length +
            width / 2);
        let y =
          pos_sign[num][1] * ((0.5 - vector.y / svg_height) * model.width);
        let z =
          pos_sign[num][2] *
          (offset_sign[num][2] * (vector.x / svg_width - 0.5) * model.length +
            depth / 2);
        return mdGeo.vertices.push(new Three.Vector3(x, y, z));
      });
    }
  }

  return mdGeo;
}

let dcmFace = function(a, leng, MDV) {
  for (let i = a; i < a + 2; i++) {
    let f_a1 = i;
    let f_b1 = i + leng;
    let f_c1 = i + leng + 1;
    let f_a2 = i;
    let f_b2 = i + 1;
    let f_c2 = f_c1;
    MDV.faces.push(new Three.Face3(f_a1, f_b1, f_c1));
    MDV.faces.push(new Three.Face3(f_c2, f_b2, f_a2));
  }
  MDV.faces.push(new Three.Face3(a + 2, a + leng + 2, a + leng + 4));
  MDV.faces.push(new Three.Face3(a + leng + 4, a + 4, a + 2));

  for (let i = a + 4; i < a + 74; i++) {
    let f_a1 = i;
    let f_b1 = i + leng;
    let f_c1 = i + leng + 1;
    let f_a2 = i;
    let f_b2 = i + 1;
    let f_c2 = f_c1;
    MDV.faces.push(new Three.Face3(f_a1, f_b1, f_c1));
    MDV.faces.push(new Three.Face3(f_c2, f_b2, f_a2));
  }
  MDV.faces.push(new Three.Face3(a + 50, a + 3, a + 103));
  MDV.faces.push(new Three.Face3(a + 103, a + 150, a + 50));
};

let fbmFace = function(a, leng, MDV) {
  let total_leng = leng * 4;
  for (let i = a; i < a + leng - 1; i++) {
    let f_a1 = i;
    let f_b1 = (i + leng) % total_leng;
    let f_c1 = (i + leng + 1) % total_leng;
    let f_a2 = i;
    let f_b2 = i + 1;
    let f_c2 = f_c1;
    MDV.faces.push(new Three.Face3(f_a1, f_b1, f_c1));
    MDV.faces.push(new Three.Face3(f_c2, f_b2, f_a2));
  }
};

const assignUVs = geometry => {
  geometry.computeBoundingBox();
  let h = 400;
  let { min, max } = geometry.boundingBox;
  let offset = new Three.Vector2(0 - min.x, 0 - min.z);
  let range = new Three.Vector2(max.x - min.x, max.z - min.z);

  geometry.faceVertexUvs[0] = geometry.faces.map(face => {
    let v1 = geometry.vertices[face.a];
    let v2 = geometry.vertices[face.b];
    let v3 = geometry.vertices[face.c];

    return [
      new Three.Vector2(
        (v1.x + offset.x) / range.x,
        (v1.z + offset.y) / (range.y + h)
      ),
      new Three.Vector2(
        (v2.x + offset.x) / range.x,
        (v2.z + offset.y) / (range.y + h)
      ),
      new Three.Vector2(
        (v3.x + offset.x) / range.x,
        (v3.z + offset.y) / (range.y + h)
      )
    ];
  });

  geometry.uvsNeedUpdate = true;
};

const assignUVsA = geometry => {
  geometry.computeBoundingBox();
  let h = 200;
  let { min, max } = geometry.boundingBox;
  let offset = new Three.Vector2(0 - min.x, 0 - min.y);
  let range = new Three.Vector2(max.x - min.x, max.y - min.y);

  geometry.faceVertexUvs[0] = geometry.faces.map(face => {
    let v1 = geometry.vertices[face.a];
    let v2 = geometry.vertices[face.b];
    let v3 = geometry.vertices[face.c];
    return [
      new Three.Vector2(
        (v1.x + offset.x) / (range.x + h),
        (v1.y + offset.y) / (range.y + h)
      ),
      new Three.Vector2(
        (v2.x + offset.x) / (range.x + h),
        (v2.y + offset.y) / (range.y + h)
      ),
      new Three.Vector2(
        (v3.x + offset.x) / (range.x + h),
        (v3.y + offset.y) / (range.y + h)
      )
    ];
  });

  geometry.uvsNeedUpdate = true;
};

/*
  Remove specified type of 3d object easily
*/
export function deleteSpecifiedMeshObjects(type) {
  let childLen = planData.plan.children.length;

  let children = [];
  for (let i = 0; i < childLen; i++) {
    if (planData.plan.children[i].name != type)
      children.push(planData.plan.children[i]);
  }
  planData.plan.children = children;
}

export function getMeshesFromScene() {
  let childLen = planData.plan.children.length;

  let children = [];
  for (let i = 0; i < childLen; i++) {
    if (planData.plan.children[i].type == OBJTYPE_MESH)
      children.push(planData.plan.children[i]);
  }

  return children;
}

export function threedfabs(a) {
  return a > 0 ? a : -a;
}

export function getDistanceBetweenLineSegment(pos1, pos2, pos3, pos4) {
  if (pos1.x == pos2.x && pos3.x == pos4.x) return pos3.x - pos1.x;
  else if (pos1.y == pos2.y && pos3.y == pos4.y) return pos3.y - pos1.y;
  else return -1;
}

function isSimilar(a, b) {
  if (threedfabs(a - b) <= 0.01) return 1;
  return 0;
}

export function sameSign(pos1, pos2, pos3) {
  let ch1 = 0.0;
  let ch2 = 1.0;
  if (isSimilar(pos1.x, pos2.x) && isSimilar(pos1.x, pos3.x)) {
    ch1 = (pos2.y - pos1.y) * (pos3.y - pos1.y);
  } else if (isSimilar(pos1.y, pos2.y) && isSimilar(pos1.y, pos3.y)) {
    ch1 = (pos2.x - pos1.x) * (pos3.x - pos1.x);
  } else {
    ch1 = ((pos2.y - pos1.y) * 1.0) / (pos2.x - pos1.x);
    ch2 = ((pos3.y - pos1.y) * 1.0) / (pos3.x - pos1.x);
  }
  if (ch1 * ch2 >= 0) return 1;
  return 0;
}

export function getTotalDistance(pos, rect) {
  let sum = 0;
  for (let i = 0; i < rect.length; i++) {
    sum += verticesDistance(pos, rect[i]);
  }
  return sum;
}
const applyTexture = (material, texture, length, height) => {
  if (texture) {
    material.map = texture;
    material.needsUpdate = true;
    material.map.wrapS = Three.RepeatWrapping;
    material.map.wrapT = Three.RepeatWrapping;
    material.map.repeat.set(length * 0.01, height * 0.01);

    if (texture.normal) {
      material.normalMap = loadTexture(texture.normal.uri);
      material.normalScale = new Vector2(
        texture.normal.normalScaleX,
        texture.normal.normalScaleY
      );
      material.normalMap.wrapS = Three.RepeatWrapping;
      material.normalMap.wrapT = Three.RepeatWrapping;
      material.normalMap.repeat.set(
        length * texture.normal.lengthRepeatScale,
        height * texture.normal.heightRepeatScale
      );
    }
  }
};

/**
 *
 * @param {{is_corner:number,itemInfo:Item,layoutpos:string,pos:{x:number,y:number},rect:[{x:number,y:number}],
 * rotRad:number,size:{depth:number,width:number,height:number}}} item
 * @param {Layer} layer
 * @param {{boundingBox:Box3,catalog:Catalog,grid: Object3D,plan: Object3D,sceneData: Scene,sceneGraph}} planData
 * @param {Scene} scene
 */
export function createBacksplash(item, layer, planData, scene) {
  let { sceneGraph } = planData;
  let selectedLayer = planData.sceneData.selectedLayer;
  /**
   * @type {[{backsplash,info:{rotY:number,posX:number,posY:number,posZ:number,splashWidth:number,splashHeight:number,splashDepth:number,id:string}}]}
   */
  let backsplashes = sceneGraph.layers[selectedLayer].backsplashes;
  let backsplashApplied = layer.get('backsplashApplied');
  let name = 'backsplash' + item.itemInfo.id;
  let index = backsplashes.findIndex(item => item.backsplash.name === name);
  let itemToSave = { backsplash: null, info: {} };

  if (!item.itemInfo.backsplashVisible) {
    // If backsplash is not visible
    if (index >= 0) {
      backsplashes.splice(index, 1); // Remove from backsplashes array
    }
    return;
  }

  // Get wall items

  let i,
    wallItems = [];
  let allWallItems = GeometryUtils.getAllItemSpecified(
    scene,
    planData.catalog,
    'Wall'
  );
  for (i = 0; i < allWallItems.others.length; i++)
    wallItems.push(allWallItems.others[i]);
  if (allWallItems.cur) wallItems.push(allWallItems.cur);

  // Get information of item itself

  let altitude = item.itemInfo.properties.get('altitude').get('_length');
  let altitudeUnit =
    item.itemInfo.properties.get('altitude').get('_unit') || 'cm';
  altitude = convert(altitude)
    .from(altitudeUnit)
    .to('cm');
  let thickness = 1,
    /** Height --- altitude */ depth;

  // Calc wall & hole items info

  /**
   * Wall & Hole items info
   * @type {[{x:number,width:number,altitude:number}]}
   */
  let altItems = [],
    flag = false;

  wallItems.map(wallItem => {
    let altitude = wallItem.itemInfo.properties.get('altitude').get('_length');
    let altitudeUnit =
      wallItem.itemInfo.properties.get('altitude').get('_unit') || 'cm';
    altitude = convert(altitude)
      .from(altitudeUnit)
      .to('cm');
    altItems.push({ x: wallItem.pos.x, width: wallItem.size.width, altitude });
  });
  layer.holes.map(hole => {
    let width = hole.properties.getIn(['width', 'length']);
    let altitude = hole.properties.getIn(['altitude', 'length']);
    altItems.push({ x: hole.x, width, altitude });
  });

  if (altItems.length > 0) {
    depth = altItems[0].altitude;
    altItems.map(altItem => {
      if (
        item.pos.x + item.size.width / 2 >= altItem.x - altItem.width / 2 &&
        item.pos.x - item.size.width / 2 <= altItem.x + altItem.width / 2
      ) {
        if (depth >= altItem.altitude) {
          depth = altItem.altitude;
          flag = true;
        }
      }
    });
  }
  if (!flag) depth = 52 * 2.54;

  // Get backsplash info

  let posX =
    item.pos.x - Math.sin(item.rotRad) * (item.size.depth / 2 - thickness);
  let posY = altitude + depth / 2;
  let posZ =
    -item.pos.y - Math.cos(item.rotRad) * (item.size.depth / 2 - thickness);

  // TODO If you want apply backsplash to walls that's not vertical or horizontal ( slope )
  // You must update this calcuating rotY
  let rotY = (Math.PI / 2) * Math.sin(item.rotRad);

  let splashWidth = item.size.width;
  let splashHeight = depth;
  let splashDepth = thickness;

  // Find mergeable other backsplashes

  let info,
    wholeWidth,
    /** Width factor */ factor,
    distance,
    halfWidth,
    /** To get new center */ centerFactor;

  for (let back of backsplashes) {
    info = back.info;
    if (info.height === splashHeight && info.rotY === rotY) {
      factor = Math.cos(rotY) || 1;
      distance = Math.hypot(posX - info.posX, posZ - info.posZ) / factor;
      halfWidth = (info.width + splashWidth) / 2;

      if (Math.abs(distance - halfWidth) < 1e-2) {
        // dispose `back`
        if (info.id) {
          deleteSpecifiedMeshObjects('backsplash' + info.id);
        }

        // Get center position
        wholeWidth = info.width + splashWidth;
        centerFactor = info.width / wholeWidth;
        posX = posX + centerFactor * (info.posX - posX);
        posZ = posZ + centerFactor * (info.posZ - posZ);

        splashWidth = wholeWidth;
      }
    }
  }

  itemToSave.info.posX = posX;
  itemToSave.info.posY = posY;
  itemToSave.info.posZ = posZ;

  itemToSave.info.rotY = rotY;

  itemToSave.info.width = splashWidth;
  itemToSave.info.height = splashHeight;
  itemToSave.info.depth = splashDepth;

  // Make material

  let texture = layer.get('backsplash');
  var areaMaterial = new Three.MeshStandardMaterial({
    side: Three.DoubleSide,
    metalness: texture.metalness,
    roughness: texture.roughness
  });
  let interiortexture = loadTexture(texture.uri);
  applyTexture(areaMaterial, interiortexture, 100, 100);

  // Make geometry

  let geometry = new Three.BoxBufferGeometry(
    splashWidth,
    splashHeight,
    splashDepth
  );

  // Make backsplash mesh

  let backsplash = (itemToSave.backsplash = new Three.Mesh(
    geometry,
    areaMaterial
  ));

  backsplash.position.x = itemToSave.info.posX;
  backsplash.position.y = itemToSave.info.posY;
  backsplash.position.z = itemToSave.info.posZ;

  backsplash.rotation.y = itemToSave.info.rotY;
  itemToSave.info.id = item.itemInfo.id;

  deleteSpecifiedMeshObjects('backsplash' + item.itemInfo.id);

  if (item.rotRad % (Math.PI / 2) === 0) planData.plan.add(backsplash);

  backsplash.name = name;
  backsplash.visible =
    item.itemInfo.get('backsplashVisible') &&
    backsplashApplied &&
    item.rotRad % (Math.PI / 2) === 0;

  // Save to scene graph

  if (index < 0) {
    backsplashes.push(itemToSave);
  } else {
    disposeObject(backsplashes[index].backsplash);
    backsplashes.splice(index, 1, itemToSave);
  }
}

/**
 * @param {Map} item - Selected Item
 */
function addMDMesh(molding, planData, layer, item) {
  let outline = null;

  if (item) {
    // Get Outline Data of Selected Item
    outline = planData.catalog.elements[item.get('type')].info.outline;

    if (outline) {
      // Extract Points from `outline`
      var outlinePaths = outline.paths;
      var outlineWidth = outline.svgWidth;
      var outlineHeight = outline.svgHeight;
      var outlinePoints = []; // Hold Points Of SVG

      for (let path of outlinePaths) {
        for (let subPath of path.subPaths) {
          outlinePoints = outlinePoints.concat(subPath.getPoints());
        }
      }
    }
  }

  let moldingCate = layer.getIn(['molding']);
  moldingCate.filter(child => {
    let point = [];
    let data = child.data;
    let { paths, svg_width, svg_height } = data;
    for (let i = 0; i < paths.length; i++) {
      let path = paths[i];
      for (let j = 0, jl = path.subPaths.length; j < jl; j++) {
        let subPath = path.subPaths[j];
        point = subPath.getPoints();
      }
    }

    _addMDMesh(
      molding,
      planData,
      layer,
      point,
      child,
      svg_width,
      svg_height,
      outline && {
        points: outlinePoints,
        width: outlineWidth,
        height: outlineHeight
      }
    );
  });
}

/**
 * @param data  - Molding SVG Points
 * @param outline - Contains Outline Info ( Points & Width, Height ) Of Corner Cabinet
 */
function _addMDMesh(
  molding,
  planData,
  layer,
  data,
  child,
  svg_width,
  svg_height,
  outline
) {
  let material = getDoorStyleMaterial(
    molding.items[0],
    planData,
    layer,
    child.name
  );
  if (!material) return;
  let layoutType = molding.items[0].layoutpos;
  let altitude = convert(
    molding.items[0].properties.get('altitude').get('length')
  )
    .from('in')
    .to('cm');
  // let visible = molding.items[0];

  // If Data is not Empty
  if (
    data !== null ||
    data !== undefined ||
    (typeof data === 'object' && Object.keys(data).length !== 0)
  ) {
    let molding_type = MOLDING_TYPE_NONE;
    let faceCount = 0;
    if (layoutType === 'Base' && child.molding_type === 'Base') {
      molding_type = MOLDING_TYPE_BASE;
      faceCount = 4;
    } else if (
      (layoutType === 'Wall' && child.molding_type === 'Wall') ||
      (layoutType === 'Tall' && child.molding_type === 'Tall')
    ) {
      molding_type = MOLDING_TYPE_WALL_TALL;
      faceCount = 3;
    }
    if (molding_type != MOLDING_TYPE_NONE) {
      let mWidth = molding.size.width; //cabinet
      let mDepth = molding.size.depth;
      let thickness = 2;
      let geometry = new Three.Geometry();
      let length = data.length; //point array
      let temp_unit = child.height_unit;
      if (temp_unit === 'inch') {
        child.height = convert(child.height)
          .from('in')
          .to('cm');
        child.height_unit = 'cm';
      }
      temp_unit = child.width_unit;
      if (temp_unit === 'inch') {
        child.width = convert(child.width)
          .from('in')
          .to('cm');
        child.width_unit = 'cm';
      }
      temp_unit = child.length_unit;
      if (temp_unit === 'inch') {
        child.length = convert(child.length)
          .from('in')
          .to('cm');
        child.length_unit = 'cm';
      }

      geometry.vertices.verticesNeedUpdate = true;
      geometry = moldingVertices(
        molding_type,
        geometry,
        data,
        mWidth,
        mDepth,
        child,
        svg_width,
        svg_height,
        outline
      );
      if (outline) {
        let total = geometry.vertices.length;
        let len = geometry.vertices.length / length;
        for (let i = 0; i < len; i++) {
          for (let j = i * length; j < (i + 1) * length - 1; j++) {
            let f_a1 = j;
            let f_b1 = (j + length) % total;
            let f_c1 = (j + length + 1) % total;
            let f_a2 = j;
            let f_b2 = j + 1;
            let f_c2 = f_c1;
            geometry.faces.push(new Three.Face3(f_a1, f_b1, f_c1));
            geometry.faces.push(new Three.Face3(f_c2, f_b2, f_a2));
          }
        }
      } else {
        for (let i = 0; i < faceCount; i++)
          fbmFace(i * length, length, geometry);
      }

      geometry.computeFaceNormals();
      geometry.computeVertexNormals();

      assignUVs(geometry);

      let fbm3d = new Three.Mesh(geometry, material);
      fbm3d.position.x = molding.pos.x;
      fbm3d.position.z = -molding.pos.y;
      fbm3d.position.y = child.height + altitude;
      switch (child.location_type) {
        case 'Top':
          fbm3d.position.y += molding.size.height;
          break;
        case 'Middle':
          fbm3d.position.y += molding.size.height / 2;
          break;
        case 'Bottom':
          break;
        default:
          break;
      }
      planData.plan.add(fbm3d);
      fbm3d.quaternion.setFromAxisAngle(
        new Three.Vector3(0, 1, 0),
        molding.rotRad
      );
      fbm3d.name = child.name + 'molding';
      fbm3d.visible = true;
      molding.meshes[child.name] = fbm3d;
    }
  } else {
    if (layoutType === 'Base') {
      let material = mesh.material;
      let thickness = 15;
      let geometry = new Three.BoxBufferGeometry(
        molding.size.width + 1,
        thickness,
        molding.size.depth
      );
      let fbm3d = new Three.Mesh(geometry, material);

      molding.fbm3d = fbm3d;
      planData.plan.add(fbm3d);

      fbm3d.position.x = molding.pos.x;
      fbm3d.position.z = -molding.pos.y;
      fbm3d.position.y = thickness / 2;

      fbm3d.quaternion.setFromAxisAngle(
        new Three.Vector3(0, 1, 0),
        molding.rotRad
      );

      fbm3d.name = 'fbmmolding';
    }
  }
}

function deleteCTMesh(countertop, planData, layer) {
  log('--deleteCTMesh', countertop);
  if (countertop.items.length == 1) {
    showItemCT(countertop.items[0], countertop, false, planData, layer);
  } else {
    let ct3d = countertop.ct3d;
    if (ct3d) {
      planData.plan.remove(ct3d);
      disposeObject(ct3d);
    }
  }
}

export function deleteMDMesh(molding, planData, layer) {
  for (let name in molding.meshes) {
    let mesh = molding.meshes[name];
    if (mesh) {
      planData.plan.remove(mesh);
      disposeObject(mesh);
      delete molding.meshes[name];
    }
  }
}

function log() {
  // console.log(...arguments);
}

function addCountertop(CTArray, addItem, planData, layer) {
  log('addCountertop', [...CTArray], addItem.id);
  let tmp = planData;
  tmp = tmp && tmp.catalog.getElement(addItem.type);
  let long_name = tmp && tmp.long_name;
  if (long_name.includes('Sink ')) return;

  let newCT = createCTFromItem(
    addItem,
    planData.sceneGraph.unit,
    planData.catalog
  );
  let oldCT = null;
  let extCT = false;
  let extCTIndex = -1;

  let tryMergeCT = function() {
    extCTIndex = CTArray.findIndex(el => {
      extCT = tryAdjacent(newCT, el);
      return extCT != false;
    });
    return extCTIndex >= 0;
  };
  while (tryMergeCT()) {
    oldCT = CTArray.splice(extCTIndex, 1)[0];
    deleteCTMesh(newCT, planData, layer);
    deleteCTMesh(oldCT, planData, layer);
    newCT = extCT;
  }
  addCTMesh(newCT, planData, layer);
  CTArray.push(newCT);
}

export function deleteCountertop(CTArray, delItem, planData, layer) {
  if (delItem == undefined) {
    return;
  }
  log('deleteCountertop', [...CTArray], delItem.id);
  let delCT = null;
  let delItemIndex = -1;
  let delCTIndex = CTArray.findIndex(el => {
    delItemIndex = el.items.findIndex(el => el.id == delItem.id);
    return delItemIndex >= 0;
  });

  if (delCTIndex < 0) return;

  delCT = CTArray.splice(delCTIndex, 1)[0];

  if (delCT.items.length > 1) {
    deleteCTMesh(delCT, planData, layer);

    let ct1 = delCT.items.slice(0, delItemIndex);
    let ct2 = delCT.items.slice(delItemIndex + 1);

    [ct1, ct2].forEach(cts => {
      if (cts.length == 0) return;
      let newCT = createCTFromItems(
        cts,
        planData.sceneGraph.unit,
        planData.catalog
      );
      CTArray.push(newCT);
      addCTMesh(newCT, planData, layer);
    });
  }
}

export function deleteMolding(MDArray, delItem, planData, layer) {
  if (delItem == undefined) {
    return;
  }

  log('deleteMolding', [...MDArray], delItem.id);
  let delMD = null;
  let delItemIndex = -1;
  let delMDIndex = MDArray.findIndex(el => {
    delItemIndex = el.items.findIndex(el => el.id == delItem.id);
    return delItemIndex >= 0;
  });

  if (delMDIndex < 0) return;

  delMD = MDArray.splice(delMDIndex, 1)[0];
  if (delMD.items.length > 1) {
    deleteMDMesh(delMD, planData, layer);

    let md1 = delMD.items.slice(0, delItemIndex);
    let md2 = delMD.items.slice(delItemIndex + 1);

    [md1, md2].forEach(mds => {
      if (mds.length == 0) return;
      let newMD = createMDFromItems(
        mds,
        planData.sceneGraph.unit,
        planData.catalog
      );
      MDArray.push(newMD);
      addMDMesh(newMD, planData, layer);
    });
  } else {
    deleteMDMesh(delMD, planData, layer);
  }
}

export function addMolding(MDArray, addItem, planData, layer) {
  log('addMolding', [...MDArray], addItem.id);

  // Get `addItem` is corner cabinet or not
  let isCorner = planData.catalog.elements[addItem.get('type')].info.is_corner;

  let allLines = GeometryUtils.getAllLines(layer);
  let allLineRects = GeometryUtils.buildRectFromLines(layer, allLines);
  let allItemRect = GeometryUtils.getAllItems(
    planData.sceneData,
    planData.catalog,
    allLineRects
  );
  let itemRect = allItemRect.others.filter(
    element => element.itemInfo.id === addItem.id
  );
  if (itemRect.length)
    checkCabinetOverlap(
      addItem,
      itemRect[0],
      GeometryUtils.getHoleItems(layer),
      planData
    );
  let newMD = createMDFromItem(
    addItem,
    planData.sceneGraph.unit,
    planData.catalog
  );
  let oldMD = null;
  let extMD = false;
  let extMDIndex = -1;
  let tryMergeMD = function() {
    extMDIndex = MDArray.findIndex(el => {
      extMD = tryAdjacentMD(newMD, el);
      return extMD != false;
    });
    return extMDIndex >= 0;
  };
  while (tryMergeMD() && !isCorner) {
    oldMD = MDArray.splice(extMDIndex, 1)[0];
    deleteMDMesh(newMD, planData, layer);
    deleteMDMesh(oldMD, planData, layer);
    newMD = extMD;
  }

  addMDMesh(newMD, planData, layer, addItem);
  MDArray.push(newMD);
}

/**
 * Flip door handle
 * @param item Scene state item ( redux )
 * @param item3D Mesh
 * @param {Boolean} flip Determines handle position.  
 * @note When `flip` is set  
 * 
 * `true`  
 * * Left side door - use left handle (LL)
 * * Right side door - use right handle (RR)
 * 
 * `false`  
 * * Left side door - use right handle (LR)
 * * Right side door - use left handle (RL)
 * 
 * And one-door-cabinet is the same case with the left door of two-door-cabinet
*/
export function updateDoorHandleMesh(_, item3D, flip) {
  if (!item3D) {
    console.error('Item mesh is undefined.');
    return;
  }

  /**
   * Mesh whose name pass this regexp is a place holder for a door
   * which is the parent or grand parent of a door mesh and its handles
   */
  let doorReg = /ph_.*[^(drawer)]_door(?:_(L|R))?$/;
  let doorHandleReg = /ph_.*_door_.*_handle_(L|R)/;

  item3D.traverse(child => {
    /**
     * Hold the name of the place holder for door and its position
     * @type {[string,"L"|"R"|undefined]}
     */
    let doorRegResult = doorReg.exec(child.name);
    if (doorRegResult) {
      // `child` is place holder for door mesh, and also grand parent of on[0]e
      let phDoor = child.children[0];
      if (!phDoor) {
        return console.error('Group is no valid.');
      }

      let doorHandle = null;
      let phRight = null;
      let phLeft = null;

      // Find door_handle, ph_left, ph_right 3D item
      phDoor.traverse(childOfDoor => {
        /**
         * @type {[string, "L"|"R"]}
         */
        let result = doorHandleReg.exec(childOfDoor.name);
        if (result !== null) {
          // `childOfDoor` is place holder for door handle

          switch (result[1]) {
            case 'L':
              phLeft = childOfDoor;
              break;
            case 'R':
              phRight = childOfDoor;
              break;
            default:
              console.error('Place holder type should be L or R.');
              return;
          }

          if (childOfDoor.children.length !== 0) {
            // It has a door handle
            doorHandle = childOfDoor.children[0];
          }
        }
      });

      // Change door handle position
      if (doorHandle && phLeft && phRight) {
        // Remove handles for re-addition
        if (phLeft.children.length) {
          phLeft.remove(doorHandle);
        }
        if (phRight.children.length) {
          phRight.remove(doorHandle);
        }

        switch (doorRegResult[1]) {
          case undefined: // Single door cabinet
          case 'R': // Right side door
            (flip ? phRight : phLeft).add(doorHandle);
            break;
          case 'L': // Left side door
            (flip ? phLeft : phRight).add(doorHandle);
            break;
        }
      } else {
        console.error('door_handle or ph_left or ph_right is null.');
      }
    }
  });
}

export function addWarningBox(itemId, altitude, planData) {
  deleteSpecifiedMeshObjects('WarningBox' + itemId);
  let item3D =
    planData.sceneGraph.layers[planData.sceneData.selectedLayer].items[itemId];
  if (item3D == undefined) return;
  let altitudeLength = convert(altitude)
    .from('in')
    .to('cm');
  let sBounding = item3D.children[0].userData;
  let width = sBounding.max.x - sBounding.min.x;
  let height = sBounding.max.y - sBounding.min.y;
  let depth = sBounding.max.z - sBounding.min.z;
  let warnBoxGeom = new Three.BoxGeometry(width, height, depth);
  let warnBoxObj = new Three.Mesh(
    warnBoxGeom,
    new Three.MeshBasicMaterial({
      color: 0xff2200,
      opacity: 0.8,
      transparent: true,
      blending: Three.MultiplyBlending
    })
  );
  let box = new Three.BoxHelper(warnBoxObj, 0x000000);
  box.material.linewidth = 2;
  box.material.depthTest = false;
  box.renderOrder = 200;
  warnBoxObj.add(box);
  warnBoxObj.position.set(
    item3D.position.x,
    altitudeLength + item3D.position.y + height / 2,
    item3D.position.z
  );
  warnBoxObj.rotation.set(
    item3D.rotation.x,
    item3D.rotation.y,
    item3D.rotation.z
  );
  warnBoxObj.name = 'WarningBox' + itemId;
  // planData.plan.add(warnBoxObj);
}
export function checkCabinetOverlap(itemPos, itemRect, holeItems, planData) {
  if (holeItems.length) {
    let depth = itemRect.size.depth;
    let i;
    for (i = 0; i < holeItems.length; i++) {
      let hole = holeItems[i];
      let holeAlti = hole.altitude;
      if (holeAlti >= depth) continue;
      if (Math.abs(Math.sin(itemRect.rotRad)) === 1) {
        if (
          itemPos.y + itemRect.size.width / 2 >= hole.y - hole.width / 2 &&
          itemPos.y - itemRect.size.width / 2 <= hole.y + hole.width / 2 &&
          (itemRect.rotRad == 0 || itemRect.rotRad == -Math.PI / 2
            ? itemPos.x <= hole.x && itemPos.x + itemRect.size.height >= hole.x
            : itemPos.x >= hole.x && itemPos.x - itemRect.size.height <= hole.x)
        )
          break;
      } else {
        if (
          itemPos.x + itemRect.size.width / 2 >= hole.x - hole.width / 2 &&
          itemPos.x - itemRect.size.width / 2 <= hole.x + hole.width / 2 &&
          (itemRect.rotRad == 0 || itemRect.rotRad == -Math.PI / 2
            ? itemPos.y <= hole.y && itemPos.y + itemRect.size.height >= hole.y
            : itemPos.y >= hole.y && itemPos.y - itemRect.size.height <= hole.y)
        )
          break;
      }
    }
    if (i != holeItems.length) {
      let altitude = itemRect.itemInfo.properties
        .get('altitude')
        .get('_length');
      let altitudeUnit =
        itemRect.itemInfo.properties.get('altitude').get('_unit') || 'cm';
      altitude = convert(altitude)
        .from(altitudeUnit)
        .to('cm');
      addWarningBox(itemRect.itemInfo.id, altitude, planData);
    }
  }
}
