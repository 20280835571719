import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layer, Grids } from './export';

export default class Scene extends Component {

  componentWillReceiveProps(nextProps) {
    // console.log("componentDidMount", this.props.scene.hashCode(), nextProps.scene.hashCode());
    if(this.props.scene.hashCode() !== nextProps.scene.hashCode()) {
      let {scene, catalog} = nextProps;
      let {height, layers} = scene;
      let selectedLayer = layers.get(scene.selectedLayer);

      var msg = '';
      selectedLayer.items.valueSeq().forEach(item => {
        if(!catalog.elements.hasOwnProperty(item.type)) {
          if(!msg.includes(`Element ${item.type} does not exist in catalog.`)) {
            msg += (`Element ${item.type} does not exist in catalog.\n`);
          }
        }
      })
      if(msg !== '') {
        msg += `Do you want to initialize?`;
        confirm(msg)
        ? this.context.projectActions.newProject()
        : null
      }
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.scene.hashCode() !== nextProps.scene.hashCode();
  }

  render() {
    // console.log("scene", this);
    let {scene, catalog} = this.props;
    let {height, layers} = scene;
    let selectedLayer = layers.get(scene.selectedLayer);

    return (
      <g>
        <Grids scene={scene}/>

        <g style={{pointerEvents: 'none'}}>
          {
            layers
            .entrySeq()
            .filter(([layerID, layer]) => layerID !== scene.selectedLayer && layer.visible)
            .map(([layerID, layer]) => <Layer key={layerID} layer={layer} scene={scene} catalog={catalog}/>)
          }
        </g>

        <Layer key={selectedLayer.id} layer={selectedLayer} scene={scene} catalog={catalog}/>
      </g>
    );
  }
}


Scene.propTypes = {
  scene: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
};

Scene.contextTypes = {
  projectActions: PropTypes.object.isRequired,
};
