import React from 'react';
import ReviewItem from '../product/reviewItem';

import * as S from './styles';
import * as MS from '../styles';

import { ToolbarButtonWithPopup } from '../';

class ReviewQuoteToolbarButton extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { handleSaveDesign, handleSubmitForQuote, handleDesignerAssist,
      dataSource, catalog, trigger, collapse, collapseAction, isOpened, mode, onClose } = this.props;
    let content = (
      <S.Content>
        {
            dataSource.count() ? dataSource.entrySeq().map(([itemID, item]) => {
              return (
                <ReviewItem key={itemID} catalog={catalog} itemID={itemID} item={item}/>
              )
            }) : null
          }
        <div style={{padding: "10px 20px", display: 'flex', justifyContent: 'space-between'}}>
          <span><strong>Grand Total</strong></span>
          <span>{dataSource.count()}</span>
          <span>0</span>
        </div>
        <S.Control onClick={() => handleSaveDesign(true)}>
          <S.ControlIcon>
            <img width={'150px'} name={"Save Project"} src={'/assets/img/svg/toolbar/save_project_button.svg'} />
            <MS.ButtonTitle style={{top: "43%", fontSize: 12}}>
              Save Design
            </MS.ButtonTitle>
          </S.ControlIcon>
        </S.Control>
        <S.Control style={{justifyContent: 'space-between'}}>
          <S.ControlIcon onClick={() => handleSubmitForQuote(true)}>
            <img width={'150px'} name={"Email Quote"} src={'/assets/img/svg/toolbar/email_quote_button.svg'} />
            <MS.ButtonTitle style={{top: "43%", fontSize: 12}}>
              Submit for Quote
            </MS.ButtonTitle>
          </S.ControlIcon>
          <S.ControlIcon onClick={() => handleDesignerAssist(true)}>
            <img width={'150px'} name={"Consult a Designer"} src={'/assets/img/svg/toolbar/consult_a_designer_button.svg'} />
            <MS.ButtonTitle style={{top: "43%", fontSize: 12}}>
              Designer Assistance
            </MS.ButtonTitle>
          </S.ControlIcon>
        </S.Control>

      </S.Content>
    );
    return (
      <div>
        <ToolbarButtonWithPopup mode={mode} order={5} collapse={collapse} collapseAction={collapseAction} trigger={trigger} isOpened={isOpened} closeAction={onClose}>
          {content}
        </ToolbarButtonWithPopup>
      </div>
    )
  }
}


export default ReviewQuoteToolbarButton
