import * as Three from 'three';
import { OBJTYPE_MESH } from '../../../../src/constants';

export function controlGeom() {
    let controlGeom = new Three.Geometry();
    controlGeom.vertices.push(new Three.Vector3(0, 0.02, 0.55));
    controlGeom.vertices.push(new Three.Vector3(-0.08, 0, 0.5));
    controlGeom.vertices.push(new Three.Vector3(-0.05, 0, 0.5));
    controlGeom.vertices.push(new Three.Vector3(-0.05, 0, -0.5));
    controlGeom.vertices.push(new Three.Vector3(-0.08, 0, -0.5));
    controlGeom.vertices.push(new Three.Vector3(0, 0.02, -0.55));
    controlGeom.vertices.push(new Three.Vector3(0.08, 0, -0.5));
    controlGeom.vertices.push(new Three.Vector3(0.05, 0, -0.5));
    controlGeom.vertices.push(new Three.Vector3(0.05, 0, 0.5));
    controlGeom.vertices.push(new Three.Vector3(0.08, 0, 0.5));
    controlGeom.vertices.push(new Three.Vector3(0, 0.02, 0.55));
    controlGeom.vertices.push(new Three.Vector3(-0.08, 0.04, 0.5));
    controlGeom.vertices.push(new Three.Vector3(-0.05, 0.04, 0.5));
    controlGeom.vertices.push(new Three.Vector3(-0.05, 0.04, -0.5));
    controlGeom.vertices.push(new Three.Vector3(-0.08, 0.04, -0.5));
    controlGeom.vertices.push(new Three.Vector3(0, 0.02, -0.55));
    controlGeom.vertices.push(new Three.Vector3(0.08, 0.04, -0.5));
    controlGeom.vertices.push(new Three.Vector3(0.05, 0.04, -0.5));
    controlGeom.vertices.push(new Three.Vector3(0.05, 0.04, 0.5));
    controlGeom.vertices.push(new Three.Vector3(0.08, 0.04, 0.5));
    controlGeom.vertices.push(new Three.Vector3(0, 0.02, 0.55));
    controlGeom.faces.push(new Three.Face3(0, 1, 9));
    controlGeom.faces.push(new Three.Face3(4, 5, 6));
    controlGeom.faces.push(new Three.Face3(2, 3, 8));
    controlGeom.faces.push(new Three.Face3(3, 7, 8));
    controlGeom.faces.push(new Three.Face3(10, 11, 19));
    controlGeom.faces.push(new Three.Face3(14, 15, 16));
    controlGeom.faces.push(new Three.Face3(12, 13, 18));
    controlGeom.faces.push(new Three.Face3(13, 17, 18));
    controlGeom.faces.push(new Three.Face3(10, 1, 11));
    controlGeom.faces.push(new Three.Face3(10, 9, 19));
    controlGeom.faces.push(new Three.Face3(1, 2, 11));
    controlGeom.faces.push(new Three.Face3(2, 12, 11));
    controlGeom.faces.push(new Three.Face3(8, 9, 18));
    controlGeom.faces.push(new Three.Face3(9, 18, 19));
    controlGeom.faces.push(new Three.Face3(12, 2, 3));
    controlGeom.faces.push(new Three.Face3(12, 3, 13));
    controlGeom.faces.push(new Three.Face3(17, 7, 8));
    controlGeom.faces.push(new Three.Face3(17, 8, 18));
    controlGeom.faces.push(new Three.Face3(5, 4, 14));
    controlGeom.faces.push(new Three.Face3(15, 6, 16));
    
    return controlGeom;
}

export function upwardsGeom() {
    let upwardsGeom = new Three.Geometry();
    upwardsGeom.vertices.push(new Three.Vector3(0.05, 0, 0.05));
    upwardsGeom.vertices.push(new Three.Vector3(0.05, 0.3, 0.05));
    upwardsGeom.vertices.push(new Three.Vector3(0.07, 0.3, 0.05));
    upwardsGeom.vertices.push(new Three.Vector3(0, 0.4, 0.05));
    upwardsGeom.vertices.push(new Three.Vector3(-0.07, 0.3, 0.05));
    upwardsGeom.vertices.push(new Three.Vector3(-0.05, 0.3, 0.05));
    upwardsGeom.vertices.push(new Three.Vector3(-0.05, 0, 0.05));
    upwardsGeom.vertices.push(new Three.Vector3(0.05, 0, 0.05));
    upwardsGeom.vertices.push(new Three.Vector3(0.05, 0, -0.05));
    upwardsGeom.vertices.push(new Three.Vector3(0.05, 0.3, -0.05));
    upwardsGeom.vertices.push(new Three.Vector3(0.07, 0.3, -0.05));
    upwardsGeom.vertices.push(new Three.Vector3(0, 0.4, -0.05));
    upwardsGeom.vertices.push(new Three.Vector3(-0.07, 0.3, -0.05));
    upwardsGeom.vertices.push(new Three.Vector3(-0.05, 0.3, -0.05));
    upwardsGeom.vertices.push(new Three.Vector3(-0.05, 0, -0.05));
    upwardsGeom.vertices.push(new Three.Vector3(0.05, 0, -0.05));
    upwardsGeom.faces.push(new Three.Face3(0, 1, 5));
    upwardsGeom.faces.push(new Three.Face3(0, 5, 6));
    upwardsGeom.faces.push(new Three.Face3(2, 3, 4));
    upwardsGeom.faces.push(new Three.Face3(0, 1, 8));
    upwardsGeom.faces.push(new Three.Face3(1, 8, 9));
    upwardsGeom.faces.push(new Three.Face3(1, 2, 9));
    upwardsGeom.faces.push(new Three.Face3(2, 9, 10));
    upwardsGeom.faces.push(new Three.Face3(2, 3, 10));
    upwardsGeom.faces.push(new Three.Face3(3, 10, 11));
    upwardsGeom.faces.push(new Three.Face3(3, 4, 11));
    upwardsGeom.faces.push(new Three.Face3(4, 11, 12));
    upwardsGeom.faces.push(new Three.Face3(4, 5, 12));
    upwardsGeom.faces.push(new Three.Face3(4, 12, 13));
    upwardsGeom.faces.push(new Three.Face3(5, 6, 13));
    upwardsGeom.faces.push(new Three.Face3(6, 13, 14));
    upwardsGeom.faces.push(new Three.Face3(6, 7, 14));
    upwardsGeom.faces.push(new Three.Face3(7, 14, 15));
    upwardsGeom.faces.push(new Three.Face3(8, 9, 13));
    upwardsGeom.faces.push(new Three.Face3(8, 13, 14));
    upwardsGeom.faces.push(new Three.Face3(10, 11, 12));

    return upwardsGeom;
}

export function rotShape(radius) {
    let rotShape = new Three.Shape();

    rotShape.moveTo(Math.cos(-60 / 180 * Math.PI) * (radius - 0.05), Math.sin(-60 / 180 * Math.PI) * (radius - 0.05));
    for (let i = -55; i <= 60; i += 5) {
    rotShape.lineTo(Math.cos(i / 180 * Math.PI) * (radius - 0.05), Math.sin(i / 180 * Math.PI) * (radius - 0.05));
    }
    for (let i = 60; i >= -60; i -= 5) {
        if (i === 5) {
            rotShape.lineTo(Math.cos(i / 180 * Math.PI) * (radius + 0.05), Math.sin(i / 180 * Math.PI) * (radius + 0.05));
            rotShape.lineTo(Math.cos(i / 180 * Math.PI) * (radius + 0.05) + 0.15, Math.sin(i / 180 * Math.PI) * (radius + 0.05));
            rotShape.lineTo(Math.cos(i / 180 * Math.PI) * (radius + 0.05) + 0.15, Math.sin(i / 180 * Math.PI) * (radius + 0.05) + 0.1);
            continue;
        } else if(i === 0) {
            rotShape.lineTo(Math.cos(i / 180 * Math.PI) * (radius + 0.05) + 0.25, Math.sin(i / 180 * Math.PI) * (radius + 0.05));
            continue;
        } else if(i === -5) {
            rotShape.lineTo(Math.cos(i / 180 * Math.PI) * (radius + 0.05) + 0.15, Math.sin(i / 180 * Math.PI) * (radius + 0.05) - 0.1);
            rotShape.lineTo(Math.cos(i / 180 * Math.PI) * (radius + 0.05) + 0.15, Math.sin(i / 180 * Math.PI) * (radius + 0.05));
            rotShape.lineTo(Math.cos(i / 180 * Math.PI) * (radius + 0.05), Math.sin(i / 180 * Math.PI) * (radius + 0.05));
            continue;
        }
        rotShape.lineTo(Math.cos(i / 180 * Math.PI) * (radius + 0.05), Math.sin(i / 180 * Math.PI) * (radius + 0.05));
    }
    rotShape.lineTo(Math.cos(-60 / 180 * Math.PI) * (radius + 0.1), Math.sin(-60 / 180 * Math.PI) * (radius + 0.1));
    rotShape.lineTo(Math.cos(-70 / 180 * Math.PI) * (radius + 0.0), Math.sin(-70 / 180 * Math.PI) * (radius + 0.0));
    rotShape.lineTo(Math.cos(-60 / 180 * Math.PI) * (radius - 0.1), Math.sin(-60 / 180 * Math.PI) * (radius - 0.1));
    rotShape.lineTo(Math.cos(-60 / 180 * Math.PI) * (radius - 0.05), Math.sin(-60 / 180 * Math.PI) * (radius - 0.05));

    return rotShape;
}

export function rotGeoms(radius) {
    if (!Number.isFinite(radius)) radius = 1;
    let rotShape = this.rotShape(radius);
    let rotFill = new Three.ShapeBufferGeometry(rotShape);
    let rotStroke = new Three.BufferGeometry().setFromPoints(rotShape.getPoints());
    rotFill.rotateX(Math.PI / 2);
    rotStroke.rotateX(Math.PI / 2);
    return {rotFill, rotStroke};
}

// export function rotMesh(radius) {
//     let rotShape = rotShape(radius);

//     let rotGeom = new Three.ShapeBufferGeometry(rotShape);

    

//     const points = rotShape.getPoints();
//     const spacedPoints = shape.getSpacedPoints( 50 );

//     const geometryPoints = new THREE.BufferGeometry().setFromPoints( points );
//     const geometrySpacedPoints = new THREE.BufferGeometry().setFromPoints( spacedPoints );

//     // solid line

//     let line = new THREE.Line( geometryPoints, new THREE.LineBasicMaterial( { color: color } ) );
//     line.position.set( x, y, z - 25 );
//     line.rotation.set( rx, ry, rz );
//     line.scale.set( s, s, s );
//     group.add( line );

// }

export function baseBoxHelper(object, color) {
    let tempobj = [];
    for (let j = object.children.length - 1; j >= 0; j--) {
        if (object.children[j].type !== OBJTYPE_MESH) {
            tempobj.push(object.children[j]);
            object.remove(object.children[j]);
        }
    }
    let boxHelper = new Three.BoxHelper(object, color);
    tempobj.forEach((v) => {
        object.add(v);
    })
    return boxHelper;
}

export function baseBox3FromObject(object) {
    let tempobj = [];
    for (let j = object.children.length - 1; j >= 0; j--) {
        if (object.children[j].type !== OBJTYPE_MESH || object.children[j].name.includes('_countertop')) {
            tempobj.push(object.children[j]);
            object.remove(object.children[j]);
        }
    }
    let box3 = new Three.Box3().setFromObject(object);
    tempobj.forEach((v) => {
        object.add(v);
    })
    return box3;
}

export function emptyBoxHolder(newWidth, newHeight, newDepth) {
    const scale = 2.54/100;
    const geometry = new Three.BoxBufferGeometry( newWidth * scale, newHeight * scale, newDepth * scale );
    const material = new Three.MeshBasicMaterial( {color: 0x00ff00, opacity: 0.2, transparent: true} );
    const cube = new Three.Mesh( geometry, material );
    return cube;
}