import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as S from './button/styles';
import ControlButton from './button/ControlButton';
import DirectionButton from './button/DirectionButton';
import DirectionPanSpinButton from './button/DirectionPanSpinButton';
import ToggleButton from './button/ToggleButton';
import ToggleMeasureButton from './button/ToggleMeasureButton';
import * as Icon from 'react-icons/fa';
import { zoom } from 'react-svg-pan-zoom';
import { GeometryUtils } from '../../utils/export';
import * as PS from '../toolbar/popup/styles';
import { FaTimes } from 'react-icons/fa/index';
import { toFixedFloat } from '../../utils/math';
import convert from 'convert-units';
import { FormLabel, FormNumberInput, FormSelect } from '../../components/style/export';
import { CEIL_UNITS_LENGTH } from '../../constants';

import {
  MODE_IDLE,
  MODE_3D_VIEW,
  MODE_IDLE_3D,
  MODE_ROTATING_ITEM_3D,
  MODE_DRAGGING_ITEM_3D,
  MODE_DRAWING_ITEM_3D,
} from '../../constants';
import ItemAttributesEditor from '../sidebar/panel-element-editor/attributes-editor/item-attributes-editor';

let allItemRect;
let allItemSnap;
let allLines;
let allLineRects;
let allLineSnap;
let allRect;

const internalTableStyle = { borderCollapse: 'collapse' };
const secondTdStyle = { padding: 0 };
const unitContainerStyle = { width: '5em' };
const firstTdStyle = {width: '6em', textTransform:'capitalize'};
const tableStyle = {width: '100%', borderSpacing: '2px 0', marginBottom: '2px'};

export default class FooterBar extends Component {
  constructor(props, context) {
    super(props, context);
    let selectedLayout = props.state.scene.selectedLayer;
    let height = props.state.scene.layers.get(selectedLayout).ceilHeight;
    let unit = props.state.scene.layers.get(selectedLayout).unit;
    this.state = {
      objectSetting: null,
      controlButton: "",
      panButton:"",
      toggleUnit: 'in',
      dragFlag: true,
      unit: unit,
      ceilFlag: false,
      ceil3DFlag: false,
      height: height
    };
  }
  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    let selectedLayout = nextProps.state.scene.selectedLayer;
    let height = nextProps.state.scene.layers.get(selectedLayout).ceilHeight;
    let unit = nextProps.state.scene.layers.get(selectedLayout).unit;
    this.setState({ height: height });
    this.setState({ unit: unit });
  }

  render() {
    let { state: globalState, content } = this.props;

    let layer = globalState.scene.layers.get(globalState.scene.selectedLayer);
    let selectedItems = [];
    if ( layer.selected.items.size > 0 ) {
      selectedItems = layer.selected.items.toJS();
    }
    let { projectActions, viewer3DActions, viewer2DActions, itemsActions, catalog,} = this.context;
    let { controlButton: control, panButton: pan_control, toggleUnit, dragFlag } = this.state;
    // let { x, y } = globalState.get('mouse').toJS();
    // let zoom = globalState.get('zoom');
    let mode = globalState.get('mode');
    let scene = globalState.get('scene');

    let update = (LengthInput, unitInput, type) => {
      
      let newLength = toFixedFloat(LengthInput);

      if(type === 0) {
        this.setState({ height: newLength });
        viewer2DActions.updateCeilHeight(newLength);
        viewer2DActions.updateCeilHeightUnit(unitInput);
        viewer3DActions.update3DCeilHeight(newLength);
        viewer3DActions.update3DCeilHeightUnit(unitInput);
      } else {
        this.setState({ height: convert(newLength).from(this.state.unit).to(unitInput) });
        this.setState({ unit: unitInput, });
        viewer2DActions.updateCeilHeight(convert(newLength).from(this.state.unit).to(unitInput));
        viewer2DActions.updateCeilHeightUnit(unitInput);
        viewer3DActions.update3DCeilHeightUnit(unitInput);
        viewer3DActions.update3DCeilHeight(convert(newLength).from(this.state.unit).to(unitInput));
      }
    };

    let prepareSnap = () => {
      allItemRect = GeometryUtils.getAllItems(scene, catalog)
      allItemSnap = GeometryUtils.getAllItemSnap(allItemRect);
      allLines = GeometryUtils.getAllLines(layer);
      allLineRects = GeometryUtils.buildRectFromLines(layer, allLines);
      allLineSnap = GeometryUtils.getAllLineSnap(allLineRects, allItemRect.cur);
      allRect = allItemRect.others.concat(allLineRects);
      allItemSnap = GeometryUtils.validateSnaps(allItemSnap, allRect);
      allLineSnap = GeometryUtils.validateSnaps(allLineSnap, allRect);
    };

    function spinDragStart(key) {
        if(dragFlag) {
          window.interval = setInterval(() => {
            window.SPKeyDown({ keyCode: key });
          },50);
        }
    }

    function spinDragStop() {
        clearInterval(window.interval);
    }

    function panDragStart(key) {
      if(dragFlag) {
        window.interval = setInterval(() => {
          window.tDKeyDown({ keyCode: key });
        },50);
      }
    }

    function panDragStop() {
        clearInterval(window.interval);
    }

    function validOutSVG(x, y) {
      const { SVGWidth, SVGHeight } = globalState.get('viewer2D').toJS();
      if( x <0 || x > SVGWidth || y < 0 || y > SVGHeight)
        return false;
      return true;
    }
    let footerview = ![MODE_3D_VIEW, MODE_IDLE_3D, MODE_ROTATING_ITEM_3D, MODE_DRAGGING_ITEM_3D, MODE_DRAWING_ITEM_3D].includes(mode) ? [
      <div
        key={"section"}
        style={{
          display: "flex",
          position:"absolute",
          right: content/2-205,
          bottom: 35,
          flexFlow: 'column',
          flexDirection: 'column',
        }}
      >
        <div
          style = {{
            marginBottom: '30px',
            alignSelf: 'flex-end',
          }}
        >
          {
            this.state.ceilFlag != true ? '' :
              <PS.SelectedWrapper>
                <PS.TitleBar>
                  <PS.Title>Setting</PS.Title>
                  <PS.TitleBarIconButton onClick={() => { this.setState({ ceilFlag: false })}}>
                    <PS.IconButton><FaTimes /></PS.IconButton>
                  </PS.TitleBarIconButton>
                </PS.TitleBar>
                <PS.ContentObjectWrapper>
                  <table className="PropertyLengthMeasure" style={tableStyle}>
                    <tbody>
                      <tr>
                        <td style={firstTdStyle}><FormLabel>Ceiling Height</FormLabel></td>
                        <td style={secondTdStyle}>
                          <table style={internalTableStyle}>
                            <tbody>
                              <tr>
                                <td>
                                  <FormNumberInput
                                    value = {this.state.height}
                                    onChange = { event => update(event.target.value, this.state.unit, 0) }
                                  />
                                </td>
                                <td style={unitContainerStyle}>
                                  <FormSelect
                                    value = { this.state.unit }
                                    onChange={ event => update(this.state.height, event.target.value, 1) }
                                  >
                                    {
                                      CEIL_UNITS_LENGTH.map(el => <option key={el} value={el}>{el}</option>)
                                    }
                                  </FormSelect>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </PS.ContentObjectWrapper>
              </PS.SelectedWrapper>
          }
        </div>

        <div
          style = {{
            display: "flex",
            position: 'relative',
            // right: content/2-205,
            bottom: "20px",
            cursor: "pointer",
            // zIndex: "10",
          }}
        >
          <ToggleButton
            key={'2d3d toggle'}
            active={control === '2d3d toggle'}
            settingMode = {mode}
            backgroundImage={<img src={'/assets/img/svg/bottombar/2d3d_toggle.svg'} style={{'width': '66px', 'height': '28px' }}/>}
            onClick={() =>{
              this.setState({ controlButton: '2d3d toggle' });
              projectActions.setMode(MODE_IDLE_3D);
              let layer = globalState.scene.getIn(["layers", globalState.scene.selectedLayer]);
              let sCount = layer.selected.areas.size + layer.selected.holes.size + layer.selected.items.size + layer.selected.lines.size;
              if (sCount > 1)
                projectActions.unselectAll();
              this.setState({ ceilFlag: false });
            }}
            icon={<img src={'/assets/img/svg/bottombar/2d3d_button.svg'} style={{'width': '22px', 'height': '22px' }} />}
          />
          <ControlButton
            key={'Zoom Out'}
            tooltip={'Zoom Out'}
            active={control === 'Zoom Out'}
            onClick={() => {
              this.setState({ controlButton: 'Zoom Out' });
              let value = globalState.get('viewer2D').toJS();
              let width = value.viewerWidth;
              let height = value.viewerHeight;
              let len = value.SVGWidth;
              let _e = (width - len * value.a) / 2;
              let _f = (height - len * value.d) / 2;
              let offsetE = (value.e - _e) / value.a;
              let offsetF = (value.f - _f) / value.a;
              value.a -= 0.1; value.d -= 0.1;
              value.e -= (value.SVGWidth * value.a - value.SVGWidth * (value.a + 0.1)) / 2 ;
              value.f -= (value.SVGHeight * value.d - value.SVGHeight * (value.d + 0.1)) / 2;
              // value = zoom(value, 0, 0, 1/1.06)
              if(value.e <= 10 && value.e + value.a * value.SVGWidth + 10 >= value.viewerWidth && value.f <= 80 && value.f + value.d * value.SVGHeight + 10 >= value.viewerHeight) {
                viewer2DActions.updateCameraView(value);
              }
            }}
            icon={<img src={'/assets/img/svg//bottombar/zoomout.svg'} style={{ 'width': '28px', 'height': '28px' }} />}  bottom={'60px'}
          />
          <ControlButton
            key={'Zoom In'}
            tooltip={'Zoom In'}
            active={control === 'Zoom In'}
            onClick={() => {
              this.setState({ controlButton: 'Zoom In' });
              let value = globalState.get('viewer2D').toJS();
              let width = value.viewerWidth;
              let height = value.viewerHeight;
              let len = value.SVGWidth;
              let _e = (width - len * value.a) / 2;
              let _f = (height - len * value.d) / 2;
              let offsetE = (value.e - _e) / value.a;
              let offsetF = (value.f - _f) / value.a;
              value.a += 0.1; value.d += 0.1;
              value.e -= (value.SVGWidth * value.a - value.SVGWidth * (value.a - 0.1)) / 2;
              value.f -= (value.SVGHeight * value.d - value.SVGHeight * (value.d - 0.1)) / 2;
              // value = zoom(value, 0, 0, 1.06)
              if(value.e <= 10 && value.e + value.a * value.SVGWidth + 10 >= value.viewerWidth && value.f <= 80 && value.f + value.d * value.SVGHeight + 10 >= value.viewerHeight) {
                viewer2DActions.updateCameraView(value);
              }
            }}
            icon={<img src={'/assets/img/svg/bottombar/zoomin.svg'} style={{ 'width': '28px', 'height': '28px' }} />}
            bottom={'95px'}
          />
          <S.Direction >
            <svg width="59px" height="59px" style={{ background:'#dadcde', boxShadow:' 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.39), 0px 1px 18px 0px rgba(0,0,0,0.12)', borderRadius: '50%'}} version="1.1">
              <defs>
              </defs>
              <g id="design" style={{ transform: 'scale(0.4)'}}>
                <metadata id="CorelCorpID_0Corel-Layer"/>
                <g id="_1995302324176">
                <path style={{fill:'white'}} d="M73.08 0c18.55,0 35.48,6.91 48.36,18.3l-48.36 48.36 -48.36 -48.36c12.89,-11.39 29.82,-18.3 48.36,-18.3zm54.79 24.72c11.38,12.89 18.3,29.81 18.3,48.36 0,18.55 -6.91,35.47 -18.3,48.36l-48.36 -48.36 48.36 -48.36zm-6.42 103.14c-12.89,11.39 -29.82,18.3 -48.36,18.3 -18.55,0 -35.47,-6.91 -48.36,-18.3l48.36 -48.36 48.36 48.36zm-103.15 -6.42c-11.39,-12.89 -18.3,-29.82 -18.3,-48.36 0,-18.55 6.91,-35.47 18.3,-48.36l48.36 48.36 -48.36 48.36z"/>
                </g>
              </g>
            </svg>
            <DirectionButton key={'Right'}
              active={control === 'Right'}
              tooltip={'Right'}
              style={{ left: '207px' }}
              onClick={() => {
                  this.setState({ controlButton: 'Right' });
                  // if(selectedItems.length === 1) {

                  //   prepareSnap();
                  //   const {x, y} = layer.getIn(['items', selectedItems[0]]).toJS()
                  //   var {nx, ny, rot, rotRad} =  GeometryUtils.calcSnap(allItemRect, allItemSnap, allLineRects, allLineSnap, allRect, x + 20, y);
                  //   let val = {pos:{x:nx, y: ny}, rotRad:rotRad};
                  //   val.size = allItemRect.cur.size;
                  //   val.layoutpos = allItemRect.cur.layoutpos;
                  //   let isSect = GeometryUtils.getAllHoleRect(scene, val);
                  //   let isrectSect = GeometryUtils.validInterSect(allItemRect.others, val);
                  //   if(isrectSect && isSect && validOutSVG(nx, ny)) {
                  //     // itemsActions.updateItemPosition(layer.getIn(['id']), selectedItems[0], {x: nx, y: ny})
                  //     itemsActions.updateDraggingItemChanged(nx, ny, layer.getIn(['id']), selectedItems[0]);
                  //     itemsActions.updateRotatingItemChanged(rot, layer.getIn(['id']), selectedItems[0]);
                  //   }
                  // }
                  let value = globalState.get('viewer2D').toJS();
                  value.e += 5;
                  if(value.e <= 10 && value.e + value.a * value.SVGWidth + 10 >= value.viewerWidth && value.f <= 80 && value.f + value.d * value.SVGHeight + 10 >= value.viewerHeight) {
                    viewer2DActions.updateCameraView(value);
                  }
                }}
              icon={<Icon.FaChevronRight style={{marginTop:'3px'}} />}
            />
            <DirectionButton key={'Left'}
              active={control === 'Left'}
              tooltip={'Left'}
              style={{ left: '171px' }}
              onClick={() => {
                  this.setState({ controlButton: 'Left' });
                  // if(selectedItems.length === 1) {
                  //   prepareSnap();
                  //   const {x, y} = layer.getIn(['items', selectedItems[0]]).toJS()
                  //   var {nx, ny, rot, rotRad} =  GeometryUtils.calcSnap(allItemRect, allItemSnap, allLineRects, allLineSnap, allRect, x - 20, y);
                  //   let val = {pos:{x:nx, y: ny}, rotRad:rotRad};
                  //   val.size = allItemRect.cur.size;
                  //   val.layoutpos = allItemRect.cur.layoutpos;
                  //   let isSect = GeometryUtils.getAllHoleRect(scene, val);
                  //   let isrectSect = GeometryUtils.validInterSect(allItemRect.others, val);
                  //   if(isrectSect && isSect && validOutSVG(nx, ny)) {
                  //     // itemsActions.updateItemPosition(layer.getIn(['id']), selectedItems[0], {x: nx, y: ny})
                  //     itemsActions.updateDraggingItemChanged(nx, ny, layer.getIn(['id']), selectedItems[0]);
                  //     itemsActions.updateRotatingItemChanged(rot, layer.getIn(['id']), selectedItems[0]);
                  //   }
                  // }
                  let value = globalState.get('viewer2D').toJS();
                  value.e -= 5;
                  if(value.e <= 10 && value.e + value.a * value.SVGWidth + 10 >= value.viewerWidth && value.f <= 80 && value.f + value.d * value.SVGHeight + 10 >= value.viewerHeight) {
                    viewer2DActions.updateCameraView(value);
                  }
                }}
              icon={<Icon.FaChevronLeft style={{marginTop:'3px'}} />}
            />
            <DirectionButton key={'Top'}
              active={control === 'Top'}
              tooltip={'Top'}
              style={{ top: '0px' }}
              onClick={() => {
                  this.setState({ controlButton: 'Top' });
                  // if(selectedItems.length === 1) {
                  //   prepareSnap();
                  //   const {x, y} = layer.getIn(['items', selectedItems[0]]).toJS()
                  //   var {nx, ny, rot, rotRad} =  GeometryUtils.calcSnap(allItemRect, allItemSnap, allLineRects, allLineSnap, allRect, x, y + 20);
                  //   let val = {pos:{x:nx, y: ny}, rotRad:rotRad};
                  //   val.size = allItemRect.cur.size;
                  //   val.layoutpos = allItemRect.cur.layoutpos;
                  //   let isSect = GeometryUtils.getAllHoleRect(scene, val);
                  //   let isrectSect = GeometryUtils.validInterSect(allItemRect.others, val);
                  //   if(isrectSect && isSect && validOutSVG(nx, ny)) {
                  //     // itemsActions.updateItemPosition(layer.getIn(['id']), selectedItems[0], {x: nx, y: ny})
                  //     itemsActions.updateDraggingItemChanged(nx, ny, layer.getIn(['id']), selectedItems[0]);
                  //     itemsActions.updateRotatingItemChanged(rot, layer.getIn(['id']), selectedItems[0]);
                  //   }
                  // }
                  let value = globalState.get('viewer2D').toJS();
                  value.f -= 5;
                  if(value.e <= 10 && value.e + value.a * value.SVGWidth + 10 >= value.viewerWidth && value.f <= 80 && value.f + value.d * value.SVGHeight + 10 >= value.viewerHeight) {
                    viewer2DActions.updateCameraView(value);
                  }
                }}
              icon={<Icon.FaChevronUp style={{marginTop:'3px'}} />}
            />
            <DirectionButton key={'Bottom'}
              active={control === 'Bottom'}
              tooltip={'Bottom'}
              style={{ bottom: '0px' }}
              onClick={() => {
                  this.setState({ controlButton: 'Bottom' });
                  // if(selectedItems.length === 1) {
                  //   prepareSnap();
                  //   const {x, y} = layer.getIn(['items', selectedItems[0]]).toJS()
                  //   var {nx, ny, rot, rotRad} =  GeometryUtils.calcSnap(allItemRect, allItemSnap, allLineRects, allLineSnap, allRect, x, y - 20);
                  //   let val = {pos:{x:nx, y: ny}, rotRad:rotRad};
                  //   val.size = allItemRect.cur.size;
                  //   val.layoutpos = allItemRect.cur.layoutpos;
                  //   let isSect = GeometryUtils.getAllHoleRect(scene, val);
                  //   let isrectSect = GeometryUtils.validInterSect(allItemRect.others, val);
                  //   if(isrectSect && isSect && validOutSVG(nx, ny)) {
                  //     // itemsActions.updateItemPosition(layer.getIn(['id']), selectedItems[0], {x: nx, y: ny})
                  //     itemsActions.updateDraggingItemChanged(nx, ny, layer.getIn(['id']), selectedItems[0]);
                  //     itemsActions.updateRotatingItemChanged(rot, layer.getIn(['id']), selectedItems[0]);
                  //   }
                  // }
                  let value = globalState.get('viewer2D').toJS();
                  value.f += 5;
                  if(value.e <= 10 && value.e + value.a * value.SVGWidth + 10 >= value.viewerWidth && value.f <= 80 && value.f + value.d * value.SVGHeight + 10 >= value.viewerHeight) {
                    viewer2DActions.updateCameraView(value);
                  }
                }}
              icon={<Icon.FaChevronDown style={{marginTop:'3px'}} />}
            />
          </S.Direction>
          <ControlButton
            key={'Settings'}
            tooltip={'Settings'}
            active={control === 'Settings'}
            onClick={() => {
              this.setState({ controlButton: 'Settings' });
              console.log('settings');
              this.setState({ ceilFlag: true });
              }}
            icon={ <img src={'/assets/img/svg/bottombar/settings.svg'} style={{ 'width': '28px', 'height': '28px' }}/> }
          />
          <ToggleMeasureButton
            key={'S/H Measure'}
            active={control === 'show/hide toggle measure '}
            settingMode = {mode}
            state = {globalState}
            backgroundImage={<img src={'/assets/img/svg/bottombar/2d3d_toggle.svg'} style={{'width': '66px', 'height': '28px' }}/>}
            onClick={() =>{
              if (!this.props.layers.get(this.props.selectedLayer).areas.size){
                alert("No Floor plan");
                return;
              }
              this.setState({ controlButton: 'measure toggle' });
              viewer2DActions.changeMeasure(1 - globalState.scene.get('showMeasure'));
            }}
            icon={<img src={'/assets/img/svg/bottombar/2d3d_button.svg'} style={{'width': '22px', 'height': '22px' }} />}
          />
            {/* <ToggleConvertButton
            key={'incm toggle'}
            active={control === 'incm toggle'}
            settingMode = {scene.rulerUnit}
            backgroundImage={<img src={'/assets/img/svg/bottombar/2d3d_toggle.svg'} style={{'width': '66px', 'height': '28px' }}/>}
            onClick={() =>{
              if(scene.rulerUnit === 'in')
                projectActions.setProjectProperties({rulerUnit: 'cm'});
              else
                projectActions.setProjectProperties({rulerUnit: 'in'});
            }}
            icon={<img src={'/assets/img/svg/bottombar/2d3d_button.svg'} style={{'width': '22px', 'height': '22px' }} />}
          /> */}
        </div>
      </div>
    ]:[
      <div key={"section"}
        style={{
          display: "flex",
          position:"absolute",
          right: content/2 + 49,
          bottom: "35px",
          // cursor: "pointer",
          // zIndex: "10",
          flexFlow: 'column',
          flexDirection: 'column',
        }}
      >
        <div
          style = {{
            display: 'flex',
            position: 'absolute',
            left: '380px',
            bottom: '60px'
          }}
        >
          {
            this.state.ceil3DFlag != true ? '' :
              <PS.SelectedWrapper>
                <PS.TitleBar>
                  <PS.Title>Setting</PS.Title>
                  <PS.TitleBarIconButton onClick={() => { this.setState({ ceil3DFlag: false })}}>
                    <PS.IconButton><FaTimes /></PS.IconButton>
                  </PS.TitleBarIconButton>
                </PS.TitleBar>
                <PS.ContentObjectWrapper>
                  <table className="PropertyLengthMeasure" style={tableStyle}>
                    <tbody>
                      <tr>
                        <td style={firstTdStyle}><FormLabel>Ceiling Height</FormLabel></td>
                        <td style={secondTdStyle}>
                          <table style={internalTableStyle}>
                            <tbody>
                              <tr>
                                <td>
                                  <FormNumberInput
                                    value = {this.state.height}
                                    onChange = { event => update(event.target.value, this.state.unit, 0) }
                                  />
                                </td>
                                <td style={unitContainerStyle}>
                                  <FormSelect
                                    value = { this.state.unit }
                                    onChange={ event => update(this.state.height, event.target.value, 1) }
                                  >
                                    {
                                      CEIL_UNITS_LENGTH.map(el => <option key={el} value={el}>{el}</option>)
                                    }
                                  </FormSelect>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </PS.ContentObjectWrapper>
              </PS.SelectedWrapper>
          }
        </div>

        <div
          style = {{
            display: "flex",
            position: "relative",
            cursor: "pointer",
            zIndex: "10",
          }}
        >

          <ToggleButton
            key={'2d3d toggle'}
            active={control === '2d3d toggle'}
            settingMode = {mode}
            onClick={() =>{
              this.setState({ controlButton: '2d toggle' });
              projectActions.setMode(MODE_IDLE);
              let layer = globalState.scene.getIn(["layers", globalState.scene.selectedLayer]);
              let sCount = layer.selected.areas.size + layer.selected.holes.size + layer.selected.items.size + layer.selected.lines.size;
              if (sCount > 1)
                projectActions.unselectAll();
              this.setState({ ceil3DFlag: false });
              }
            }
            backgroundImage={<img src={'/assets/img/svg/bottombar/2d3d_toggle.svg'} style={{'width': '66px', 'height': '28px' }}/>}
            icon={<img src={'/assets/img/svg/bottombar/2d3d_button.svg'} style={{'width': '22px', 'height': '22px' }} />} 
          />

          <S.Direction key={'pan'}>
            <DirectionButton key={'pan'}
              active={pan_control === 'pan'}
              style={{ left: '85px', bottom:'20px', cursor: 'default'}}
              icon={<img src={'/assets/img/svg/bottombar/pan_1.svg'} style={{ 'width': '177px'}}/>}
            />
            <DirectionPanSpinButton key={'Left'}
              active={pan_control === 'Left'}
              style={{ left: '85px', bottom: '-25px', borderBottomLeftRadius:'20%'}}
              tooltip={'Left'}
              onMouseDown={() => {
                window.tDKeyDown({ keyCode: 37 });//Up key
                panDragStart(37);
                this.setState({ panButton: 'Left', dragFlag:false });
                
                document.addEventListener('mouseup', () => {
                  panDragStop();
                  this.setState({ panButton: 'Left', dragFlag:true });
                }, { once: true });
              }}
              icon={<img src={'/assets/img/svg/bottombar/pan_2.svg'} style={{ 'height': '40px', borderBottomLeftRadius:'20%'}} draggable={false} />}
            />
            <DirectionPanSpinButton key={'Right'}
              active={pan_control === 'Right'}
              tooltip={'Right'}
              style={{ left: '131px', bottom: '-25px' }}
              onMouseDown={() => {
                window.tDKeyDown({ keyCode: 39 });//Up key
                panDragStart(39);
                this.setState({ panButton: 'Right', dragFlag:false });

                document.addEventListener('mouseup', () => {
                  panDragStop();
                  this.setState({ panButton: 'Right', dragFlag:true });
                }, { once: true });
              }}
              icon={<img src={'/assets/img/svg/bottombar/pan_3.svg'} style={{ 'height': '40px'}} draggable={false} />}
            />
            <DirectionPanSpinButton key={'Top'}
              active={pan_control === 'Top'}
              tooltip={'Up'}
              style={{ left: '176px', bottom: '-25px'  }}
              onMouseDown={() => {
                window.tDKeyDown({ keyCode: 38 });//Up key
                panDragStart(38);
                this.setState({ panButton: 'Top', dragFlag:false });

                document.addEventListener('mouseup', () => {
                  panDragStop();
                  this.setState({ panButton: 'Top', dragFlag:true });
                }, { once: true });
              }}
              icon={<img src={'/assets/img/svg/bottombar/pan_4.svg'} style={{ 'height': '40px'}} draggable={false} />}
            />
            <DirectionPanSpinButton key={'Bottom'}
              active={pan_control === 'Bottom'}
              tooltip={'Down'}
              style={{ left: '221px', bottom: '-25px',  borderBottomRightRadius:'20%'  }}
              onMouseDown={() => {
                window.tDKeyDown({ keyCode: 40 });//Up key
                panDragStart(40);
                this.setState({ panButton: 'Bottom', dragFlag:false });

                document.addEventListener('mouseup', () => {
                  panDragStop();
                  this.setState({ panButton: 'Bottom', dragFlag:true });
                }, { once: true });
              }}
              icon={<img src={'/assets/img/svg/bottombar/pan_5.svg'} style={{ 'height': '40px', borderBottomRightRadius:'20%'}} draggable={false} />}
            />
          </S.Direction>

          <S.Direction key={'spin'}>
            <DirectionButton key={'spin'}
              active={pan_control === 'spin'}
              style={{ left: '280px', bottom:'20px', cursor: 'default' }}
              icon={<img src={'/assets/img/svg/bottombar/spin_1.svg'} style={{ 'width': '177px'}}/>}
            />
            <DirectionPanSpinButton key={'spinLeft'}
              active={pan_control === 'spinLeft'}
              tooltip={'Left'}
              style={{ left: (195 + 85), bottom: '-25px', borderBottomLeftRadius:'20%'}}
              onMouseDown={() => {
                window.SPKeyDown({ keyCode: 65 });
                spinDragStart(65);
                this.setState({ panButton: 'spinLeft', dragFlag:false });
                }}
              onMouseUp={() => {
                spinDragStop();
                this.setState({ panButton: 'spinLeft', dragFlag:true });
              }}
              icon={<img src={'/assets/img/svg/bottombar/spin_2.svg'} style={{ 'height': '40px', borderBottomLeftRadius:'20%'}}/>}
            />
            <DirectionPanSpinButton key={'spinRight'}
              active={pan_control === 'spinRight'}
              tooltip={'Right'}
              style={{ left: (195 + 131), bottom: '-25px' }}
              onMouseDown={() => {
                window.SPKeyDown({ keyCode: 68 });
                spinDragStart(68);
                this.setState({ panButton: 'spinRight', dragFlag:false });
                }}
              onMouseUp={() => {
                spinDragStop();
                this.setState({ panButton: 'spinRight', dragFlag:true });
              }}
              icon={<img src={'/assets/img/svg/bottombar/spin_3.svg'} style={{ 'height': '40px'}}/>}
            />
            <DirectionPanSpinButton key={'spinTop'}
              active={pan_control === 'spinTop'}
              tooltip={'Up'}
              style={{ left: (195 + 176), bottom: '-25px'  }}
              onMouseDown={() => {
                window.SPKeyDown({ keyCode: 87 });
                spinDragStart(87);
                this.setState({ panButton: 'spinTop', dragFlag:false });
                }}
              onMouseUp={() => {
                spinDragStop();
                this.setState({ panButton: 'spinTop', dragFlag:true });
              }}
              icon={<img src={'/assets/img/svg/bottombar/spin_4.svg'} style={{ 'height': '40px'}}/>}
            />
            <DirectionPanSpinButton key={'spinBottom'}
              active={pan_control === 'spinBottom'}
              tooltip={'Down'}
              style={{ left: (195 + 221), bottom: '-25px', borderBottomRightRadius:'20%'  }}
              onMouseDown={() => {
                window.SPKeyDown({ keyCode: 83 });
                spinDragStart(83);
                this.setState({ panButton: 'spinBottom', dragFlag:false });
                }}
              onMouseUp={() => {
                spinDragStop();
                this.setState({ panButton: 'spinBottom', dragFlag:true });
              }}
              icon={<img src={'/assets/img/svg/bottombar/spin_5.svg'} style={{ 'height': '40px', borderBottomRightRadius:'20%'}}/>}
            />
          </S.Direction>
          <div
            style ={{
              display: 'flex',
              position: 'absolute',
              left: '474px',
              bottom: '-5px'
            }}
          >
            <ControlButton
              key={'Settings'}
              tooltip={'Setting'}
              active={control === 'Settings'}
              onClick={() => {
                this.setState({ controlButton: 'Settings' });
                this.setState({ ceil3DFlag: true });
                }}
              icon={ <img src={'/assets/img/svg/bottombar/settings.svg'} style={{ 'width': '28px', 'height': '28px' }}/> }
              style = {{ bottom: '-25px', left: '461px' }}
            />
          </div>
        </div> 

      </div>
    ];


    return ( mode !== 'MODE_VIEWING_CATALOG' ? footerview: null );
  }
}

FooterBar.propTypes = {
  state: PropTypes.object.isRequired,
  // footerbarComponents: PropTypes.array.isRequired,
  // width: PropTypes.number.isRequired,
  // height: PropTypes.number.isRequired,
  content: PropTypes.number.isRequired,
  // softwareSignature: PropTypes.string
};

FooterBar.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  viewer2DActions: PropTypes.object.isRequired,
  viewer3DActions: PropTypes.object.isRequired,
  // linesActions: PropTypes.object.isRequired,
  // holesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  // translator: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,

};
