import React from 'react';
import * as Three from 'three';
import {loadGLTF, scaleObject} from '../../utils/load-obj';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';
import { OBJTYPE_MESH } from '../../../../../src/constants';

const hdrUrls = ['px.hdr', 'nx.hdr', 'py.hdr', 'ny.hdr', 'pz.hdr', 'nz.hdr'];
let textureCube = new HDRCubeTextureLoader()
  .setPath('catalog/envMap/')
  .setDataType(Three.UnsignedByteType)
  .load(hdrUrls, function () {
  textureCube.magFilter = Three.LinearFilter;
  textureCube.needsUpdate = true;
});

let cached3DWindow = null;

export default {
  name: "Window",
  prototype: "holes",

  info: {
    title: "Clear",
    tag: ['window'],
    description: "Window",
    image: "/assets/img/svg/windows_clear.svg",
    url: "/assets/gltf/window_clear.gltf",
  },

  properties: {
    width: {
      label: "Width",
      type: "length-measure",
      defaultValue: {
        length: 86.36
      }
    },
    height: {
      label: "Height",
      type: "length-measure",
      defaultValue: {
        length: 100
      }
    },
    altitude: {
      label: "Distance from floor",
      type: "length-measure",
      defaultValue: {
        length: 121.92
      }
    },
    thickness: {
      label: "Thickness",
      type: "length-measure",
      defaultValue: {
        length: 6
      }
    }
  },

  render2D: function (element, layer, scene) {
    const STYLE_HOLE_BASE = {stroke: 'rgb(73, 73, 73)', strokeWidth: '1px', strokeDasharray: '9,5', fill: 'rgb(73, 73, 73)'};
    const STYLE_HOLE_SELECTED = {stroke: '#0096fd', strokeWidth: '1px', strokeDasharray: '9,5', fill: '#0096fd', cursor: 'move'};
    const STYLE_ARC_BASE = {stroke: 'rgb(73, 73, 73)', strokeWidth: '1px', strokeDasharray: '9,5', fill: 'none'};
    const STYLE_ARC_SELECTED = {stroke: '#0096fd', strokeWidth: '1px', strokeDasharray: '9,5', fill: 'none', cursor: 'move'};
    const STYLE_STR0 = {fill:'rgb(185, 185, 185)',stroke:'#494949',strokeWidth:'1', strokeMiterlimit:'2.61313'};
    const STYLE_STR0_S = {fill:'rgb(185, 185, 185)',stroke:'#0096fd',strokeWidth:'1', strokeMiterlimit:'2.61313'};
    const STYLE_STR1 = {fill:'none', stroke:'#494949', strokeWidth:'1', strokeLinecap:'round', strokeLinejoin:'round', strokeMiterlimit:'2.61313', strokeDasharray:'23.860041 11.930021'};
    const STYLE_FILL2 =  {fill:'#1183B7'};
    const STYLE_FNT0 =  {fill:'white',fontWeight:'normal', fontSize:'12px', fontFamily:'Proxima Nova Rg'}
    //let line = layer.lines.get(hole.line);
    //let epsilon = line.properties.get('thickness') / 2;

    let EPSILON = 3;
    let lineWidth = element.properties.get('thickness').get('length');

    let holeWidth = element.properties.get('width').get('length');
    let holePath = `M${0} ${ -EPSILON}  L${holeWidth} ${-EPSILON}  L${holeWidth} ${EPSILON}  L${0} ${EPSILON}  z`;
    let arcPath = `M${0},${0}  A${holeWidth},${holeWidth} 0 0,1 ${holeWidth},${holeWidth}`;
    let holeStyle = element.selected ? STYLE_HOLE_SELECTED : STYLE_HOLE_BASE;
    let arcStyle = element.selected ? STYLE_ARC_SELECTED : STYLE_ARC_BASE;
    let rectStyle = element.selected ? STYLE_STR0_S : STYLE_STR0;
    let length = element.properties.get('width').get('length');
    return (
      <g transform={`translate(${-length / 2}, 0)`}>
          {/* <path d={arcPath} style={arcStyle} transform={`translate(${0},${holeWidth}) scale(${1},${-1}) rotate(${0})`}/>
          <line x1={0} y1={holeWidth - EPSILON} x2={0} y2={0 - EPSILON} style={holeStyle} transform={`scale(${-1},${1})`}/> */}
          <rect style={rectStyle} x="0" y={-lineWidth/2} width={holeWidth} height={lineWidth}/>
          {/* <rect style={STYLE_FILL2} x={holeWidth - 16} y="-8.5" width="18" height="17" rx="1.27" ry="1.27"/> */}
          {/* <text x={holeWidth - 15} y="4.5" transform={`scale(1,-1)`} style={STYLE_FNT0}>W1</text> */}
        </g>
    );
  },

  render3D: function (element, layer, scene) {
    let onLoadItem = (object) => {
      let width = element.properties.get('width').get('length');
      let height = element.properties.get('height').get('length');
      let thickness = element.properties.get('thickness').get('length');

      scaleObject(object, [90, 100, 6], [width, height, thickness]);

      if (element.selected) {
        let box = new Three.BoxHelper(object, 0x99c3fb);
        box.material.linewidth = 2;
        box.material.depthTest = false;
        box.renderOrder = 1000;
        object.add(box);
      }

      //let normalMap = require('./texture.png');
      //let t = new Three.TextureLoader().load(normalMap);
      const examplecolor = new Three.Color(0xFFFFFF);
      let mat2 = new Three.MeshStandardMaterial({
        color: examplecolor, 
        metalness: 0.1,
        roughness: 0.9
      });
      //mat2.map = t;
      mat2.envMap = textureCube;

      for (let j = 0; j < object.children.length; j++) {
        if (object.children[j].type === OBJTYPE_MESH) {
          object.children[j].material = mat2;
          object.children[j].receiveShadow = true;
        }
      }

      return object;
    };

    if(cached3DWindow) {
      return Promise.resolve(onLoadItem(cached3DWindow.clone()));
    }
    return loadGLTF(element.url)
    .then(object => {
      cached3DWindow = object;
      return onLoadItem(cached3DWindow.clone())
    })
  }
};
