import React from 'react';
import * as Three from 'three';

// const grey = new Three.MeshLambertMaterial({color: 0x3f3f3f});
// const white = new Three.MeshLambertMaterial({color: 0xffffff, transparent:true, opacity:0.5});
// const black = new Three.MeshLambertMaterial({color: 0x000000});

import {loadObjWithMaterial, loadGLTF} from '../../utils/load-obj';
import path from 'path';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';
import { OBJTYPE_MESH } from '../../../../../src/constants';

const hdrUrls = ['px.hdr', 'nx.hdr', 'py.hdr', 'ny.hdr', 'pz.hdr', 'nz.hdr'];
let textureCube = new HDRCubeTextureLoader()
  .setPath('catalog/envMap/')
  .setDataType(Three.UnsignedByteType)
  .load(hdrUrls, function () {
  textureCube.magFilter = Three.LinearFilter;
  textureCube.needsUpdate = true;
});
let cached3DDoor = null;

export default {
  name: 'Sliding',
  prototype: 'holes',

  info: {
    tag: ['door'],
    title: 'Sliding',
    description: 'Sliding',
    // image: "/assets/img/svg/doors_patio.svg",
    image: "/assets/img/png/door/sliding.png",
    url: "/assets/gltf/door_sliding.gltf",
  },

  properties: {
    width: {
      label: 'width',
      type: 'length-measure',
      defaultValue: {
        length: 200,
        unit: 'cm'
      },
      length: 200,
    },
    height: {
      label: 'height',
      type: 'length-measure',
      defaultValue: {
        length: 215,
        unit: 'cm'
      },
      length: 215,
    },
    thickness: {
      label: 'thickness',
      type: 'length-measure',
      defaultValue: {
        length: 6,
        unit: 'cm'
      },
    },
    altitude: {
      label: 'altitude',
      type: 'length-measure',
      defaultValue: {
        length: 0,
        unit: 'cm'
      }
    },
    flip_horizontal: {
      label: 'horizontal flip',
      type: 'checkbox',
      defaultValue: 'none',
      values: {
        'none': 'none',
        'yes':  'yes'
      }
    },
    flip_vertical: {
      label: 'vertical flip',
      type: 'checkbox',
      defaultValue: 'right',
      values: {
        'right': 'right',
        'left':  'left'
      }
    }
  },

  render2D: function (element, layer, scene) {

    const STYLE_HOLE_BASE = {stroke: 'rgb(73, 73, 73)', strokeWidth: '1px', strokeDasharray: '9,5', fill: 'rgb(73, 73, 73)'};
    const STYLE_HOLE_SELECTED = {stroke: '#0096fd', strokeWidth: '1px', strokeDasharray: '9,5', fill: '#0096fd', cursor: 'move'};
    const STYLE_STR0 = {fill:'rgb(185, 185, 185)',stroke:'#494949',strokeWidth:'1', strokeMiterlimit:'2.61313'};
    const STYLE_STR0_S = {fill:'rgb(185, 185, 185)',stroke:'#0096fd',strokeWidth:'1', strokeMiterlimit:'2.61313'};
    
    let epsilon = 2;
    let lineWidth = 6;
    let flip = element.properties.get('flip_horizontal');
    let handleSide = element.properties.get('flip_vertical');
    let holeWidth = element.properties.get('width').get('length');
    let holeStyle = element.selected ? STYLE_HOLE_SELECTED : STYLE_HOLE_BASE;
    let rectStyle = element.selected ? STYLE_STR0_S : STYLE_STR0;

    let scaleX = 1, scaleY = 1;

    scaleX = flip == handleSide ? 1 : -1;
    scaleY = flip ? -1 : 1;

    return (
      <g transform={`scale(${scaleX},${scaleY})`}>
        <rect key='1' style={rectStyle} x={-holeWidth / 2} y={-lineWidth / 2} width={holeWidth} height={lineWidth}/>
        <rect key='2' style={rectStyle} x={-holeWidth / 2} y={-lineWidth / 4} width={holeWidth / 2} height={lineWidth / 2}/>

        <line key='3' x1={0 + epsilon} y1='0' x2={holeWidth / 2} y2='0' style={holeStyle}/>
        <line key='4' x1={0 + epsilon} y1='0' x2={epsilon + lineWidth / 2} y2={lineWidth / 3} style={holeStyle}/>
        <line key='5' x1={0 + epsilon} y1='0' x2={epsilon + lineWidth / 2} y2={-lineWidth / 3} style={holeStyle}/>
        <line key='6' x1={holeWidth / 2 - epsilon} y1='0' x2={holeWidth / 2 - epsilon - lineWidth / 2} y2={lineWidth / 3} style={holeStyle}/>
        <line key='7' x1={holeWidth / 2 - epsilon} y1='0' x2={holeWidth / 2 - epsilon - lineWidth / 2} y2={-lineWidth / 3} style={holeStyle}/>

        <rect key='8' style={rectStyle} x={-lineWidth} y={ lineWidth / 4} width={lineWidth} height={lineWidth / 2}/>
      </g>
    )

  },

  render3D: function (element, layer, scene) {
    let onLoadItem = (object) => {
      let flip = element.properties.get('flip_horizontal');
      let handleSide = element.properties.get('flip_vertical');
      let width = element.properties.get('width').get('length');
      let height = element.properties.get('height').get('length');
      let thickness = element.properties.get('thickness').get('length');
      let newAltitude = element.properties.get('altitude').get('length');

      flip ? flip = 'yes' : flip = 'none';
      handleSide ? handleSide = 'right' : handleSide = 'left';

      let valuePosition = new Three.Box3().setFromObject(object);

      let deltaX = Math.abs(valuePosition.max.x - valuePosition.min.x);
      let deltaY = Math.abs(valuePosition.max.y - valuePosition.min.y);
      let deltaZ = Math.abs(valuePosition.max.z - valuePosition.min.z);

      if (element.selected) {
        let boundingBox = new Three.BoxHelper(object, 0x99c3fb);
        boundingBox.material.linewidth = 5;
        boundingBox.renderOrder = 1000;
        boundingBox.material.depthTest = false;
        object.add(boundingBox);
      }

      const params = {
        envMap: 'HDR',
        roughness: 0.9,
        metalness: 0.1,
        exposure: 1.0,
      };
      const examplecolor = new Three.Color(0xFFFFFF);
      let mat2 = new Three.MeshStandardMaterial({
        color: examplecolor,
        metalness: params.metalness,
        roughness: params.roughness
      });
      mat2.envMap = textureCube;
      for (let j = 0; j < object.children.length; j++) {
        if (object.children[j].type === OBJTYPE_MESH) {
          object.children[j].material = mat2;
          object.children[j].receiveShadow = true;
        }
      }

      if(flip === 'yes')
        object.rotation.y += Math.PI;

      object.position.y+= newAltitude;
      object.scale.set(width / deltaX, height / deltaY, thickness / deltaZ);

      return object;
    };

    if(cached3DDoor) {
      return Promise.resolve(onLoadItem(cached3DDoor.clone()));
    }

    return loadGLTF(element.url)
    .then(object => {
      cached3DDoor = object;
      return onLoadItem(cached3DDoor.clone())
    })
  }
};
