import React from 'react';
import PropTypes from 'prop-types';
import { UNITS_LENGTH, UNIT_INCH, UNIT_CENTIMETER } from '../../constants';
import convert from 'convert-units';
import { FormLabel, FormNumberInput, FormSelect } from '../../components/style/export';
import { Map } from 'immutable';
import { toFixedFloat } from '../../utils/math';
import PropertyStyle from './shared-property-style';

const internalTableStyle = { borderCollapse: 'collapse' };
const secondTdStyle = { padding: 0 };
const unitContainerStyle = { width: '3em' };

export default function PropertyLengthMeasure({ value, onUpdate, onValid, configs, sourceElement, internalState, state }, { catalog }) {
  let _unit = value.get('_unit');
  let length = value.get('length') || 0; // in centi
  let _length = value.get('_length') || length; // in _unit
  if (!_unit) {
    _unit = UNIT_INCH;
    _length = convert(length).from(UNIT_CENTIMETER).to(UNIT_INCH);
  }
  let type = value.get('type') || 'Base';
  let { hook, label, ...configRest } = configs;
  let update = (lengthInput, unitInput, type) => {
    let newLength = toFixedFloat(lengthInput);
    let merged = null;
    if(type === 0) {
      merged = value.merge({
        _length: newLength,
        _unit: unitInput,
        length: convert(newLength).from(unitInput).to(UNIT_CENTIMETER),
      });
    } else {
      merged = value.merge({
        _length: convert(newLength).from(UNIT_CENTIMETER).to(unitInput),
        _unit: unitInput,
        length: newLength,
      });
    }
    if (hook) {
      return hook(merged, sourceElement, internalState, state).then(val => {
        return onUpdate(val);
      });
    }
    return onUpdate(merged);
  };

  return (
    <table className="PropertyLengthMeasure" style={PropertyStyle.tableStyle}>
      <tbody>
        <tr>
          <td style={PropertyStyle.firstTdStyle}><FormLabel>{label}</FormLabel></td>
          <td style={secondTdStyle}>
            <table style={internalTableStyle}>
              <tbody>
                <tr>
                  <td>
                    <FormNumberInput
                      disabled={type==='Tall'}
                      value={_length}
                      onChange={event => update(event.target.value, _unit, 0)}
                      onValid={onValid}
                      {...configRest}
                    />
                  </td>
                  <td style={unitContainerStyle}>
                    <FormSelect disabled={type==='Tall'} value={_unit} onChange={event => update(length, event.target.value, 1)}>
                      {
                        UNITS_LENGTH.map(el => <option key={el} value={el}>{el}</option>)
                      }
                    </FormSelect>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );

}

PropertyLengthMeasure.propTypes = {
  value: PropTypes.instanceOf(Map).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onValid: PropTypes.func,
  configs: PropTypes.object.isRequired,
  sourceElement: PropTypes.object,
  internalState: PropTypes.object,
  state: PropTypes.object.isRequired
};

PropertyLengthMeasure.contextTypes = {
  catalog: PropTypes.object.isRequired
};
