import React from 'react';
import ReactDOM from 'react-dom';
import ContainerDimensions from 'react-container-dimensions';
import Immutable, { Map } from 'immutable';
import immutableDevtools from 'immutable-devtools';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import browserHistory from '../../src/@history';
import * as history from 'history'
import AppContext from './AppContext';
import { HashRouter, Router, Route, Switch, Redirect} from 'react-router-dom';
import { render2DItem, render3DItem, render3DApplianceItem, render3DLightingItem} from "./catalog/utils/item-loader";
import exporter from "./catalog/utils/exporter";
import axios from 'axios';
import MyCatalog from './catalog/mycatalog';
import Login from './../../src/components/login/Login'
import Register from './../../src/components/login/Register'
import {MODE, API_SERVER_URL, NO_DATA_DATABASE, ERROR_DATABASE, HAS_LOADINGBAR} from './../../src/constants';
// import { Progress } from 'antd';
import { Line, Circle } from 'rc-progress';
import ToolbarScreenshotButton from './ui/toolbar-screenshot-button';
import MobileDetect from 'mobile-detect';
import * as zlib from 'zlib';

import {
  Models as PlannerModels,
  reducer as PlannerReducer,
  KitchenConfigurator,
  Plugins as PlannerPlugins,
} from 'KitchenConfigurator'; //KitchenConfigurator
import { newProject } from '../../src/actions/project-actions';
import { SVGLoader } from '../../src/plugins/SVGLoader';

// Axios config
axios.defaults.baseURL =  API_SERVER_URL;

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = md.mobile();
if (isMobile !== null) {
  alert('The Kitchen Design software is only available from Desktop use');
}
//define state
let AppState = Map({
  'KitchenConfigurator': new PlannerModels.State()
});

console.log("Version: 20231004.195");
ReactGA.initialize([
  {
    trackingId: 'G-YK2JCC9F9G' // https://dev.addovisuals.com
  },
  {
    trackingId: 'G-8X2PYZ8WKK' // http://kc.addovisuals.com/
  },
  {
    trackingId: 'G-M2VD74KP44' // http://rtastore.diydesignspace.com/
  },
]);

hotjar.initialize("3010506", "6");

//define reducer
let reducer = (state, action) => {
  state = state || AppState;
  state = state.update('KitchenConfigurator', plannerState => PlannerReducer(plannerState, action));
  return state;
};

let blackList = isProduction === true ? [] : [
  'UPDATE_MOUSE_COORDS',
  'UPDATE_ZOOM_SCALE',
  'UPDATE_2D_CAMERA'
];

if (!isProduction) {
  console.info('Environment is in development and these actions will be blacklisted', blackList);
  console.info('Enable Chrome custom formatter for Immutable pretty print');
  immutableDevtools(Immutable);
}

//init store
let store = createStore(
  reducer,
  null,
  !isProduction && window.devToolsExtension ?
    window.devToolsExtension({
      features: {
        pause: true,     // start/pause recording of dispatched actions
        lock: true,     // lock/unlock dispatching actions and side effects
        persist: true,     // persist states on page reloading
        export: true,     // export history of actions in a file
        import: 'custom', // import history of actions from a file
        jump: true,     // jump back and forth (time travelling)
        skip: true,     // skip (cancel) actions
        reorder: true,     // drag and drop actions in the history list
        dispatch: true,     // dispatch custom actions or action creators
        test: true      // generate tests for the selected actions
      },
      actionsBlacklist: blackList,
      maxAge: 999999
    }) :
    f => f
);

let plugins = [
  PlannerPlugins.Keyboard(),
  PlannerPlugins.ConsoleDebugger(),
];

let toolbarButtons = [
  ToolbarScreenshotButton,
];

let curPercentage = 0;
let categoryData;
if(isMobile === null) {

  curPercentage = 0;
  curPercentage = curPercentage.toFixed(0);
  
  if (HAS_LOADINGBAR){
    ReactDOM.render(
      (
        <Provider store={store}>
          <ContainerDimensions>
          {
            () =>
                <div style={{textAlign: "center"}}>
                  <div style={{textAlign: "center", width: '100%'}}>
                    <div style={{textAlign: "center", width: '100%'}}>
                      <img style={{animation: 'spin 2s linear infinite', marginTop: `7%`}} src={"/assets/img/loading_large.gif"} alt="img"/>
                    </div>
                    <div style={{marginTop: `10%`}}>Kitchen Configurator is loading : {curPercentage}%</div><br/>
                    <Line percent={curPercentage} strokeWidth="1" strokeColor="#2d88d5" style={{width: '80%'}}/>
                  </div>           
                </div>
          }
          </ContainerDimensions>
        </Provider>
      ),
      document.getElementById('app')
    );
  }

  axios.post(`${API_SERVER_URL}/api/planner/read/planner`, {
    type: MODE === "staging" ? 2 : 1,
    
  },{
    responseType: "arraybuffer",
    onDownloadProgress: function (progressEvent) {
      
      curPercentage = progressEvent.loaded * 50 / progressEvent.total;
      curPercentage = curPercentage.toFixed(0);
      console.log("Loading Progress:" +  curPercentage +"%");
      console.log(HAS_LOADINGBAR);
      if (HAS_LOADINGBAR){
        ReactDOM.render(
          (
            <Provider store={store}>
              <ContainerDimensions>
              {
                () =>
                    <div style={{textAlign: "center"}}>
                      <div style={{marginTop: `22%`}}>Kitchen Configurator is loading : {curPercentage}%</div><br/>
                      <Line percent={curPercentage} strokeWidth="1" strokeColor="#2d88d5" style={{width: '80%'}}/>                
                    </div>
              }
              </ContainerDimensions>
            </Provider>
          ),
          document.getElementById('app')
        );
      }    
    },
  })
  .then(async response => {
      const unzip_data = JSON.parse (zlib.unzipSync(new Buffer (response.data)).toString ());
      const { data, appliances, lighting, furnishing, success } = unzip_data;
      if(success === false)
      {
        console.log(NO_DATA_DATABASE);
      }
      if(success === 'error')
      {
        alert(ERROR_DATABASE);
      }

      curPercentage = 50;
      curPercentage = curPercentage.toFixed(0);
      
      if (HAS_LOADINGBAR){
        ReactDOM.render(
          (
            <Provider store={store}>
              <ContainerDimensions>
              {
                () =>
                    <div style={{textAlign: "center"}}>
                      <div style={{textAlign: "center", width: '100%'}}>
                        <div style={{textAlign: "center", width: '100%'}}>
                          <img style={{animation: 'spin 2s linear infinite', marginTop: `7%`}} src={"/assets/img/loading_large.gif"} alt="img"/>
                        </div>
                        <div style={{marginTop: `10%`}}>Kitchen Configurator is loading : {curPercentage}%</div><br/>
                        <Line percent={curPercentage} strokeWidth="1" strokeColor="#2d88d5" style={{width: '80%'}}/>
                      </div>           
                    </div>
              }
              </ContainerDimensions>
            </Provider>
          ),
          document.getElementById('app')
        );
      }

      await axios.post(`${API_SERVER_URL}/api/toolbar/getCategoryData`, {
        type: MODE === "staging" ? 2 : 1,
      },{
        responseType: "arraybuffer",
        onDownloadProgress: function (progressEvent) {
          
          curPercentage = progressEvent.loaded * 50 / progressEvent.total + 50;
          curPercentage = curPercentage.toFixed(0);
          console.log("Loading Progress:" +  curPercentage +"%");
          console.log(HAS_LOADINGBAR, " : Category");
          if (HAS_LOADINGBAR){
            ReactDOM.render(
              (
                <Provider store={store}>
                  <ContainerDimensions>
                  {
                    () =>
                        <div style={{textAlign: "center"}}>
                          <div style={{marginTop: `22%`}}>Kitchen Configurator is loading : {curPercentage}%</div><br/>
                          <Line percent={curPercentage} strokeWidth="1" strokeColor="#2d88d5" style={{width: '80%'}}/>                
                        </div>
                  }
                  </ContainerDimensions>
                </Provider>
              ),
              document.getElementById('app')
            );
          }    
        },
      })
      .then(response => {
        categoryData = JSON.parse (zlib.unzipSync(new Buffer (response.data)).toString ());
        let molding = [];
        let cabinets = categoryData.data.cabinets;
        cabinets[cabinets.length - 1].items.forEach(index => {
          if(index.name === 'Molding') {
            molding = index.items;
          }
        });
        let promises = [];
        molding.forEach(child=>{
          promises.push(new Promise((resolve, reject) => {
            let url = child.shape_svg;
            const loader = new SVGLoader();
            let point = [];
            loader.load( url, function ( data ) {
              child.data = {paths: data.paths, svg_width: data.xml.viewBox.animVal.width, svg_height: data.xml.viewBox.animVal.height};
              resolve();
            }, null, (error) => {
              console.log(error);
              reject(error);
            });
          }));
        })
        return Promise.all(promises);
      });
      const Item = [];

      // Load Outline SVG Data

      let svgLoadPromises = data.map( item => new Promise((resolve, reject) => {

        if (item.outline) {

          let loader = new SVGLoader();
          loader.load(item.outline, data => {

            resolve({ paths: data.paths, svgWidth: parseFloat(data.xml.getAttribute('width')), svgHeight: parseFloat(data.xml.getAttribute('height')) });

          });

        } else {

          resolve();

        }

      }));

      let outlineSVGData = await Promise.all(svgLoadPromises);

      // End : Load Outline SVG Data

      data.forEach( (obj, index) => {
        const { itemID, long_name, name, sizeinfo, description, prototype, base, structure_json, layoutpos, is_corner, shape_svg, alti, obj_property} = obj;
        Item.push(exporter(itemID, "cabinet", long_name, name, sizeinfo, description, prototype, base, shape_svg, render2DItem, render3DItem, structure_json, layoutpos, is_corner, alti, obj_property, outlineSVGData[index]));
      })
      appliances.forEach( obj => {
        const { itemID, long_name, name, sizeinfo, description, prototype, base, structure_json, layoutpos, is_corner, shape_svg, alti, obj_property} = obj;
        Item.push(exporter(itemID, "appliance", long_name, name, sizeinfo, description, prototype, base, shape_svg, render2DItem, render3DApplianceItem, structure_json, layoutpos, is_corner, alti, obj_property));
      })
      lighting.forEach( obj => {
        const { itemID, long_name, name, sizeinfo, description, prototype, base,  shape_svg, structure_json, layoutpos, is_corner, alti, obj_property} = obj;
        Item.push(exporter(itemID, "lighting", long_name, name, sizeinfo, description, prototype, base,  shape_svg, render2DItem, render3DLightingItem, structure_json, layoutpos, is_corner, alti, obj_property));
      })
      furnishing.forEach( obj => {
        const { itemID, long_name, name, sizeinfo, description, prototype, base,  shape_svg, structure_json, layoutpos, is_corner, alti, obj_property} = obj;
        Item.push(exporter(itemID, "furnishing", long_name, name, sizeinfo, description, prototype, base,  shape_svg, render2DItem, render3DApplianceItem, structure_json, layoutpos, is_corner, alti, obj_property));
      })
      for (let x in Item) MyCatalog.registerElement(Item[x]);

      let ErrorComponent = () => <button type="button" onClick={()=>{window.location.reload();}}>Clear the Project</button>;
      
      setTimeout(() => {
        ReactDOM.render(<ErrorComponent/>, document.getElementById('error'));
        ReactDOM.render(
          (
            <AppContext.Provider
              // value={{
              //     routes
              // }}
            >
              <Provider store={store}>
                <ContainerDimensions>
                  {({ width, height }) =>
                  <HashRouter history={history.createHashHistory()}>
                    <Router history={browserHistory}>
                    <Switch>
                      <Route exact path="/" name="kc" render={props =>
                        <KitchenConfigurator
                          catalog={MyCatalog}
                          {...props}
                          width={width}
                          height={height}
                          plugins={plugins}
                          toolbarButtons={toolbarButtons}
                          stateExtractor={state => state.get('KitchenConfigurator')}
                          categoryData={categoryData}
                        />}
                      />
                      <Route path="/login" render={props => <Login /> } />
                      <Route path="/register" render={props => <Register /> } />
                      <Route path="/project/:role/:token/:pid" render={props =>
                        <KitchenConfigurator
                          catalog={MyCatalog}
                          {...props}
                          width={width}
                          height={height}
                          plugins={plugins}
                          toolbarButtons={toolbarButtons}
                          stateExtractor={state => state.get('KitchenConfigurator')}
                          categoryData={categoryData}
                        />}  />
                    </Switch>
                    </Router>
                  </HashRouter>
                  }
                </ContainerDimensions>
              </Provider>
            </AppContext.Provider>
          ),
          document.getElementById('app')
        );
      }, 100);

  }).catch(err => {
    alert("Something wrong happened. Do you want to clear the cache and restart the app?");
    console.log("Failed to load Category Data in demo/src/renderer.jsx", err);
    localStorage.clear();
    store.dispatch(newProject());
    
    ReactDOM.render(
      (
        <Provider store={store}>
          <ContainerDimensions>
            {({ width, height }) =>
              <KitchenConfigurator
                catalog={MyCatalog}
                // cabinetCategory={MyCabinetCategory}

                width={width}
                height={height}
                plugins={plugins}
                toolbarButtons={toolbarButtons}
                stateExtractor={state => state.get('KitchenConfigurator')}
                categoryData={categoryData}
              />
            }
          </ContainerDimensions>
        </Provider>
      ),
      document.getElementById('app')
    );
    return;
  });
}

