import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

export default class RightButton extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = { hover: false };
  }

  render() {
    let { state ,props } = this;
    let overlayStyle = {}
    if (state.hover)
      overlayStyle = { ...overlayStyle, 'display': 'block'}
    if (props.active)
      overlayStyle = { ...overlayStyle, 'display': 'block'}
    return (
        <S.rMain
          onMouseOver={event => this.setState({ hover: true })}
          onMouseOut={event => this.setState({ hover: false })}
          onClick={props.onClick}
        >
        <S.rightIcon>
          {props.icon}
        </S.rightIcon>

        <S.rOverlay style={{...overlayStyle}}>
          <img width="40px" src={props.src} />
          <S.rTitle>{props.title}</S.rTitle>
        </S.rOverlay>
        {/* {
          state.active ?
          <div style={STYLE_TOOLTIP}>
            <span style={STYLE_TOOLTIP_PIN} />
            {props.tooltip}
          </div>
          : null
        } */}
      </S.rMain>
    )
  }
}

RightButton.propTypes = {
  title: PropTypes.string,
  hover: PropTypes.bool,
  onClick: PropTypes.func
};
