import { Map, List } from 'immutable';
import {
  MODE_VIEWING_CATALOG,
  MODE_CONFIGURING_PROJECT,
  MODE_IDLE,
  MODE_DRAWING_ITEM_3D,
  MODE_3D_VIEW,
  MODE_DRAGGING_ITEM_3D,
  MODE_IDLE_3D,
  MODE_ROTATING_ITEM_3D,
  MODE_DRAWING_ITEM,
  MODE_WAITING_DRAWING_LINE,
  MODE_DRAWING_HOLE
} from '../constants';
import { State, Catalog } from '../models';
import { history } from '../utils/export';
import {
  Layer,
  Group,
  Line,
  Hole,
  Item,
  Area,
  HorizontalGuide,
  VerticalGuide
} from '../class/export';
import * as viewer2DActions from '../actions/viewer2d-actions';

class Project {

  static setAlterate(state) {
    return { updatedState: state.set('alterate', !state.alterate) };
  }

  static openCatalog(state) {
    state = this.setMode(state, MODE_VIEWING_CATALOG).updatedState;

    return { updatedState: state };
  }

  static newProject(state) {
    let doorStyle = state.doorStyle;
    let oStyle = state.oStyle;
    let layerID = state.scene.selectedLayer;
    let counterTop = state.getIn(['scene', 'layers', layerID, 'counterTop']);
    let floorStyle = state.getIn(['scene', 'layers', layerID, 'floorStyle']);
    let _viewer2D = state.viewer2D;
    let viewer = state.viewer2D.toJS();
    _viewer2D = _viewer2D.merge({
      e: viewer.viewerWidth /2 - viewer.SVGWidth / 2,
      f: viewer.viewerHeight /2 - viewer.SVGHeight / 2,
      a: 0.99,
      d: 0.99
    });

    state = new State({});
    state = state.merge({
      doorStyle: doorStyle,
      oStyle: oStyle,
      viewer2D: _viewer2D
    });
    state = Item.setCounterTop(state, counterTop).updatedState;
    state = Area.setFloorStyles(state, floorStyle).updatedState;
    return { updatedState: state };
  }

  static loadProject(state, sceneJSON) {
    let doorStyle = state.doorStyle;
    let oStyle = state.oStyle;
    let layerID = state.scene.selectedLayer;
    let _viewer2D = state.viewer2D;
    let viewer = state.viewer2D.toJS();
    
    let a, e, f;
    e = viewer.viewerWidth /2 - viewer.SVGWidth / 2;
    f = viewer.viewerHeight /2 - viewer.SVGHeight / 2;
    _viewer2D = _viewer2D.merge({
      e: e,
      f: f,
      a: 0.99,
      d: 0.99
    });
    
    let { scene : oldScene, sceneHistory : oldSceneHistory } = state;
    state = new State({ scene: sceneJSON, catalog: state.catalog.toJS() });
    state = state.merge({ sceneHistory: history.historyPush(oldSceneHistory, oldScene) });
    // get counterTop and floorStyle is after state created from sceneJSON
    let counterTop = state.getIn(['scene', 'layers', layerID, 'counterTop']);
    let floorStyle = state.getIn(['scene', 'layers', layerID, 'floorStyle']);

    const layer = state.scene.getIn(["layers", state.scene.selectedLayer]);
    let bb = {minX:Infinity, maxX: -Infinity, minY:Infinity, maxY: -Infinity};
    bb = layer.vertices.reduce((pre, cur) => {
      pre.minX = Math.min(pre.minX, cur.x);
      pre.maxX = Math.max(pre.maxX, cur.x);
      pre.minY = Math.min(pre.minY, cur.y);
      pre.maxY = Math.max(pre.maxY, cur.y);
      return pre;
    }, bb);

    let w = bb.maxX - bb.minX;
    let h = bb.maxY - bb.minY;

    if (w != 0 && Math.abs(w) != Infinity && h != 0 && Math.abs(h) != Infinity ) {
      a = viewer.viewerHeight / h * 3/5 ;
      e = (viewer.viewerWidth - (bb.maxX + bb.minX) * a) / 2;
      f = (viewer.viewerHeight - (viewer.SVGHeight * 2 - bb.maxY - bb.minY) * a) / 2;
      _viewer2D = _viewer2D.merge({
        a: a,
        d: a,
        e: e,
        f: f
      });
    }

    state = state.merge({
      doorStyle: doorStyle,
      oStyle: oStyle,
      viewer2D: _viewer2D
    });
    state = Item.setCounterTop(state, counterTop).updatedState;
    state = Area.setFloorStyles(state, floorStyle).updatedState;
    this.updateZoomScale(state, a);
    viewer2DActions.updateCameraView(_viewer2D);
    return { updatedState: state };
  }

  static setProperties(state, layerID, properties) {
    state = Layer.setPropertiesOnSelected(state, layerID, properties).updatedState;
    state = Layer.updateMovingState(state, false).updatedState;
    return { updatedState: state };
  }

  static updateProperties(state, layerID, properties) {
    state = Layer.updatePropertiesOnSelected(state, layerID, properties).updatedState;
    return { updatedState: state };
  }

  static setItemsAttributes(state, attributes) {
    //TODO apply only to items
    state = Layer.updateMovingState(state, false).updatedState;
    state.getIn(['scene', 'layers']).forEach(layer => { state = Layer.setAttributesOnSelected(state, layer.id, attributes).updatedState; });

    return { updatedState: state };
  }

  static setLinesAttributes(state, attributes, isDirect, directData) {
    //TODO apply only to lines
    if(!isDirect) {
      state.getIn(['scene', 'layers']).forEach(layer => { state = Layer.setAttributesOnSelected(state, layer.id, attributes).updatedState; });
    }
    else if(isDirect && directData !== null) {
      const {layerID, lineID} = directData
      if(layerID && lineID)
        state = Line.setAttributes(state, layerID, lineID, attributes).updatedState
    }
    return { updatedState: state };
  }

  static setHolesAttributes(state, attributes) {
    //TODO apply only to holes
    state.getIn(['scene', 'layers']).forEach(layer => { state = Layer.setAttributesOnSelected(state, layer.id, attributes).updatedState; });

    return { updatedState: state };
  }

  static unselectAll(state) {
    state.getIn(['scene', 'layers']).forEach(({ id: layerID }) => { state = Layer.unselectAll(state, layerID).updatedState; });
    state.getIn(['scene', 'groups']).forEach(group => { state = Group.unselect(state, group.get('id')).updatedState; });

    return { updatedState: state };
  }

  static selectAll(state, value) {
    if (value == null) {
      state.getIn(['scene', 'layers']).forEach(({ id: layerID }) => { state = Layer.selectAll(state, layerID).updatedState; });
    }
    else {
      let layerID = state.getIn(['scene', 'selectedLayer']);
      let layer = state.scene.layers.get(layerID);
      layer.items.forEach(data => {
        let x = state.getIn(["scene", "layers", layerID, "items", data.id, "x"])
        state = state.mergeIn(["scene", "layers", layerID, "items", data.id, "x"], x + value.x);
        let y = state.getIn(["scene", "layers", layerID, "items", data.id, "y"])
        state = state.mergeIn(["scene", "layers", layerID, "items", data.id, "y"], y + value.y);
      });
      // layer.lines.forEach(data => { state = Layer.selectElement(state, layerID, "lines", data.id).updatedState });
      // layer.holes.forEach(data => { state = Layer.selectElement(state, layerID, "holes", data.id).updatedState });
      // layer.areas.forEach(data => { state = Layer.selectElement(state, layerID, "areas", data.id).updatedState });
      layer.vertices.forEach(data => {
        let x = state.getIn(["scene", "layers", layerID, "vertices", data.id, "x"])
        state = state.mergeIn(["scene", "layers", layerID, "vertices", data.id, "x"], x + value.x);
        let y = state.getIn(["scene", "layers", layerID, "vertices", data.id, "y"])
        state = state.mergeIn(["scene", "layers", layerID, "vertices", data.id, "y"], y + value.y);
      });

      return { updatedState: state };
    }
    // state.getIn(['scene', 'groups']).forEach(group => { state = Group.unselect(state, group.get('id')).updatedState; });

    return { updatedState: state };
  }

  static remove(state) {
    let selectedLayer = state.getIn(['scene', 'selectedLayer']);
    let {
      lines: selectedLines,
      holes: selectedHoles,
      items: selectedItems
    } = state.getIn(['scene', 'layers', selectedLayer, 'selected']);

    state = Layer.unselectAll(state, selectedLayer).updatedState;

    selectedLines.forEach(lineID => { state = Line.remove(state, selectedLayer, lineID).updatedState; });
    selectedHoles.forEach(holeID => { state = Hole.remove(state, selectedLayer, holeID).updatedState; });
    selectedItems.forEach(itemID => { state = Item.remove(state, selectedLayer, itemID).updatedState; });

    state = Layer.detectAndUpdateAreas(state, selectedLayer).updatedState;

    return { updatedState: state };
  }

  static undo(state) {
    let ActiveDrawingHelpers = document.getElementsByName("line_type_ActiveDrawingHelper");
    ActiveDrawingHelpers.forEach ((element, index) => {
      element.style.display = "none";
    });
    let sceneHistory = state.sceneHistory;
    let forRedo = window.forRedo;
    window.forRedo = forRedo;
    if (state.scene === sceneHistory.last && sceneHistory.list.size > 1) {
      sceneHistory = history.historyPop(sceneHistory);
    }
    forRedo.push(state.scene);
    let mode;
    if ([MODE_3D_VIEW, MODE_IDLE_3D, MODE_ROTATING_ITEM_3D, MODE_DRAGGING_ITEM_3D, MODE_DRAWING_ITEM_3D].includes(state.mode))
      mode = MODE_IDLE_3D;
    else
      mode = MODE_IDLE;
    state = state.merge({
      mode: mode,
      scene: sceneHistory.last,
      sceneHistory: history.historyPop(sceneHistory)
    });
    return { updatedState: state };
  }

  static redo(state) {
    let forRedo = window.forRedo;
    if (forRedo.length == 0)
      return { updatedState: state };
    let scene = forRedo.pop();
    let mode;
    if ([MODE_3D_VIEW, MODE_IDLE_3D, MODE_ROTATING_ITEM_3D, MODE_DRAGGING_ITEM_3D, MODE_DRAWING_ITEM_3D].includes(state.mode))
      mode = MODE_IDLE_3D;
    else
      mode = MODE_IDLE;
    state = state.merge({
      mode: mode,
      scene: scene,
      sceneHistory: history.historyPush(state.sceneHistory, state.scene)
    });
    window.forRedo = forRedo;
    return { updatedState: state };
  }

  static uncreate(state) {
    state = state.merge({
      popup:false
    });
    return { updatedState: state };
  }

  static recreate(state) {
    if( state.getIn(['drawingSupport', 'type']) !== undefined )
      state = state.merge({
        mode: MODE_DRAWING_ITEM_3D,
        popup:true
      });
    return { updatedState: state };
  }

  static shift2don(state) {
    state = state.merge({
      popup:false,
    });
    return { updatedState: state };
  }

  static shift2doff(state) {
    if (state.getIn(['drawingSupport', 'type']) !== undefined) {
      if (state.get('selectedElementsHistory')._tail.array[0].prototype === "holes")
        state = state.merge({
          mode: MODE_DRAWING_HOLE,
          popup: true
        });
      else
        state = state.merge({
          mode: MODE_DRAWING_ITEM,
          popup: true
        });
    }
    return { updatedState: state };
  }

  static rollback(state) {
    let sceneHistory = state.sceneHistory;
    if (!sceneHistory.last && sceneHistory.list.isEmpty()) {
      return { updatedState: state };
    }

    state = this.unselectAll(state).updatedState;
    let mode;
    if (state.mode == MODE_DRAWING_ITEM_3D) {
      mode = MODE_IDLE_3D;
      if (state.drawingSupport.has("currentID"))
        state = Layer.removeElement(state, state.scene.selectedLayer, "items", state.drawingSupport.get("currentID")).updatedState;
      state = state.merge({
        mode: mode,
        snapElements: new List(),
        activeSnapElement: null,
        drawingSupport: new Map(),
        draggingSupport: new Map(),
        rotatingSupport: new Map(),
      });
      return { updatedState: state };
    }
    if ([MODE_3D_VIEW, MODE_IDLE_3D, MODE_ROTATING_ITEM_3D, MODE_DRAGGING_ITEM_3D].includes(state.mode))
      mode = MODE_IDLE_3D;
    else
      mode = MODE_IDLE;
    state = state.merge({
      mode: mode,
      scene: sceneHistory.last,
      sceneHistory: history.historyPush(sceneHistory, sceneHistory.last),
      snapElements: new List(),
      activeSnapElement: null,
      drawingSupport: new Map(),
      draggingSupport: new Map(),
      rotatingSupport: new Map(),
    });
    return { updatedState: state };
  }

  static removeDrawingSupport(state) {
    let sceneHistory = state.sceneHistory;
    let mode = state.mode;
    if (!sceneHistory.last && sceneHistory.list.isEmpty() || mode != MODE_DRAWING_ITEM_3D) {
      return { updatedState: state };
    }

    state = this.unselectAll(state).updatedState;
    state = state.merge({
      scene: sceneHistory.last,
      sceneHistory: history.historyPush(sceneHistory, sceneHistory.last),
      drawingSupport: new Map(),
    });
    return { updatedState: state };
  }

  static setProjectProperties(state, properties) {
    let scene = state.scene.merge(properties);
    state = state.merge({
      mode: MODE_IDLE,
      scene
    });

    return { updatedState: state };
  }

  static openProjectConfigurator(state) {
    state = state.merge({
      mode: MODE_CONFIGURING_PROJECT,
    });

    return { updatedState: state };
  }

  static initCatalog(state, catalog) {
    try {
      state = state.set('catalog', new Catalog(catalog));
      return { updatedState: state };
    } catch (error) {
      console.log("initCatalogError", error);
      return {updatedState: state}
    }
  }

  static updateMouseCoord(state, coords) {
    state = state.set('mouse', new Map(coords));

    return { updatedState: state };
  }

  static updateZoomScale(state, scale) {
    state = state.set('zoom', scale);

    return { updatedState: state };
  }

  static toggleSnap(state, mask) {
    state = state.set('snapMask', mask);
    return { updatedState: state };
  }

  static throwError(state, error) {
    state = state.set('errors', state.get('errors').push({
      date: Date.now(),
      error
    }));

    return { updatedState: state };
  }

  static throwWarning(state, warning) {
    state = state.set('warnings', state.get('warnings').push({
      date: Date.now(),
      warning
    }));

    return { updatedState: state };
  }

  static copyProperties(state, properties) {
    let layerID = state.getIn(['drawingSupport', 'layerID']);
    let layer = state.getIn(['scene', 'layers', layerID]);
    if(layer === undefined)
      return { updatedState: state };
    else 
      { state = state.merge({
        mode: MODE_WAITING_DRAWING_LINE,
        activeSnapElement: null
      });
      return { updatedState: state };
    }

  }

  static pasteProperties(state) {
    state = this.updateProperties(state, state.getIn(['scene', 'selectedLayer']), state.get('clipboardProperties')).updatedState;

    return { updatedState: state };
  }

  static pushLastSelectedCatalogElementToHistory(state, element) {
    let currHistory = state.selectedElementsHistory;

    let previousPosition = currHistory.findIndex(el => el.name === element.name);
    if (previousPosition !== -1) {
      currHistory = currHistory.splice(previousPosition, 1);
    }
    currHistory = currHistory.splice(0, 0, element);

    state = state.set('selectedElementsHistory', currHistory);
    return { updatedState: state };
  }

  static changeCatalogPage(state, oldPage, newPage) {
    state = state.setIn(['catalog', 'page'], newPage)
      .updateIn(['catalog', 'path'], path => path.push(oldPage));

    return { updatedState: state };
  }

  static goBackToCatalogPage(state, newPage) {
    let pageIndex = state.catalog.path.findIndex(page => page === newPage);
    state = state.setIn(['catalog', 'page'], newPage)
      .updateIn(['catalog', 'path'], path => path.take(pageIndex));

    return { updatedState: state };
  }

  static setMode(state, mode) {
    state = state.set('mode', mode);
    return { updatedState: state };
  }

  static addHorizontalGuide(state, coordinate) {
    state = HorizontalGuide.create(state, coordinate).updatedState;

    return { updatedState: state };
  }

  static addVerticalGuide(state, coordinate) {
    state = VerticalGuide.create(state, coordinate).updatedState;

    return { updatedState: state };
  }

  static addCircularGuide(state, x, y, radius) {
    console.log('adding horizontal guide at', x, y, radius);

    return { updatedState: state };
  }

  static removeHorizontalGuide(state, guideID) {
    state = HorizontalGuide.remove(state, guideID).updatedState;

    return { updatedState: state };
  }

  static removeVerticalGuide(state, guideID) {
    state = VerticalGuide.remove(state, guideID).updatedState;

    return { updatedState: state };
  }

  static removeCircularGuide(state, guideID) {
    console.log('removeing horizontal guide ', guideID);

    return { updatedState: state };
  }

  static setStateProperties(state, properties) {
    state = state.merge({
      ...properties
    });
    return {updatedState: state}
  }
}

export { Project as default };
